import React from 'react';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import { bluePlanetTheme } from 'ui/theme';
import CardHeading from 'ui/views/cards/CardHeading';
import ImageCard from 'ui/views/cards/ImageCard';
import { communityUrls } from 'urls';
import { getOrElse } from 'util/resource';
import { useCompanyApplication } from './useCompanyApplication';

interface Props {
  companyId?: number;
  communitySlug: string;
}

export default function HundredPitchesOnYourAgenda({ communitySlug, companyId }: Props) {
  const { resource: companyApplicationResource } = useCompanyApplication(communitySlug, companyId);

  const companyApplication = getOrElse(companyApplicationResource, []);
  const hasCompletedApplication = !companyId || companyApplication.every(a => !!a.answer);

  return (
    <ImageCard
      bleedOnMobile
      backgroundColor={'#B020FE'}
      color={bluePlanetTheme.bluePlanetPalette.white}
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <div>
        <CardHeading heading={`Your application to 100 pitches${hasCompletedApplication ? '' : ' is waiting'}`} />
        <p className="text-body u-content-spacing-bottom">
          {!hasCompletedApplication
            ? '100 Pitches are waiting for you to complete your application'
            : 'Your 100 Pitches application is being reviewed'}
          . Application deadline is 13th of August. All applicants will be notified if they are included before that.
        </p>
      </div>
      {companyId && (
        <div>
          <div style={{ marginTop: 'auto' }}>
            <LinkAsButton color="indigo" kind={'primary'} url={communityUrls.overview(communitySlug, 'overview')}>
              Go to application
            </LinkAsButton>
          </div>
        </div>
      )}
    </ImageCard>
  );
}
