import { ResourceAction, ResourceName } from 'types/company/company';
import { hasScope } from 'util/scopeUtils';
import { getOrUndefined } from 'util/resource';
import { useCommunityAccess } from 'apis/CompanyAPI/companies/useCommunityAccess';
import { ActingAs } from 'types/company/access';

export type HasScope = (action: ResourceAction, resource: ResourceName, ownership?: ActingAs) => boolean;

export default function useCommunityRole(communitySlug: string) {
  const { resource: accessResource } = useCommunityAccess(communitySlug);

  const access = getOrUndefined(accessResource);
  const scopes = access?.scopes ?? [];
  const roles = access?.roles ?? [];

  const canEdit = hasScope({ action: 'Write', resource: 'Community' }, scopes);
  const canView = hasScope({ action: 'Read', resource: 'Community' }, scopes);

  return {
    isFetched: accessResource.state === 'fetched',
    canEditCommunity: canEdit,
    canViewCommunity: canView,
    hasScope: (action: ResourceAction, resource: ResourceName, actingAs?: ActingAs) =>
      hasScope({ action, resource, actingAs }, scopes),
    roles: roles,
    scopes: scopes,
    myCompanyMemberships: access?.myCompanyMemberships ?? [],
  };
}
