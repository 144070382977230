import config from 'config';
import { JourneyLogEntry } from 'hooks/useJourneyLogger';
import { Industry, Stage, AllImpactGoals, ImpactGoal, AllHelpCenter, BusinessDomain } from 'types';
import { List } from 'types/api';
import { InviteTokenDTO, PendingEmailChangeDTO, VerifyEmailResult, VerifyEmailResponseDTO } from 'types/user';
import ApiBase from '../ApiBase';
import follower from './follower';
export * from './types';

const baseUrl = config.COMPANIES_API_URL;

const api = ApiBase();

export default {
  follower,
  identity: {
    verify: (token: string) =>
      api.post<VerifyEmailResult>(`${baseUrl}/identity/register/verify-email?token=${token}`, {}),
    resendVerificationEmail: (email: string) =>
      api.post(`${baseUrl}/identity/register/resend-verification-email`, { email }),
    emailHasAccount: (email: string) =>
      api.post<{ hasAccount: boolean }>(`${baseUrl}/identity/register/hasAccount`, { email }),
    getInvitationToken: (token: string) => api.get<InviteTokenDTO>(`${baseUrl}/identity/invite-token/${token}`),
    resetPassword: () => api.post<void>(`${baseUrl}/identity/reset-password`, {}),

    changeEmail: (email: string, password: string) =>
      api.post(`${baseUrl}/identity/change-email`, {
        email,
        password,
      }),
    getPendingEmailChange: () => api.get<PendingEmailChangeDTO>(`${baseUrl}/identity/change-email`),
    verifyEmailChange: (token: string) =>
      api.post<VerifyEmailResponseDTO>(`${baseUrl}/identity/change-email/verify/${token}`, {}),
    delete: (password: string) =>
      api.post<void>(`${baseUrl}/identity/delete`, {
        password,
      }),
  },
  businessDomains: {
    get: () => api.get<List<BusinessDomain>>(`${baseUrl}/businessDomains`),
  },
  fundingStageLabels: {
    get: () => api.get<List<Stage>>(`${baseUrl}/labels/funding-stage`),
  },
  industries: {
    update: (companyId: number, labels: Industry[]) =>
      api.put<List<Industry>>(`${baseUrl}/companies/${companyId}/industry-labels`, { labelIds: labels.map(l => l.id) }),
  },
  stages: {
    add: (companyId: number, stage: Stage) =>
      api.post<void>(`${baseUrl}/companies/${companyId}/stage-labels`, {
        labelId: stage.id,
      }),
    delete: (companyId: number, stage: Stage) =>
      api.deleteRequest<void>(`${baseUrl}/companies/${companyId}/stage-labels/${stage.id}`),
    update: (companyId: number, stages: Stage[]) =>
      api.put<List<Stage>>(`${baseUrl}/companies/${companyId}/stage-labels`, { labelIds: stages.map(l => l.id) }),
  },
  impactGoals: {
    listAll: () => api.get<AllImpactGoals>(`${baseUrl}/impact-goals`),
    save: (companyId: number, impactGoalIds: string[]) =>
      api.put<ImpactGoal[]>(`${baseUrl}/companies/${companyId}/impact-goals`, { impactGoalIds }),
  },
  helpCenter: {
    listAll: () => api.get<AllHelpCenter>(`${baseUrl}/help`),
  },
  log: {
    journey: (payload: JourneyLogEntry) => api.post<JourneyLogEntry>(`${baseUrl}/logs/journey`, payload),
  },
};
