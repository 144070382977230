import Resource from 'util/resource/Resource';
import React, { useState } from 'react';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import { useExecutiveSummary } from 'apis/CompanyAPI/companies/useExecutiveSummary';
import { ICompany } from 'types/company';
import useDialogHandler from 'hooks/useDialogHandler';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import TeamMembers from './TeamMembers';
import EditTeamDescription from './EditTeamDescription';
import LinkButton from 'ui/elements/buttons/LinkButton';
import EditAction from 'ui/elements/actions/EditAction';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import Card from 'ui/views/cards/Card';
import Button from 'ui/elements/buttons/Button';
import { useCompanyLastUpdated } from '../LastUpdated';
import useHighlightedUsers from './useHighlightedUsers';
import { Link } from 'react-router-dom';
import { companyUrls } from 'urls';
import Action from 'ui/elements/actions/Action';
import InviteUserIcon from 'ui/elements/icons/InviteUserIcon';
import classNames from 'classnames';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

interface Props {
  company: ICompany;
  canEditCompany: boolean;
}

export default function Team({ company, canEditCompany }: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const { resource: executiveSummaryResource, mutate } = useExecutiveSummary(company.id);
  const [openedTeamMember, setOpenedTeamMember] = useState<UUID | undefined>();
  const editDialogHandler = useDialogHandler();
  const notify = useNotify();
  const { setLastUpdated } = useCompanyLastUpdated(company.id);
  const [onSave, isSaving] = useLazyResource(
    (teamDescription: string | undefined) => companiesApi.teamDescription.patch(company.id, { teamDescription }),
    {
      onSuccess: () => {
        mutate();
        notify('success', 'Team description saved');
        editDialogHandler.close();
        setLastUpdated(new Date());
      },
      onFailure: message => notify('error', message || 'Unable to save Team description, please try again later.'),
    },
  );

  const { resource: highlightedUsersResource } = useHighlightedUsers(company.slug);

  const hasNotProvidedTeamInformation =
    executiveSummaryResource.state === 'fetched' &&
    !executiveSummaryResource.resource.teamDescription &&
    !canEditCompany &&
    highlightedUsersResource.state === 'fetched' &&
    highlightedUsersResource.resource.sections.length === 0;
  if (hasNotProvidedTeamInformation) {
    return <Card>{company.name} has not provided any team information yet.</Card>;
  }
  return (
    <>
      <Resource resource={executiveSummaryResource}>
        {executiveSummary => {
          return (
            <div>
              {executiveSummary.teamDescription && canEditCompany && (
                <div
                  className={classNames('u-flex-space-between u-half-spacing-top', {
                    ['u-content-spacing-x']: isMobile,
                  })}
                >
                  <Link
                    to={companyUrls.settings.inviteUser(company.slug, 'prospective_investor')}
                    className="text-link"
                  >
                    <Action icon={<InviteUserIcon />}>Invite team members</Action>
                  </Link>
                  <LinkButton className="u-half-spacing-bottom" onClick={editDialogHandler.open}>
                    <EditAction tooltip="Update team description" />
                  </LinkButton>
                </div>
              )}
              {executiveSummary.teamDescription && (
                <Card className="u-section-spacing-bottom text-body">{executiveSummary.teamDescription}</Card>
              )}
              {!executiveSummary.teamDescription && canEditCompany && (
                <>
                  <MobileMargin className={classNames('u-flex u-half-spacing-bottom')}>
                    <Link
                      to={companyUrls.settings.inviteUser(company.slug, 'prospective_investor')}
                      className="text-link"
                    >
                      <Action icon={<InviteUserIcon />}>Invite team members</Action>
                    </Link>
                  </MobileMargin>
                  <Card
                    type="dashed"
                    onClick={editDialogHandler.open}
                    className="u-content-spacing-bottom text-body u-flex u-flex-space-around"
                  >
                    <Button kind="tertiary">Add a description of your team</Button>
                  </Card>
                </>
              )}
              <EditTeamDescription
                companyId={company.id}
                isSaving={isSaving}
                onSave={onSave}
                dialogHandler={editDialogHandler}
                teamDescription={executiveSummary.teamDescription}
              />
            </div>
          );
        }}
      </Resource>

      <TeamMembers company={company} onOpenTeamMember={setOpenedTeamMember} canEditCompany={canEditCompany} />
      {openedTeamMember && (
        <UserProfileDialog cwUserId={openedTeamMember} onClose={() => setOpenedTeamMember(undefined)} />
      )}
    </>
  );
}
