import {
  DialogContent,
  DialogTitle,
  IconButton,
  DialogContentProps,
  DialogActions as MuiDialogActions,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import { DialogTitleProps } from '@mui/material/DialogTitle';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import React from 'react';
import styles from './styles.scss';
import bluePlanetTheme, { BackdropColor, getBackdropColor } from 'ui/theme/themeBluePlanet';
import { contentSpacing, halfSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  onClose?: () => void;
  open: DialogProps['open'];
  maxWidth?: DialogProps['maxWidth'];
  fullScreen?: DialogProps['fullScreen'];
  overflowY?: 'scroll';
  style?: DialogProps['style'];
  backdropColor?: BackdropColor;
  backdropBlur?: 'none' | 'some';
  backgroundColor?: string;
  scroll?: DialogProps['scroll'];
  transitionDuration?: number;
  ref?: DialogProps['ref'];
  className?: string;
  verticalAlign?: 'none' | 'top';
}

type TitleProps = DialogTitleProps & {
  subheading?: React.ReactNode;
  onClose?: () => void;
  closeIconIndent?: 'single' | 'double';
  style?: React.CSSProperties;
  padding?: PaddingSizes;
};

export function Title({ subheading, onClose, closeIconIndent, style, padding, className, children }: TitleProps) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  return (
    <DialogTitle
      style={style}
      className={cx(className, {
        'u-content-padding-y': padding === 'half',
      })}
    >
      <div className="u-flex-space-between u-flex-align-center">
        <div className="u-flex u-flex-align-center u-flex-1">{children}</div>
        {onClose && (
          <IconButton
            color="inherit"
            onClick={onClose}
            aria-label="Close"
            style={{
              marginTop: isMobile ? 0 : 3,
              marginRight: closeIconIndent === 'double' ? -10 : -5,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      {subheading && <div className={styles.subheading}>{subheading}</div>}
    </DialogTitle>
  );
}
type PaddingSizes = 'default' | 'half';

interface DialogActionProps {
  className?: string;
  align?: 'left' | 'center' | 'space-between';
  padding?: PaddingSizes;
  children?: React.ReactNode;
}

export function DialogActions({ className, align, padding, children }: DialogActionProps) {
  const justifyContent = align === 'center' ? 'center' : align === 'space-between' ? 'space-between' : 'start';
  return (
    <MuiDialogActions
      className={cx(className, {
        'u-content-padding-y': padding === 'half',
      })}
      style={{ justifyContent }}
    >
      <>{children}</>
    </MuiDialogActions>
  );
}

type ContentProps = DialogContentProps & {
  overflowY?: 'visible' | 'auto';
  paddingY?: 'none' | 'top' | 'bottom' | 'both';
};

export const Content = (
  props: ContentProps & {
    rootRef?: DialogProps['ref'];
  },
) => {
  const { overflowY, paddingY, rootRef, sx, ...rest } = props;
  const theme = useTheme();
  return (
    <DialogContent
      sx={{
        ...sx,
        wordBreak: 'break-word',
        overflowY: overflowY ?? undefined,
        paddingTop: paddingY === 'top' || paddingY === 'both' ? sectionSpacing : 0,
        paddingBottom: paddingY === 'bottom' || paddingY === 'both' ? sectionSpacing : 0,
        [theme.breakpoints.down('lg')]: {
          paddingTop: paddingY === 'top' || paddingY === 'both' ? contentSpacing : 0,
          paddingBottom: paddingY === 'bottom' || paddingY === 'both' ? contentSpacing : 0,
        },
      }}
      ref={rootRef}
      {...rest}
    />
  );
};

export default function Dialog(props: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const yMargin = halfSpacing;
  return (
    <MuiDialog
      transitionDuration={props.transitionDuration}
      ref={props.ref}
      style={props.style}
      maxWidth={props.maxWidth}
      sx={
        props.verticalAlign === 'top'
          ? {
              '& .MuiDialog-container:after': {
                display: 'none',
              },
            }
          : undefined
      }
      PaperProps={{
        style: {
          marginTop: props.verticalAlign === 'top' && !isMobile ? '15vh' : props.fullScreen ? '0' : yMargin,
          marginBottom: props.fullScreen ? 0 : yMargin,
          borderRadius: isMobile && props.fullScreen ? '0' : '8px',
          overflowY: props.overflowY ?? 'visible',
          backgroundColor: props.backgroundColor,
          maxWidth: props.fullScreen ? 'calc(100%)' : undefined,
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: getBackdropColor(props.backdropColor),
          backdropFilter: props.backdropBlur === 'some' ? 'blur(5px)' : 'none',
        },
      }}
      open={props.open}
      onClose={props.onClose}
      fullWidth
      fullScreen={props.fullScreen}
      scroll={props.scroll || 'body'}
    >
      {props.children}
    </MuiDialog>
  );
}
