import React from 'react';
import { communityUrls, userUrls } from 'urls';
import { DashboardMessage as DashboardMessageDTO } from 'apis/DashboardAPI';
import MentorCtaCard from 'domain/mentoring/MentorCta/MentorCtaCard';
import { CardGridWithLimit } from 'ui/views/cards/CardGrid';
import { Link } from 'react-router-dom';
import FinishUserProfile from '../messages/FinishInvestorProfile';
import DashboardMessage from '../messages/DashboardMessage';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import Resources from 'util/resource/Resources';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import SearchIcon from 'ui/elements/icons/SearchIcon';
import { CompanyMessages } from 'pages/Dashboard/manage/CompanyMessages';
import styled from '@emotion/styled';
import { contentSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import { bluePlanetTheme } from 'ui/theme';
import featureToggle from 'featureToggle';
import Logo from 'ui/domain/Logo';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

export const healthArenaMessageKey = `has-dismissed-health-arena-message`;

interface Props {
  messages: DashboardMessageDTO[];
}

const MessagesContainer = styled.div`
  /* This isn't great, but with the current way dashboard messages are implemented there is no way of knowing whether we are showing any */
  & > * > * {
    margin-bottom: ${sectionSpacing};
    ${bluePlanetTheme.breakpoints.down('lg')} {
      margin-bottom: ${contentSpacing};
    }
  }
`;

export default function OnYourAgenda({ messages }: Props) {
  const restOfMessages = messages ?? [];

  const { resource: userResource } = useSelfUserProfile();

  return (
    <Resources resources={[userResource]}>
      {([user]) => (
        <MessagesContainer>
          <MobileMargin>
            <CardGridWithLimit
              mobile={{ visibleCardsCount: 2, columnCount: 1 }}
              tablet={{ visibleCardsCount: 2, columnCount: 2 }}
              desktop={{ visibleCardsCount: 3, columnCount: 3 }}
              xl={{ visibleCardsCount: 3, columnCount: 3 }}
              xxl={{ visibleCardsCount: 3, columnCount: 3 }}
            >
              <CompanyMessages messages={messages} />
              {restOfMessages.map(message => {
                switch (message.type) {
                  case 'you-are-pending-in-community': {
                    const community = message.community;
                    const isDnbCommunity = featureToggle.communityIsConnectedToDnb(community.id);

                    return (
                      <DashboardMessage
                        key={message.type}
                        color={{
                          background: isDnbCommunity ? '#285358' : bluePlanetTheme.bluePlanetPalette.white,
                          text: isDnbCommunity
                            ? bluePlanetTheme.bluePlanetPalette.white
                            : bluePlanetTheme.bluePlanetPalette.indigo.dark,
                        }}
                        topElement={<Logo size={70} type="community" url={community.logoURL}></Logo>}
                      >
                        <p className="text-weight-medium">You are pending access to {community.name}</p>
                        <p>
                          Please wait for the community admin to review your profile, you will be notified as soon as
                          you are approved.
                        </p>
                        <div style={{ marginTop: 'auto' }}>
                          <Link
                            className={isDnbCommunity ? 'text-link-light' : 'text-link'}
                            to={communityUrls.overview(community.slug)}
                          >
                            View community
                          </Link>
                        </div>
                      </DashboardMessage>
                    );
                  }
                  case 'you-are-new-in-community': {
                    const community = message.community;
                    const isDnbCommunity = featureToggle.communityIsConnectedToDnb(community.id);
                    const joinedAs =
                      message.role === 'company_admin_in_community'
                        ? (message.joinedAsCompany?.name ?? 'Your company') + ' has'
                        : 'You have';

                    return (
                      <DashboardMessage
                        key={message.type}
                        color={{
                          background: isDnbCommunity ? '#285358' : bluePlanetTheme.bluePlanetPalette.white,
                          text: isDnbCommunity
                            ? bluePlanetTheme.bluePlanetPalette.white
                            : bluePlanetTheme.bluePlanetPalette.indigo.dark,
                        }}
                        topElement={<Logo size={70} type="community" url={community.logoURL}></Logo>}
                      >
                        <p>
                          {joinedAs} recently joined {community.name}
                        </p>
                        <div style={{ marginTop: 'auto' }}>
                          <Link
                            className={isDnbCommunity ? 'text-link-light' : 'text-link'}
                            to={communityUrls.overview(community.slug)}
                          >
                            Go to community
                          </Link>
                        </div>
                      </DashboardMessage>
                    );
                  }
                  case 'thanks-for-signing-up-as-investor':
                  case 'thanks-for-signing-up-as-other':
                    return <FinishUserProfile key="thanks-for-signing-up-as-user" />;

                  default:
                    return <MentorCtaCard key={message.type} message={message} />;
                }
              })}

              {user.isInvestor && (
                <Link className="u-flex" to={userUrls.discover}>
                  <DashboardMessage
                    key="message-explore-pitches"
                    topElement={
                      <IconAvatar color="blue-light" size={{ width: '60px', height: '60px' }}>
                        <SearchIcon fontSize="large" color="indigo"></SearchIcon>
                      </IconAvatar>
                    }
                  >
                    <p>Explore pitches and create shortlists</p>
                  </DashboardMessage>
                </Link>
              )}
            </CardGridWithLimit>
          </MobileMargin>
        </MessagesContainer>
      )}
    </Resources>
  );
}
