import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function BriefcaseIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M10 13.942c0-.31 0-.465.034-.592a1 1 0 0 1 .707-.707c.127-.034.282-.034.592-.034h1.334c.31 0 .465 0 .592.034a1 1 0 0 1 .707.707c.034.127.034.282.034.592v.667a2 2 0 1 1-4 0v-.667Z" />
      <path d="M13.9 14.109h1.183c1.284 0 1.926 0 2.473-.116 1.582-.335 2.873-1.363 3.438-2.739.195-.475.275-1.05.434-2.2.06-.431.09-.647.061-.823-.084-.515-.496-.936-1.05-1.074-.192-.048-.433-.048-.915-.048H4.476c-.482 0-.724 0-.914.048-.555.138-.967.56-1.051 1.074-.029.176.001.392.061.824.16 1.15.239 1.724.434 2.2.565 1.375 1.856 2.403 3.438 2.738.547.116 1.19.116 2.473.116H10.1" />
      <path d="M3.5 12.109v2c0 3.771 0 5.657 1.106 6.828 1.107 1.172 2.888 1.172 6.45 1.172h1.888c3.562 0 5.343 0 6.45-1.172C20.5 19.766 20.5 17.88 20.5 14.11v-2" />
      <path d="m15.5 7.109-.077-.353c-.385-1.76-.578-2.64-1.036-3.144-.458-.503-1.067-.503-2.285-.503h-.204c-1.218 0-1.827 0-2.285.503-.458.504-.65 1.384-1.036 3.144l-.077.353" />
    </SvgIcon>
  );
}
