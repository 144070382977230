import { asUserAuthor } from 'domain/shared/author';
import React from 'react';
import { UserProfile } from 'types/user';
import { CompanyUpdate, PostUpdateTo } from 'types/content/update';
import UpdateForm from '../UpdateForm';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { companyUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import LoadableResource from 'util/resource/Resource';
import useNotify from 'hooks/useNotify';
import { ICompany } from 'types/company';
import useLazyResource from 'util/resource/useLazyResource';
import { CompanyUpdateFormValues } from 'domain/Updates/CompanyUpdate/UpdateForm/types';

interface Props {
  setActive: (v: boolean) => void;
  user?: UserProfile;
  onComplete: () => void;
  companyUpdate: CompanyUpdate;
  company: ICompany;
}

export default function EditUpdateDialog(props: Props) {
  const { user, companyUpdate } = props;
  const { update } = companyUpdate;

  const api = companyUpdateAPI(companyUpdate.companyId);

  const author = asUserAuthor(user);
  const notify = useNotify();

  const [attachmentsResource] = useResourceLegacy(
    () => (update.attachments.length > 0 ? api.attachments.list(update.id) : Promise.resolve({ documents: [] })),
    [update.id],
  );
  const [editUpdate, isSaving] = useLazyResource(
    (dto: CompanyUpdateFormValues & { postTo: PostUpdateTo }) => {
      const updatesAPI = companyUpdateAPI(props.company.id);
      return updatesAPI.editUpdate(update.id, { ...dto, visibility: dto.postTo.visibility }).then(() => {});
    },
    {
      onSuccess: () => {
        props.onComplete();
        props.setActive(false);
        notify('success', 'Post updated!');
      },
      onFailure: () => {
        notify('error', 'Something went wrong when trying to update post');
      },
    },
  );

  // show post in feed before it goes away to the community

  return (
    <LoadableResource resource={attachmentsResource}>
      {attachments => (
        <UpdateForm
          titleDraft={update.title}
          contentDraft={update.content}
          category={update.category}
          bannerImage={update.bannerImage}
          attachments={attachments}
          author={author}
          selectedVisibility={companyUpdate.visibility}
          linkPreview={update.linkPreview}
          onSave={(content, postTo) => editUpdate({ ...content, postTo })}
          saveButtonText="Save"
          isSaving={isSaving}
          onClose={() => props.setActive(false)}
          company={props.company}
          contentId={props.companyUpdate.update.id}
        />
      )}
    </LoadableResource>
  );
}
