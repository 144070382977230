import ApiBase from 'apis/ApiBase';
import config from 'config';
import { List, PaginatedResult } from 'types/api';
import { CompanyProfile } from 'types/company';
import {
  DetailedUserProfile,
  IntercomFeedback,
  PatchUserPayload,
  DetailedShortlist,
  Shortlist,
  UserProfile,
  PatchShortlist,
} from 'types/user';
const baseUrl = config.COMPANIES_API_URL;

const api = ApiBase();

export const userAPIUrls = {
  self: {
    detailed: `${baseUrl}/userProfiles/me/detailed`,
  },
  userProfiles: {
    list: () => `${baseUrl}/userProfiles/byCwUserId`,
  },
  shortlists: {
    list: () => `${baseUrl}/company-shortlists`,
  },
  followerRequests: {
    listSent: () => `${baseUrl}/users/follower-requests/sent`,
  },
  matches: {
    featured: () => `${baseUrl}/matches/featured`,
  },
};

export const usersApi = {
  myNetwork: (nameFilter: string) => api.get<List<UserProfile>>(`${baseUrl}/users/me/network?name=${nameFilter}`),
  isConnected: (userId: UUID) => api.get<Boolean>(`${baseUrl}/users/${userId}/is-connected`),
  matches: (queryParams: string) => api.get<CompanyProfile[]>(`${baseUrl}/matches${queryParams}`),
  feedback: (body: IntercomFeedback) => api.post(`${baseUrl}/users/feedback`, body),
  emailFeedback: (token: UUID, body: Object) => api.post(`${config.NOTIFY_API_URL}/feedback/${token}`, body),
  shortlists: {
    get: (listId: UUID) => api.get<DetailedShortlist>(`${baseUrl}/company-shortlists/${listId}`),
    create: (listName?: string, description?: string) =>
      api.post<Shortlist>(`${baseUrl}/company-shortlists`, { name: listName, description }),
    duplicate: (listId: UUID) => api.post<Shortlist>(`${baseUrl}/company-shortlists/${listId}/duplicate`, {}),
    update: (payload: PatchShortlist) => api.patch<Shortlist>(`${baseUrl}/company-shortlists`, payload),
    delete: (listId: UUID) => api.deleteRequest<UUID>(`${baseUrl}/company-shortlists/${listId}`),
    updateCompanies: (listId: UUID, companyIds: string[], communityContextSlug?: string) =>
      api.put<Shortlist>(`${baseUrl}/company-shortlists/${listId}/companies`, {
        companyIds,
        communityContextSlug,
      }),
  },
  userProfiles: {
    get: (cwUserId: string) => api.get<UserProfile>(`${baseUrl}/userProfiles/byCwUserId/${cwUserId}`),
    getDetailed: (cwUserId: string) => {
      return api.get<DetailedUserProfile>(`${baseUrl}/userProfiles/byCwUserId/${cwUserId}/detailed`);
    },
    list: (cwUserIds: string[]) => {
      return api.post<PaginatedResult<UserProfile>>(userAPIUrls.userProfiles.list(), {
        cwUserIds,
      });
    },
    patchProfile: (body: PatchUserPayload) => {
      return api.patch<DetailedUserProfile>(`${baseUrl}/users`, body);
    },
    updateProfileImage: (url?: string) =>
      api.put(`${baseUrl}/users/image`, {
        imageURL: url,
      }),
  },
};
