import React, { CSSProperties, useState } from 'react';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import { ButtonColors, ButtonProps } from 'ui/elements/buttons/Button';
import { ICompany } from 'types/company';
import ChatButton from './ChatButton';
import { Link, useRouteMatch } from 'react-router-dom';
import cx from 'classnames';
import { trackConversationOpened } from './actions';
import useRoleTrackingKeys from 'hooks/useRoleTrackingKeys';
import CompanyConversationDialogAsUser from './CompanyConversationDialogAsUser';
import { useCompanyConversationAsMe } from 'apis/ContentAPI/useCompanyConversationsAsMe';
import { getOrUndefined } from 'util/resource';

interface Props {
  className?: string;
  company: ICompany;
  buttonKind: ButtonProps['kind'];
  dialogHandler?: DialogHandlerProps;
  buttonText?: string;
  buttonColor?: ButtonColors;
  icon?: 'default' | 'none';
  initialMessage?: string;
  trackingKey: string;
  style?: CSSProperties;
}

export function useCompanyConversationAsUser(companyId: number, trackingKey: string) {
  const [isOpen, setIsOpen] = useState(false);
  const { resource: conversationMeResource } = useCompanyConversationAsMe(companyId);
  const match = useRouteMatch<{ communitySlug?: string }>();

  const onOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsOpen(true);
    trackConversationOpened({
      withCompanyId: companyId,
      key: trackingKey,
      path: match.path,
      communitySlug: match.params.communitySlug,
    });
  };

  return {
    isOpen,
    onOpen,
    onClose: () => setIsOpen(false),
    numUreadMessages: getOrUndefined(conversationMeResource)?.numUnread ?? 0,
  };
}

export default function CompanyConversationAsUser(props: Props) {
  const { isOpen, onOpen, onClose } = useCompanyConversationAsUser(props.company.id, props.trackingKey);

  return (
    <>
      <ChatButton
        className={props.className}
        kind={props.buttonKind}
        onClick={onOpen}
        numUnreadMessages={0}
        buttonText={props.buttonText}
        color={props.buttonColor}
        icon={props.icon}
        style={props.style}
      />
      {isOpen && (
        <CompanyConversationDialogAsUser
          company={props.company}
          initialMessage={props.initialMessage}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
}

export function CompanyConversationLinkAsUser({
  company,
  trackingKey,
  href,
  children,
}: {
  company: ICompany;
  trackingKey: string;
  href: string;
  children: React.ReactNode;
}) {
  const roleTrackingKeys = useRoleTrackingKeys(company.slug);
  const match = useRouteMatch<{ communitySlug?: string }>();

  return (
    <Link
      to={href}
      className={cx(`data-track-${trackingKey}`, roleTrackingKeys)}
      onClick={() => {
        trackConversationOpened({
          withCompanyId: company.id,
          key: trackingKey,
          path: match.path,
          communitySlug: match.params.communitySlug,
        });
      }}
    >
      {children}
    </Link>
  );
}
