import { Link } from 'react-router-dom';
import { organizationUrls } from 'urls';
import Logo from 'ui/domain/Logo';
import React from 'react';
import styles from '../styles.scss';
import classnames from 'classnames';

export const OrganizationLogo = ({ slug, logoUrl }: { slug: string; logoUrl?: string | undefined }) => {
  return (
    <Link className={classnames('u-flex u-flex', styles.link)} to={organizationUrls.view(slug)}>
      <div className={styles.companyLogoWrapper}>
        <Logo url={logoUrl} type={'organization'} size={59} />
      </div>
      <p>Overview</p>
    </Link>
  );
};
