import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CalendarIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18 2.609v2m-12-2v2" />
      <path d="M10 17.609v-3.653c0-.192-.137-.347-.305-.347H9m4.63 4 1.354-3.651c.063-.17-.071-.35-.263-.35H13" />
      <path d="M2.5 12.852c0-4.357 0-6.536 1.252-7.89C5.004 3.61 7.02 3.61 11.05 3.61h1.9c4.03 0 6.046 0 7.298 1.353C21.5 6.316 21.5 8.495 21.5 12.852v.513c0 4.358 0 6.536-1.252 7.89s-3.268 1.354-7.298 1.354h-1.9c-4.03 0-6.046 0-7.298-1.354C2.5 19.901 2.5 17.723 2.5 13.365v-.513ZM6 8.609h12" />
    </SvgIcon>
  );
}
