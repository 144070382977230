import { OrganizationType } from 'types/organization';
import React, { ReactNode } from 'react';
import useResource from 'util/resource/useResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import EditablePicker from 'pages/Organization/shared/EditablePicker';

interface EditableOrganizationTypesProps {
  canEdit: boolean;
  onConfirm: (value: OrganizationType[]) => Promise<any> | void;
  initialTypesNames: string[];
  valueComponent: ReactNode;
  style?: React.CSSProperties;
}

export default function EditableOrganizationTypes(props: EditableOrganizationTypesProps) {
  const { resource } = useResource<OrganizationType[]>(OrganizationAPI.listAllTypesUrl);

  return (
    <EditablePicker
      {...props}
      name={'parentOrganization'}
      placeholder={'Select types'}
      initialItems={allOptions =>
        props.initialTypesNames
          .map(type => allOptions.find(orgType => type === orgType.name))
          .filter(type => type != undefined)
      }
      label={'Add types'}
      optionLabel={t => t.name}
      optionsResource={resource}
    />
  );
};
