import React, { useState } from 'react';
import { CompanyListItem } from 'types/company/company';
import CardStack from 'ui/views/cards/CardStack';
import ClickableCard from 'ui/views/cards/ClickableCard';
import fuzzysearch from 'fuzzysearch';
import Button from 'ui/elements/buttons/Button';
import Logo from 'ui/domain/Logo';
import { companyUrls, userUrls } from 'urls';
import { CompanyRoles } from './common/CompanyRoles';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import Indicator from 'ui/elements/Indicator/NumberIndicator';
import LastUpdated from 'domain/companies/profile/LastUpdated';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import TextFilter from 'domain/shared/Filters/TextFilter';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

interface Props {
  companies: CompanyListItem[];
}

const Title = React.memo(function Title({ c }: { c: CompanyListItem }) {
  return (
    <div
      style={{ width: '100%' }}
      className="u-flex u-flex-space-between u-flex-align-center u-flex--wrap u-flex--gap-half"
    >
      <div>
        {c.name}
        <LastUpdated companyId={c.id} lastUpdated={c.lastUpdated}></LastUpdated>
      </div>
      <CompanyRoles roles={c.roles}></CompanyRoles>
    </div>
  );
});

export default function CompanyList({ companies }: Props) {
  const [search, setSearch] = useState('');

  const filteredCompanies = !search
    ? companies
    : companies.filter(c => fuzzysearch(search.toLowerCase(), c.name.toLowerCase()));

  const limit = 12;
  const [showAll, setShowAll] = useState(false);

  return (
    <div>
      <Link to={userUrls.dashboard.network.overview()} className="text-link">
        <ChevronLeftIcon fontSize="small" /> Back
      </Link>
      <MobileMargin>
        <SectionHeadingLarge
          className="u-content-spacing-top"
          heading={
            <>
              Companies you are connected with{' '}
              <Indicator className="u-quarter-spacing-left" color="grey" shape="square" value={companies.length} />
            </>
          }
        >
          {/* Direction here is a trick to make the text box grow from right to left, instead of left to right */}
          <div style={{ direction: 'rtl' }}>
            <TextFilter style={{ direction: 'ltr' }} placeholder="Company name" setValue={setSearch} value={search} />
          </div>
        </SectionHeadingLarge>
      </MobileMargin>
      <CardStack className="u-flex u-flex--column u-flex-grow">
        {filteredCompanies.slice(0, showAll ? undefined : limit).map(c => (
          <ClickableCard
            href={companyUrls.overview(c.slug, 'updates')}
            key={`company=${c.id}`}
            avatar={<Logo size={40} url={c.logoURL} type={c.isCommunity ? 'community' : 'company'} />}
            title={<Title c={c} />}
          />
        ))}
      </CardStack>
      {!showAll && filteredCompanies.length > limit && (
        <div className="u-half-spacing-top">
          <Button kind="tertiary" onClick={() => setShowAll(true)}>
            Show {filteredCompanies.length - limit} more
          </Button>
        </div>
      )}
    </div>
  );
}
