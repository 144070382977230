import React from 'react';
import Resources from 'util/resource/Resources';
import { useShortlists } from 'apis/CompanyAPI/users/useShortlists';
import CompanyProfileCard from 'domain/companies/CompanyCard/CompanyCard';
import { useFeaturedMatches } from 'apis/CompanyAPI/users/useFeaturedMatches';
import useLocalStorage from 'hooks/useLocalStorage';
import { distinct } from 'util/arrayUtils';
import styled from '@emotion/styled';
import { useDebouncedCallback } from 'use-debounce';
import { css } from '@emotion/react';
import CompanyProfileDialog from 'domain/companies/ProfileDialog/CompanyProfileDialog';
import Carousel, { CarouselProps } from 'ui/elements/Carousel';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import Button from 'ui/elements/buttons/Button';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

interface Props extends Pick<CarouselProps, 'bleedAmount'> {
  className?: string;
  useCarouselOnLargeScreen?: boolean;
}

const Container = styled.div(
  ({ isDismissed, useCarouselOnLargeScreen }: { isDismissed: boolean; useCarouselOnLargeScreen?: boolean }) => css`
    // For transition
    ${useCarouselOnLargeScreen &&
    css`
      max-height: 1000px;
    `}
    transition: all 0.3s ease-out;
    ${isDismissed &&
    css`
      overflow: hidden;
      margin-bottom: 0 !important;
      max-height: 0;
    `}
  `,
);

export default function FeaturedCompanyMatchesForCurrentUser(props: Props) {
  const { resource: matchesResource } = useFeaturedMatches();
  const { resource: shortlistResource, mutate: setShortlistResource } = useShortlists();

  const [dismissedMatches, setDismissedMatches] = useLocalStorage<number[]>('dismissed-matches-for-user', []);
  // For cool animation 😎
  const [isDismissed, setIsDismissed] = React.useState(false);
  const dismissMatches = useDebouncedCallback(companyIds => setDismissedMatches(distinct(companyIds)), 310);
  const [featuredCompanyInDialog, setFeaturedCompanyInDialog] = React.useState<number | undefined>();

  const isTabletUp = useMediaQuery(bluePlanetTheme.breakpoints.up('md'));
  const isDesktopUp = useMediaQuery(bluePlanetTheme.breakpoints.up('lg'));
  const isXlUp = useMediaQuery(bluePlanetTheme.breakpoints.up('xl'));

  const useCarousel = (isDesktopUp && props.useCarouselOnLargeScreen) || !isDesktopUp;

  return (
    <Resources resources={[matchesResource, shortlistResource]} renderLoading="Nothing">
      {([matches, shortlists]) => {
        const companies = matches.filter(it => !dismissedMatches.includes(it.id));
        const findNextMatch = (companyId: number) =>
          companies.find((_, index) => companies[index - 1]?.id === companyId)?.id;
        const findPreviousMatch = (companyId: number) =>
          companies.find((_, index) => companies[index + 1]?.id === companyId)?.id;
        const visibleCardsCount = isXlUp ? 4 : isDesktopUp ? 3 : isTabletUp ? 2 : 1;

        return (
          companies.length > 0 && (
            <div>
              <MobileMargin>
                <Container
                  className={props.className}
                  isDismissed={isDismissed}
                  useCarouselOnLargeScreen={props.useCarouselOnLargeScreen}
                >
                  <SectionHeadingLarge heading="Companies you might find interesting">
                    <Button
                      kind="primary"
                      disabled={isDismissed}
                      color="white"
                      onClick={() => {
                        setIsDismissed(true);
                        dismissMatches([...dismissedMatches, ...companies.map(c => c.id)]);
                      }}
                    >
                      Dismiss
                    </Button>
                  </SectionHeadingLarge>
                  {useCarousel && (
                    <Carousel numVisible={visibleCardsCount} seeMoreIsVisible={false} bleedAmount={props.bleedAmount}>
                      {companies.map(company => (
                        <CompanyProfileCard
                          key={company.id}
                          company={{
                            details: company,
                            location: company.location,
                            industries: company.industries.values,
                            stages: company.stages.values,
                            // The following are not available in CompanyProfile atm, so set them to empty/fallback values in order to comply with type
                            labels: { limit: 0, page: 0, pages: 0, pageSize: 0, total: 0, values: [] },
                            hasActiveFundingRound: false,
                            fundingStage: company.fundingStage,
                          }}
                          action={{
                            type: 'click',
                            handler: companyId => setFeaturedCompanyInDialog(companyId),
                          }}
                          shortlists={shortlists.values}
                          setShortlists={setShortlistResource}
                        />
                      ))}
                    </Carousel>
                  )}
                  {!useCarousel && (
                    <div className="u-flex u-flex--column" style={{ gap: '1rem' }}>
                      {companies.map(company => (
                        <CompanyProfileCard
                          key={company.id}
                          company={{
                            details: company,
                            location: company.location,
                            industries: company.industries.values,
                            stages: company.stages.values,
                            // The following are not available in CompanyProfile atm, so set them to empty/fallback values in order to comply with type
                            labels: { limit: 0, page: 0, pages: 0, pageSize: 0, total: 0, values: [] },
                            hasActiveFundingRound: false,
                            fundingStage: company.fundingStage,
                          }}
                          action={{
                            type: 'click',
                            handler: companyId => setFeaturedCompanyInDialog(companyId),
                          }}
                          shortlists={shortlists.values}
                          setShortlists={setShortlistResource}
                        />
                      ))}
                    </div>
                  )}
                </Container>
              </MobileMargin>

              {featuredCompanyInDialog && (
                <CompanyProfileDialog
                  analyticsContext="featured-matches"
                  companyId={featuredCompanyInDialog}
                  actionBar={{
                    onNext: () => setFeaturedCompanyInDialog(findNextMatch(featuredCompanyInDialog)),
                    onPrevious: () => setFeaturedCompanyInDialog(findPreviousMatch(featuredCompanyInDialog)),
                    hasNext: !!findNextMatch(featuredCompanyInDialog),
                    hasPrevious: !!findPreviousMatch(featuredCompanyInDialog),
                  }}
                  analyticsCommunityId="none"
                  onClose={() => setFeaturedCompanyInDialog(undefined)}
                />
              )}
            </div>
          )
        );
      }}
    </Resources>
  );
}
