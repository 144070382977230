import React from 'react';
import SvgIcon, { IconProps } from './SvgIcon';

export default function UserAccountIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M14 9.804h4M14 13.304h3" />
      <path d="M17 3.804H7a5 5 0 0 0-5 5v8a5 5 0 0 0 5 5h10a5 5 0 0 0 5-5v-8a5 5 0 0 0-5-5Z" />
      <path d="M5 16.804c1.208-2.58 5.712-2.75 7 0" />
      <path d="M10.5 9.804a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
    </SvgIcon>
  );
}
