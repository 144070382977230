import { UserProfile } from 'types/user';

export const mentorExpertise = {
  aiMachineLearning: 'AI & Machine Learning',
  alternativeLendingAndFinance: 'Alternative Lending and Finance',
  auditingCompliance: 'Auditing & Compliance',
  bankingAsAService: 'Banking as a Service',
  biotechnology: 'Biotechnology',
  businessDevelopment: 'Business Development',
  businessModels: 'Business Models',
  chinaMarket: 'China Market',
  commercialization: 'Commercialization',
  commercialLending: 'Commercial Lending',
  commercialPropertyManagement: 'Commercial Property Management',
  commercialRealEstate: 'Commercial Real Estate',
  companyCulture: 'Company Culture',
  corporateStrategy: 'Corporate Strategy',
  costCutting: 'Cost Cutting',
  customerAcquisitionRetentionEngagement: 'Customer Acquisition, Retention, Engagement',
  digitalBanking: 'Digital Banking',
  digitalExperience: 'Digital Experience',
  digitalMarketing: 'Digital Marketing',
  distributionStrategy: 'Distribution Strategy',
  ecommerce: 'E-Commerce',
  engineering: 'Engineering',
  exitsIPOs: 'Exits/IPOs',
  fermentedFoods: 'Fermented Foods',
  fitnessAndWellnessManagement: 'Fitness and Wellness Management',
  foodProcessOptimization: 'Food Process Optimization',
  foodQuality: 'Food Quality',
  foodRegulation: 'Food Regulation',
  foodSafety: 'Food Safety',
  foodScience: 'Food Science',
  functionalFoods: 'Functional Foods',
  funding: 'Funding',
  fundingAngelSeed: 'Funding (Angel/Seed)',
  fundingVenture: 'Funding (Venture >$1 million)',
  fundraisingStrategy: 'Fundraising Strategy',
  goToMarketStrategy: 'Go-to-Market Strategy',
  growth: 'Growth',
  healthCare: 'Healthcare',
  healthRecordManagement: 'Health Record Management',
  healthTech: 'Healthtech',
  industryFourZero: 'Industry 4.0',
  innovation: 'Innovation',
  insuranceAdvisor: 'Insurance Advisor',
  insuranceBrokerage: 'Insurance Broker',
  insuranceClaims: 'Insurance Claims',
  insuranceUnderwriting: 'Insurance Underwriting',
  internationalization: 'Internationalization',
  internationalizationNonUs: 'Internationalization (Entering non-US Markets)',
  internationalizationUs: 'Internationalization (Entering the US Market)',
  investmentAutomation: 'Investment Automation',
  investmentPortfolioManagement: 'Investment Portfolio Management',
  investmentTrends: 'Investment Trends',
  kyckyb: 'KYC/KYB',
  legalServicesContracts: 'Legal Services: Contracts',
  legalServicesFundraising: 'Legal Services: Fundraising',
  legalServicesIPTrademark: 'Legal Services: IP & Trademark',
  logistics: 'Logistics',
  manufacturing: 'Manufacturing',
  marketing: 'Marketing',
  marketingBrand: 'Marketing Brand',
  marketingSocialMedia: 'Marketing Social Media',
  marketingStrategy: 'Marketing Strategy',
  microbiology: 'Microbiology',
  mobileIndustry: 'Mobile Industry',
  negotiatingTerms: 'Negotiating Terms',
  nutrition: 'Nutrition',
  operations: 'Operations',
  organisationDevelopment: 'Organisation Development',
  partnerships: 'Partnerships',
  payments: 'Payments',
  pricing: 'Pricing',
  product: 'Product Development',
  productDesign: 'Product Design',
  projectManagement: 'Project Management',
  regulatoryRiskCompliance: 'Regulatory Risk & Compliance',
  retirementInvestmentManagement: 'Retirement Investment Management',
  robotics: 'Robotics',
  sales: 'Sales',
  strategy: 'Strategy',
  talentDevelopment: 'Talent Development',
  technology: 'Technology',
  teleMedicine: 'Tele-medicine',
  uiux: 'UI/UX',
  virology: 'Virology',
  wealthManagement: 'Wealth Management',
};

export type MentorExpertise = keyof typeof mentorExpertise;

export const mentorExpertises = Object.keys(mentorExpertise).map((key: MentorExpertise) => ({
  value: key,
  label: mentorExpertise[key],
}));

export interface Mentorshiplist {
  values: Mentorship[];
}

export interface MentorMentorship {
  id: UUID;
  mentorMasterNetworkId: number;
  programName: string;
  status: MentorshipStatus;
  scope?: string;
  mentorFinalReport?: string;
  mentorAgreementFileId?: string;
  finalReportFileId?: string;
}

export interface MentorNetworkMentorshiplist {
  values: MentorNetworkMentorship[];
}

export interface MentorNetworkMentorship {
  id: UUID;
  mentorMasterNetworkId: number;
  programName: string;
  status: MentorshipStatus;
  finalReport?: string;
  mentorFinalReport?: string;
  finalReportFileId?: string;
  scope?: string;
  mentor?: UserProfile;
  mentorAskDescription?: string;
  mentorAskExpertise: MentorExpertise[];
}

export interface MentorAsk {
  mentorAskDescription?: string;
  mentorAskExpertise?: MentorExpertise[];
}

export interface UpdateMentorAsk {
  mentorAskDescription?: string;
  mentorAskExpertise?: MentorExpertise[];
}

export interface UpdateMenteeReport {
  finalReport?: string;
}

export interface UpdateMentorReport {
  finalReport?: string;
}

export interface UpdateMentorshipScope {
  scope?: string;
}

export const MentorshipStatusValues = <const>[
  'profile_in_progress',
  'ready_for_transfer',
  'transferred_to_sub_network',
  'ready_for_mentor_assignment',
  'mentor_assigned',
  'mentor_agreement_signed',
  'mentoring_in_progress',
  'final_report_done',
];

export type MentorshipStatus = (typeof MentorshipStatusValues)[number];

export interface Mentorship {
  id: UUID;
  mentorMasterNetworkId: number;
  programName: string;
  status: MentorshipStatus;
  finalReport?: string;
  scope?: string;
  mentor?: UserProfile;
  mentorAgreementFileId?: string;
  finalReportFileId?: string;
}
