import PhotoIcon from 'ui/elements/icons/ImageIcon';
import React from 'react';

import Button from 'ui/elements/buttons/Button';
import ImageUpload from 'ui/modules/ImageUpload';
import Placeholder from 'ui/modules/ImageUpload/Placeholder';

import { notify } from 'actions/snackbar';
import { ContentAPI } from 'apis/ContentAPI';
import { resize } from 'util/cloudinary';
import config from 'config';

import styles from './styles.scss';
import { CloudinaryUploadResponse } from 'types';

interface Props {
  logoURL?: string;
  companyId: number;
  notify: typeof notify;
  onUploadSuccess: (companyId: number, image: CloudinaryUploadResponse) => void;
}

export function UploadLogo(props: Props) {
  const api = ContentAPI(`${config.CONTENT_API_URL}/companies/${props.companyId}`);

  return (
    <ImageUpload
      width={120}
      aspectRatio={1}
      onUploadSuccess={image => {
        props.onUploadSuccess(props.companyId, image);
      }}
      onUploadFailure={e => {
        props.notify('error', 'Upload failed. Only jpeg & png images are allowed.');
      }}
      getUploadUrl={filename => api.images.getCompanyUploadUrl(props.companyId, filename)}
      imageUrl={resize(props.logoURL, { width: 500, height: 500 })}
      placeholder={
        <Placeholder shape="round">
          <div className={styles.logoPlaceholder}>
            <PhotoIcon fontSize="large" />
            Drop .png or .jpeg logo here
          </div>
        </Placeholder>
      }
      shape="round"
    >
      {openDropzone => (
        <div>
          <Button kind="secondary" onClick={openDropzone} className="u-content-spacing-bottom">
            <span>Upload image</span>
          </Button>
          <p className="text-metadata-regular">
            To upload or change your logo, click to select one or drag your .png or .jpeg file onto the circle.
          </p>
        </div>
      )}
    </ImageUpload>
  );
}
