import React, { useEffect, useState } from 'react';
import { DocumentDTO } from 'types/documents';
import PitchDeckPDFPreview from './PitchDeckPDFPreview';
import { pdfjs } from 'react-pdf';
import PitchDeckPDF from './PitchDeckPDF';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import { useKeyboardNavigation } from 'contexts/KeyboardNavigationContext';
import { useClickOutside } from 'hooks/useClickOutside';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import usePitchDeckViewLogger from 'hooks/usePitchDeckViewLogger';
import useCompanyRole from 'hooks/useCompanyRole';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const keyboardNavigationContextID = 'PitchDeckPDFViewer';

interface Props {
  document: DocumentDTO;
  company: ICompany;
  viewAs: CompanyViewAs;
  pitchDeckTitle?: string;
}

export default function PitchDeckPDFContainer(props: Props) {
  const [pitchDeckPath, setPitchDeckPath] = useState('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(1);
  const [dialogPageNumber, setDialogPageNumber] = useState<number | null>(null);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const { navigationContext, setNavigationContext } = useKeyboardNavigation();

  const access = useCompanyRole(props.company.slug);

  const logger = usePitchDeckViewLogger(props.company.id, props.document.id, access.canEditCompany);

  useEffect(() => {
    logger(pageNumber);
  }, [pageNumber]);

  function goToNextPage() {
    setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));
  }

  function goToPreviousPage() {
    setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  }

  function goToPage(num: number) {
    setPageNumber(num);
  }

  const handleDownload = () => {
    companiesApi.profile.pitchDocuments
      .download(props.company.id, props.document.id, 'preview')
      .then(response => setPitchDeckPath(response.path));
  };

  const handleKeyboardNavigation = (event: KeyboardEvent) => {
    if (navigationContext !== keyboardNavigationContextID) return;
    if (event.key === 'ArrowRight') {
      goToNextPage();
    } else if (event.key === 'ArrowLeft') {
      goToPreviousPage();
    }
  };

  useClickOutside(containerRef, () => {
    setNavigationContext(null);
  });

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardNavigation);
    return () => {
      window.removeEventListener('keydown', handleKeyboardNavigation);
    };
  }, [pageNumber, navigationContext]);

  useEffect(() => {
    handleDownload();
  }, [props.document.id]);

  return (
    <div ref={containerRef} onPointerDown={() => setNavigationContext(keyboardNavigationContextID)}>
      <PitchDeckPDFPreview
        document={props.document}
        company={props.company}
        pitchDeckPath={pitchDeckPath}
        pageNumber={pageNumber}
        onThumbnailClick={clickedPage => setDialogPageNumber(clickedPage ?? pageNumber)}
        onNextPage={goToNextPage}
        onPreviousPage={goToPreviousPage}
        onPageChange={goToPage}
        viewAs={props.viewAs}
        pitchDeckTitle={props.pitchDeckTitle}
        numPages={numPages}
        setNumPages={setNumPages}
      />
      {dialogPageNumber && (
        <PitchDeckPDF
          pitchDeckPath={pitchDeckPath}
          onClose={() => setDialogPageNumber(null)}
          pageNumber={dialogPageNumber}
          onPageChange={goToPage}
        />
      )}
    </div>
  );
}
