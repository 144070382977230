import PageTitle from 'ui/views/layouts/BaseLayout/PageTitle';
import React from 'react';
import MaxWidth from 'ui/views/containers/MaxWidth';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { contentSpacing, halfSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import { useMobileHeader } from 'domain/shared/Navigation';
import ScrollableBody from './ScrollableBody';

interface Props {
  title: string;
  heading?: React.ReactNode;
  children: React.ReactNode;
}

export const Content = styled.div(`
    margin-top: ${contentSpacing};
    margin-right: ${contentSpacing};
    margin-left: ${contentSpacing};
    
  ${bluePlanetTheme.breakpoints.down('sm')} {
    margin-right: 0;
    margin-left: 0;
  }

  ${bluePlanetTheme.breakpoints.up('lg')} {
    margin-top: ${sectionSpacing};
    margin-right: ${sectionSpacing};
    margin-left: ${sectionSpacing};
  }
`);

export const MobileMargin = styled.div(`
  ${bluePlanetTheme.breakpoints.down('sm')} {
    margin-right: ${halfSpacing};
    margin-left: ${halfSpacing};
  }
`);

export const Heading = styled.div`
  background-color: ${bluePlanetTheme.bluePlanetPalette.white};
  padding: ${contentSpacing};

  ${bluePlanetTheme.breakpoints.down('sm')} {
    padding-right: ${halfSpacing};
    padding-left: ${halfSpacing};
  }

  ${bluePlanetTheme.breakpoints.up('md')} {
    margin-bottom: ${contentSpacing};
  }

  ${bluePlanetTheme.breakpoints.up('lg')} {
    padding: ${sectionSpacing};
    margin-bottom: ${sectionSpacing};
  }
`;

export default function BaseLayout(props: Props) {
  const { title } = props;
  useMobileHeader(title);

  return (
    <>
      <PageTitle title={title} />
      <ScrollableBody accountForMobileHeader>
        {props.heading && (
          <Heading>
            <MaxWidth style={{ margin: '0 auto' }} width="xl" className="u-flex u-flex-space-between">
              {props.heading}
            </MaxWidth>
          </Heading>
        )}
        <Content>
          <MaxWidth width="xl" style={{ margin: '0 auto' }}>
            {props.children}
          </MaxWidth>
        </Content>
      </ScrollableBody>
    </>
  );
}
