import { ResourceAction, ResourceName } from 'types/company/company';
import { hasScope } from 'util/scopeUtils';
import { useCompanyAccess } from 'apis/CompanyAPI/companies/useCompanyAccess';
import { getOrUndefined } from 'util/resource';
import { ActingAs } from 'types/company/access';

export default function useCompanyRole(slug: string) {
  const { resource: accessResource } = useCompanyAccess(slug);

  const access = getOrUndefined(accessResource);
  const scopes = access?.scopes ?? [];
  const roles = access?.roles ?? [];

  const canEdit = hasScope({ action: 'Write', resource: 'Company' }, scopes);
  const canView = hasScope({ action: 'Read', resource: 'Company' }, scopes);

  return {
    isFetched: accessResource.state === 'fetched',
    canEditCompany: canEdit,
    canViewCompany: canView,
    hasScope: (action: ResourceAction, resource: ResourceName, actingAs?: ActingAs) =>
      hasScope({ action, resource, actingAs }, scopes),
    roles: roles,
    scopes: scopes,
  };
}
