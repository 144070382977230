export const HELP_CENTER_URL = 'https://help.crowdworks.it/en';
export const CROWDWORKS_LOGO_BLUE = 'https://assets.crowdworks.it/email/crowdworks-logo--blue.png';
export const PAGINATION_SMALL_PAGESIZE = 10;
export const PAGINATION_DEFAULT_PAGESIZE = 20;

export const SUPPORT_EMAIL = 'hello@crowdworks.it';
export const IFRAMELY_API_KEY = '00bcc6c9aa111bc4cf52b1';
export const CLOUDINARY_API_KEY = '737135125859726';

export const SUPPORTED_DOCUMENTS_PREVIEW = ['.pdf'];
export const SUPPORTED_IMAGES_PREVIEW = ['.png', '.jpeg', '.jpg', '.svg', '.gif'];

export const SUPPORTED_DOCUMENT_FILE_TYPES_BY_MIME_TYPE = {
  'image/*': ['.png', '.jpeg', '.jpg', '.svg', '.gif'],
  'application/*': ['.xls', '.pdf', '.xlsm', '.xlsx', '.ppt', '.pptx', '.doc', '.docx'],
};

export const SUPPORTED_DOCUMENT_FILE_TYPES: {
  mimes: string[];
  extension: string;
}[] = [
  { mimes: ['application/pdf'], extension: 'pdf' },
  { mimes: ['image/png'], extension: 'png' },
  { mimes: ['image/jpeg'], extension: 'jpeg' },
  { mimes: ['image/jpeg'], extension: 'jpg' },
  { mimes: ['image/svg'], extension: 'svg' },
  { mimes: ['image/gif'], extension: 'gif' },
  { mimes: ['application/vnd.ms-excel'], extension: 'xls' },
  { mimes: ['application/vnd.ms-excel.sheet.macroEnabled.12'], extension: 'xlsm' },
  {
    mimes: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    extension: 'xlsx',
  },
  { mimes: ['application/msword'], extension: 'ppt' },
  {
    mimes: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    extension: 'pptx',
  },
  { mimes: ['application/vnd.ms-powerpoint'], extension: 'doc' },
  {
    mimes: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
    extension: 'docx',
  },
];

export const DOCUMENT_UPLOAD_LIMIT = {
  human: '100MB',
  bytes: 100000000,
};

export const PROFILE_IMAGE_UPLOAD_LIMIT = {
  human: '25MB',
  bytes: 25000000,
};

export const PROFILE_IMAGE_SIZE_SMALL = 160;
export const BANNER_IMAGE_WIDTH = 1785;
export const UPDATE_BANNER_IMAGE_WIDTH = 1400;
export const LOGO_MIN_SIZE = 240;
export const DATE_FORMAT = 'dd/MM/yyyy';
export const INTERCOM_CHAT_BUBBLE_OFFSET = 80;
export const MISSION_MAX_LENGTH = 2000;
export const SHORTLIST_DESCRIPTION_MAX_LENGTH = 500;
export const CARD_IMAGE_WIDTH = 1200;
