import MUIChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import cx from 'classnames';
import React from 'react';
import { Role } from 'types/company';
import { bluePlanetTheme } from 'ui/theme';
import { roleDisplayName } from 'domain/companies/roleUtils';
import TruncatedList from 'util/components/TruncatedList';
import ChipList from 'ui/elements/Chip/ChipList';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import styled from '@emotion/styled';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

interface Props {
  role: Role | 'pending_access';
  className?: string;
  ref?: MuiChipProps['ref'];
  title?: MuiChipProps['title'];
  onClick?: MuiChipProps['onClick'];
}

function getColor(props: Props) {
  const palette = bluePlanetTheme.bluePlanetPalette;
  switch (props.role) {
    case 'board':
      return { backgroundColor: palette.purple.light, color: palette.indigo.main };
    case 'investor':
      return { backgroundColor: palette.blue.veryLight, color: palette.indigo.main };
    case 'mentor':
      return { backgroundColor: palette.green.medium, color: palette.indigo.main || '' };
    case 'company_master':
      return { backgroundColor: palette.yellow.medium, color: palette.indigo.main };
    case 'company_admin_in_community':
      return { backgroundColor: palette.red.medium, color: palette.indigo.main };
    case 'pending_access':
      return { backgroundColor: palette.yellow.medium, color: palette.indigo.main };
    case 'prospective_investor':
      return { backgroundColor: palette.orange.medium, color: palette.indigo.main };
    default:
      return { backgroundColor: palette.grey.medium, color: palette.indigo.main };
  }
}

export const RoleLabel = (props: Props) => {
  return (
    <MUIChip
      sx={{
        ...getColor(props),
        padding: 0,
        '.MuiChip-label': {
          padding: '0 0.40625rem',
        },
      }}
      className={cx(props.className)}
      label={props.role === 'pending_access' ? 'Pending approval' : roleDisplayName(props.role)}
      onClick={props.onClick}
      ref={props.ref}
    />
  );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    padding: bluePlanetTheme.spacing(1),
    gap: bluePlanetTheme.spacing(0.5),
    boxShadow: bluePlanetTheme.shadows[3],
  },
}));

export function CompanyRoles({ roles }: { roles: Role[] }) {
  const rolesWithAdminFirst = roles.sort((a, b) => (a === 'company_master' ? -1 : b === 'company_master' ? 1 : 0));

  return (
    <div className="u-flex u-flex--gap-quarter">
      {roles.length === 0 ? (
        <RoleLabel role={'pending_access'} />
      ) : (
        <ChipList>
          <TruncatedList
            limit={1}
            values={rolesWithAdminFirst.map(role => ({ name: role }))}
            renderItem={role => <RoleLabel key={`community-${role.name}`} role={role.name} />}
            renderExpandButton={(role, hand) => (
              <HtmlTooltip
                title={
                  <>
                    {rolesWithAdminFirst.slice(1).map(role => (
                      <RoleLabel key={`community-${role}`} role={role as Role} />
                    ))}
                  </>
                }
              >
                <span>
                  <IndustryChip onClick={hand} label={role.name} />
                </span>
              </HtmlTooltip>
            )}
          />
        </ChipList>
      )}
    </div>
  );
}
