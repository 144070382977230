import { externalUrlFetcher } from 'apis/utils';
import useSWR from 'swr';
import { externalUrls } from 'urls';
import { logError } from 'util/logging';

interface MapboxFeature {
  place_type: string[];
  place_name: string;
  text: string;
  center: [number, number]; // According to the documentation of mapbox, the first item in the tuple is longitude, and the second is latitude
  properties: {
    short_code?: string;
  };
  context?: {
    id: string; // This id is on the format "[district|region|country].{number}", and can be used to infer what level the result is on. Although we search for places only, the results can be both place and region at the same time.
    text: string; // The name of the associated context, for instance "Norway" if it is a country
    short_code: string; // What this code represents depends on which context it is in, but for country, it will be the country code.
  }[];
}

export interface MapboxResponse {
  features: MapboxFeature[];
}

export interface Location {
  description: string;
  longitude: number;
  latitude: number;
  place: string;
  district?: string;
  region?: string;
  regionCode?: string;
  country?: string;
  countryCode?: string;
}

interface Props {
  shouldSearch: boolean;
  searchText: string;
  maxResults: number;
}

export function useMapboxLocation({ shouldSearch, searchText, maxResults }: Props): {
  isFetching: boolean;
  error?: Error;
  data?: Location[];
} {
  const { data, error } = useSWR<MapboxResponse>(
    shouldSearch ? externalUrls.mapboxPlacesPermanent(searchText, maxResults) : null,
    externalUrlFetcher,
  );

  if (error) {
    logError(error, 'Error in mapbox-fetching');
  }

  if (!searchText) {
    return { isFetching: false };
  }

  if (!data && !error) {
    return {
      isFetching: true,
    };
  }

  if (!data) {
    return {
      isFetching: false,
      error,
    };
  }

  return {
    data: data.features.map(mapToLocation) || [], // slice up to maxResults here, rather than in the mapbox search query, as we filter the regions out ourselves.
    isFetching: false,
  };
}

export function mapToLocation(feature: MapboxFeature): Location {
  const country = feature.context?.find((element: any) => element.id.includes('country'));
  // Some (usually capital) cities are also regions, and in mapbox v5 these capital regions are not returned in the context but only mentioned as a place_type, and in these cases the region code will be found inside properties
  const region = feature.place_type.includes('region')
    ? { text: feature.text, short_code: feature.properties.short_code }
    : feature.context?.find((element: any) => element.id.includes('region'));
  const district = feature.context?.find((element: any) => element.id.includes('district'));
  return {
    description: feature.place_name,
    longitude: feature.center[0],
    latitude: feature.center[1],
    place: feature.text,
    country: country?.text,
    countryCode: country?.short_code,
    district: district?.text,
    region: region?.text,
    regionCode: region?.short_code,
  };
}
