import useRoute from 'hooks/useRoute';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { BackdropColor } from 'ui/theme/themeBluePlanet';
import UserProfileDialog from './UserProfileDialog';

export function userDialogUrl(baseURL: string, cwUserId: UUID) {
  return `${baseURL}/users/${cwUserId}`;
}

interface Props {
  backdropColor?: BackdropColor;
}

export default function UserProfileDialogRoute(props: Props) {
  const match = useRouteMatch();
  const { push } = useRoute();

  return (
    <Route
      path={`${match.path}/users/:cwUserId`}
      render={routeProps => (
        <UserProfileDialog
          cwUserId={routeProps.match.params.cwUserId}
          onClose={() => push(match.url)}
          backdropColor={props.backdropColor}
        />
      )}
    />
  );
}
