import { CalendarEvent } from 'calendar-link';
import { TinyCommunity } from 'types/company/community';
import { OrganizationWithCommunities } from 'types/organization';

export interface CompactEventDTO {
  id: UUID;
  title: string;
  content: string;
  startsAt: string;
}

export interface EventDTO {
  id: UUID;
  startsAt: string;
  endsAt?: string;
  title: string;
  location: string;
  locationUrl?: string;
  content: string;
  calendarContent?: string;
  organizations?: OrganizationWithCommunities[];
}

export interface NewEventDTO {
  startsAt: string;
  endsAt?: string;
  title: string;
  location: string;
  locationUrl?: string;
  content: string;
  calendarContent?: string;
}

export interface UpdateEventDTO {
  startsAt: string;
  endsAt?: string;
  title: string;
  location: string;
  locationUrl?: string;
  content: string;
  calendarContent?: string;
  organizations?: { organizationId: string; communityIdList: number[] }[];
}

export interface EventWithCommunitiesDTO {
  event: CompactEventDTO;
  communityList: TinyCommunity[];
}

export const getCalendarEvent = (event: EventDTO): CalendarEvent => ({
  title: event.title,
  description: event.calendarContent ?? event.content,
  start: event.startsAt,
  end: event.endsAt,
  allDay: !event.endsAt,
  location: event.location,
});
