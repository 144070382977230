import { Masonry } from '@mui/lab';
import useWindowWidth from 'hooks/useWindowWidth';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

export default function MasonryGrid({ children }: Props) {
  const width = useWindowWidth();
  const isSmallScreen = width < bluePlanetTheme.breakpoints.values.md;

  if (isSmallScreen) {
    // The masonry elements always has some margin that bleeds into the margin surrounding the entire page, but on the smallest screens,
    // we do not have any margin on the sides. Therefore, we skip using masonry when we only want one column
    return (
      <div className="u-flex u-flex--column" style={{ gap: bluePlanetTheme.spacing(1) }}>
        {children}
      </div>
    );
  }
  return (
    <Masonry
      spacing={2}
      style={{ width: 'auto' }} // As the masonry component is experimental (part of the lab dependency of MUI), it has a visual bug where it does not take the entire width without overriding the default setting of 100% to auto (03.10.22)
      columns={2}
    >
      {children}
    </Masonry>
  );
}
