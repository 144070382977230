import React from 'react';
import ContactMeButton from 'domain/users/ContactMeButton';

export default function PitchReview({ companyId }: { companyId: number }) {
  return (
    <div className="u-flex u-flex--column u-flex--gap-half">
      <h4 className="text-h4">Get real world feedback on your pitch</h4>
      <span>
        Your pitch will be sent to a selection of investors that will review it as they would any pitch - only this time
        you will get their honest feedback in return, and based on that you can fine-tune and adjust your pitch.
      </span>
      <ContactMeButton
        tag={`wants-pitch-review-${companyId}`}
        feedback="InterestedInPitchReviewFundingRound"
        companyId={companyId}
      />
    </div>
  );
}
