import React from 'react';
import { ICompany, UserProfilesSummaryDTO } from 'types/company';
import useResource from 'util/resource/useResource';
import CompanyAPI from 'apis/CompanyAPI';
import Resource from 'util/resource/Resource';
import { PaginatedResult } from 'types';
import { FollowerRequestDTO } from 'types/follower';
import BuildYourNetwork from './BuildYourNetwork';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import FinishCompanyProfile from './FinishCompanyProfile';
import { Todo } from './Todo';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import { DashboardMessage } from 'apis/DashboardAPI';
import UpdateInvestors from './UpdateInvestors';
import UpdateCompanyProfile from './UpdateCompanyProfile';
import Indicator from 'ui/elements/Indicator/NumberIndicator';
import PromoteInvestorMatching from './PromoteInvestorMatching';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

interface Props {
  company: ICompany;
  userSummary: UserProfilesSummaryDTO;
  messages: DashboardMessage[];
}

export default function Todos({ company, userSummary, messages }: Props) {
  const { resource: followerRequestResource } = useResource<PaginatedResult<FollowerRequestDTO>>(
    CompanyAPI.follower.company.list(
      company.id,
      { limit: 2 },
      {
        status: 'pending',
      },
    ),
  );

  const updateInvestorsMessage = messages.find(
    (m): m is { type: 'should-update-investors-as-company-admin'; company: ICompany; lastUpdated: string } =>
      m.type === 'should-update-investors-as-company-admin',
  );

  const finishCompanyProfileMessage = messages.find(m => m.type === 'welcome-to-company-profile-as-new-company-admin');
  const shouldUpdateProfileMessage = messages.find(
    (m): m is { type: 'should-update-company-profile-as-company-admin'; company: ICompany; lastUpdated: string } =>
      m.type === 'should-update-company-profile-as-company-admin',
  );

  const todosCount =
    Number(!!updateInvestorsMessage) + Number(!!finishCompanyProfileMessage) + Number(!!shouldUpdateProfileMessage);

  return (
    <div className="u-section-spacing-bottom">
      <MobileMargin>
        <SectionHeadingLarge
          heading={
            <>
              To-do&apos;s <Indicator className="u-half-spacing-left" value={todosCount} />
            </>
          }
        />
      </MobileMargin>
      {todosCount > 0 ? (
        <div className="u-flex u-flex--column u-flex--gap-half">
          <Resource resource={followerRequestResource} renderLoading="Nothing">
            {followerList => (
              <BuildYourNetwork company={company} followerList={followerList} userSummary={userSummary} />
            )}
          </Resource>
          {finishCompanyProfileMessage && <FinishCompanyProfile company={company} />}
          {updateInvestorsMessage && (
            <UpdateInvestors company={company} lastPostedUpdate={updateInvestorsMessage.lastUpdated}></UpdateInvestors>
          )}
          {!finishCompanyProfileMessage && shouldUpdateProfileMessage && (
            <UpdateCompanyProfile
              company={company}
              lastUpdatedProfile={shouldUpdateProfileMessage.lastUpdated}
            ></UpdateCompanyProfile>
          )}
        </div>
      ) : (
        <Todo>
          <IconAvatar color="success" size={{ width: '40px', height: '40px' }}>
            <CheckmarkIcon />
          </IconAvatar>
          You have completed your tasks, well done!
        </Todo>
      )}
      <div className="u-half-spacing-top">
        <PromoteInvestorMatching companyId={company.id} />
      </div>
    </div>
  );
}
