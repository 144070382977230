import { PostConversationMessage } from 'apis/ContentAPI/conversationTypes';
import SeeMore from 'ui/modules/Pagination/SeeMore';
import React, { useRef, useState } from 'react';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { Comment } from 'types/content';
import { getOrUndefined } from 'util/resource';
import Button from 'ui/elements/buttons/Button';
import CommentCard from './CommentCard';
import { OnCommentDeleted } from './CommentsContainer';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import styles from './styles.scss';
import classNames from 'classnames';
import { pluralize } from 'util/stringUtils';
import CommentField, { UpdateContext } from './CommentField';
import { useSelector } from 'react-redux';
import { RootStore } from 'globalstate/rootStore';
import { scrollolol } from 'util/scroll';

interface Props {
  comment: Comment;
  onReply: (commentId: string, comment: string) => Promise<void>;
  onSeeMoreReplies: (paginationOptions: PaginationOptions) => Promise<any>;
  onDeleteComment: OnCommentDeleted;
  updateId: string;
  context: UpdateContext;
  replies?: PaginatedResult<Comment>;
}

export default function CommentContainer({
  comment,
  onReply,
  onSeeMoreReplies,
  onDeleteComment,
  updateId,
  context,
  replies,
}: Props) {
  const [showResponseForm, setShowResponseForm] = useState(false);

  const userProfiles = useSelector((state: RootStore) => state.userProfiles.items);

  const commentsScrollToAnchorRef = useRef<HTMLDivElement>(null);

  const scrollToCommentInputField = () => {
    // Add delay here because expanding the comments section also expands the update content, meaning the location we should scroll to changes
    setTimeout(() => {
      const commentsContainerTop = commentsScrollToAnchorRef?.current?.getBoundingClientRect()?.top ?? 0;
      scrollolol(commentsContainerTop - 200);
    }, 100);
  };

  const toggleShow = () => {
    setShowResponseForm(prevState => !prevState);
    scrollToCommentInputField();
  };

  const resetForm = () => {
    setShowResponseForm(false);
  };

  const onSubmit = async (reply: PostConversationMessage) => {
    onReply(comment.id, reply.content).then(() => {
      resetForm();
    });
  };

  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);

  // Append logged in user to all user profiles in case the user has not commented before
  // If not, the first comment of the user will not get the name or profile picture of the author
  const allUserProfiles = userProfile ? [...userProfiles, userProfile] : userProfiles;

  return (
    <div className="u-content-spacing-y">
      <CommentCard
        updateId={updateId}
        userProfiles={allUserProfiles}
        loggedInUser={userProfile}
        comment={comment}
        showResponseForm={showResponseForm}
        responses={replies ? replies.total : 0}
        respondable
        toggleShow={toggleShow}
        onDeleteComment={onDeleteComment}
      />

      {replies && replies.values.length > 0 && (
        <>
          {replies.values.map(reply => {
            return (
              <CommentCard
                isResponse
                key={reply.id}
                updateId={updateId}
                userProfiles={allUserProfiles}
                loggedInUser={userProfile}
                comment={reply}
                showResponseForm={showResponseForm}
                toggleShow={toggleShow}
                onDeleteComment={onDeleteComment}
              />
            );
          })}
          <SeeMore
            text="Show more replies"
            resource={replies}
            render={(onClick, remain, isLoading) => (
              <Button
                className={classNames(styles.responseFormIndent, 'u-half-spacing-top')}
                onClick={onClick}
                kind="tertiary"
                isLoading={isLoading}
              >
                {remain} more {pluralize(remain, 'reply', 'replies')}
              </Button>
            )}
            loadResource={onSeeMoreReplies}
          />
          <div ref={commentsScrollToAnchorRef} />
        </>
      )}
      {showResponseForm && userProfile && (
        <>
          <CommentField
            className={classNames(styles.responseFormIndent, 'u-content-spacing-top')}
            updateId={updateId}
            onPost={onSubmit}
            autoFocus={true}
            onInputClicked={scrollToCommentInputField}
            onCancel={resetForm}
            context={context}
            editorPlaceholder="Write a reply"
          />
        </>
      )}
    </div>
  );
}
