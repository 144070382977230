import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';
import { ButtonColors } from './Button';

interface Props {
  href?: string;
  onClick?: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  color?: ButtonColors;
  kind?: 'primary' | 'secondary' | 'tertiary';
  shape?: 'square' | 'rounded';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  target?: string;
  rel?: string;
}

export default function AnchorAsButton(props: Props) {
  const color = props.color ?? 'blue';
  const size = props.size ?? 'medium';
  const kind = props.kind ?? 'primary';
  const shape = props.shape ?? 'square';

  return (
    <a
      href={props.href}
      onClick={props.onClick}
      target={props.target}
      rel={props.rel}
      className={cx(props.className, styles[shape], styles.button, styles[color], styles[kind], styles[size])}
    >
      {props.children}
    </a>
  );
}
