import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'ui/elements/buttons/Button';
import SectionHeading from 'ui/elements/SectionHeading';
import graphIllustration from 'ui/illustrations/graphs.svg';
import Card from 'ui/views/cards/Card';
import { CompanyConversationLinkAsUser } from 'domain/conversations/CompanyConversationAsUser';
import { companyUrls } from 'urls';
import EditAction from 'ui/elements/actions/EditAction';
import cx from 'classnames';
import KPIGraph from 'domain/kpis/KPIGraph';
import KpiAPI from 'apis/KpiAPI';
import Resource from 'util/resource/Resource';
import { Grid } from '@mui/material';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import useResource from 'util/resource/useResource';
import { Kpi } from 'domain/kpis/types';

interface Props {
  company: ICompany;
  className?: string;
  viewAs: CompanyViewAs;
}

export default function Kpis({ company, viewAs, className }: Props) {
  const { resource } = useResource<Kpi[]>(KpiAPI.kpi.list(company.id));

  const isCompanyAdmin = viewAs === 'Admin';

  return (
    <Resource resource={resource}>
      {resource => {
        const kpis = resource.filter(kpi => !kpi.details.archivedAt);
        return kpis.length > 0 ? (
          <>
            <SectionHeading heading="Key performance indicators" addMobileMargin>
              {isCompanyAdmin && (
                <Link
                  style={{
                    // Because of MUI Grid
                    zIndex: 1337,
                  }}
                  to={companyUrls.kpi.manage(company.slug)}
                >
                  <EditAction tooltip="Update KPIs" />
                </Link>
              )}
            </SectionHeading>
            <Grid container spacing={2} alignItems="stretch">
              {kpis.map(kpi => (
                <Grid key={kpi.details.id} item xs={12} sm={6} xl={4}>
                  <Card key={kpi.details.id} className={cx('u-content-spacing-bottom', className)}>
                    <KPIGraph kpi={kpi} />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <SectionHeading heading="Key performance indicators" addMobileMargin />
            <Card
              type={isCompanyAdmin ? 'dashed' : 'default'}
              data-intercom-target="kpi-widget"
              className="u-content-spacing-bottom u-relative"
            >
              <div className="u-flex u-flex--column u-flex-align-center">
                {!isCompanyAdmin ? (
                  <>
                    <img style={{ maxWidth: '10rem' }} src={graphIllustration} />
                    <p className="text-body u-content-spacing-y">
                      Reach out to the company and suggest which KPIs you would like to see here. Letting them know will
                      help both of you get the most out of CrowdWorks!
                    </p>
                    <CompanyConversationLinkAsUser
                      company={company}
                      trackingKey="company-suggest-kpi"
                      href={companyUrls.inbox.showAsUser(company.slug)}
                    >
                      <Button kind="tertiary">Suggest KPIs</Button>
                    </CompanyConversationLinkAsUser>
                  </>
                ) : (
                  <>
                    <img style={{ maxWidth: '10rem' }} src={graphIllustration} />
                    <p className="text-body u-content-spacing-y">
                      Tracking KPIs gives your company and investors a clear sense of direction, helping you achieve your
                      company&apos;s goals. You can add and edit KPIs using the KPI Management page.
                    </p>
                    <Link to={companyUrls.kpi.manage(company.slug)}>
                      <Button kind="tertiary">Add your first KPI</Button>
                    </Link>
                  </>
                )}
              </div>
            </Card>
          </>
        );
      }}
    </Resource>
  );
}
