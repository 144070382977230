import React from 'react';
import { Todo } from './Todo';
import Button from 'ui/elements/buttons/Button';
import { Network } from 'ui/illustrations/Illustrations.stories';
import ContactMeButton from 'domain/users/ContactMeButton';
import useLocalStorage from 'hooks/useLocalStorage';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';

export default function PromoteInvestorMatching({ companyId }: { companyId: number }) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const [promoteInvestorMatching, setPromoteInvestorMatching] = useLocalStorage(
    `promote-investor-matching-${companyId}`,
    {
      showMessage: true,
      showDismissButton: true,
    },
  );

  return (
    promoteInvestorMatching.showMessage && (
      <Todo>
        <Network />
        <div className={`u-flex ${isMobile ? 'u-flex--column u-flex--gap-half' : 'u-flex--gap-2'}`}>
          <span style={{ margin: 'auto 0' }}>
            <b>Investor matching</b> will get you hot leads from our network and increase your chances of building
            relevant connections. Prices from €500.
          </span>
          <div className="u-flex u-flex--gap-1">
            <div
              onClick={() =>
                setPromoteInvestorMatching({
                  ...promoteInvestorMatching,
                  showDismissButton: false,
                })
              }
            >
              <ContactMeButton
                tag={`wants-investor-matching-${companyId}`}
                feedback="InterestedInInvestorMatchingDashboard"
                companyId={companyId}
              />
            </div>

            {promoteInvestorMatching.showDismissButton && (
              <Button
                kind="tertiary"
                color="grey"
                onClick={() =>
                  setPromoteInvestorMatching({
                    ...promoteInvestorMatching,
                    showMessage: false,
                  })
                }
              >
                Dismiss
              </Button>
            )}
          </div>
        </div>
      </Todo>
    )
  );
}
