import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function StarSquareIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path
        stroke="#000"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M2.804 12c0-4.478 0-6.718 1.391-8.109C5.587 2.5 7.825 2.5 12.305 2.5c4.477 0 6.717 0 8.108 1.391 1.391 1.391 1.391 3.63 1.391 8.109 0 4.478 0 6.718-1.391 8.109s-3.63 1.391-8.109 1.391c-4.478 0-6.717 0-8.109-1.391-1.39-1.392-1.39-3.63-1.39-8.109Z"
      />
      <path
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="m13.168 7.722.88 1.774c.12.247.44.484.71.53l1.595.267c1.02.171 1.26.917.525 1.653l-1.24 1.25c-.21.212-.325.62-.26.913l.355 1.547c.28 1.225-.365 1.699-1.44 1.059l-1.495-.892c-.27-.162-.715-.162-.99 0l-1.495.892c-1.07.64-1.72.161-1.44-1.059l.355-1.547c.065-.293-.05-.7-.26-.913l-1.24-1.25c-.73-.736-.494-1.482.526-1.653l1.594-.267a1.09 1.09 0 0 0 .705-.53l.88-1.774c.48-.963 1.26-.963 1.735 0Z"
      />
    </SvgIcon>
  );
}
