import { Tooltip } from '@mui/material';
import useDialogHandler from 'hooks/useDialogHandler';
import React, { useState } from 'react';
import { TrustLevel } from 'types/company';
import { FolderDTO } from 'types/documents';
import EditIcon from 'ui/elements/icons/EditIcon';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import CreateFolderDialog from './CreateFolderDialog';
import IconButton from 'ui/elements/icons/IconButton';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import DocumentAPI from 'apis/DocumentAPI';
import { invalidate } from 'hooks/useSWR';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Button from 'ui/elements/buttons/Button';
import { documentCenterFoldersKey } from 'apis/DocumentAPI/useDocuments';
import PopMenu from 'ui/modules/PopMenu';

interface FoldersActionsCellProps {
  companyId: number;
  trustLevels: TrustLevel[];
  folder: FolderDTO;
  folders: FolderDTO[];
  mobile: boolean;
  canUploadDocument: boolean;
}

export default function FoldersActions(props: FoldersActionsCellProps) {
  const [isDeleteFolderDialogOpen, setIsDeleteFolderDialogOpen] = useState(false);
  const editDialogHandler = useDialogHandler();
  const notify = useNotify();

  const [editFolder, isEditingFolder] = useLazyResource(
    (payload: { trustLevels: TrustLevel[]; name: string }) =>
      DocumentAPI.editFolder(props.companyId, props.folder.id, payload),
    {
      onSuccess: () => {
        invalidate(documentCenterFoldersKey(props.companyId));
        notify('success', 'Changes saved');
      },
      onFailure: e => notify('error', e || 'Could not save folder'),
    },
  );

  const [deleteFolder, isDeletingFolder] = useLazyResource(
    (folderId: string) => DocumentAPI.deleteFolder(props.companyId, folderId),
    {
      onSuccess: () => {
        invalidate(documentCenterFoldersKey(props.companyId));
        notify('success', 'Folder deleted');
      },
      onFailure: error => notify('error', error || 'Could not delete folder'),
    },
  );

  if (!props.canUploadDocument) {
    return null;
  }

  const parent = props.folder.parentId ? props.folders.find(f => f.id === props.folder.parentId) : undefined;
  const menu = props.mobile ? (
    <PopMenu
      items={[
        {
          text: <>Edit folder</>,
          icon: <EditIcon />,
          onClick: editDialogHandler.open,
        },
        {
          text: <>Delete folder</>,
          icon: <TrashIcon />,
          onClick: () => setIsDeleteFolderDialogOpen(true),
        },
      ]}
    />
  ) : (
    <div>
      <Tooltip title="Edit folder">
        <span>
          <IconButton
            color="indigo"
            hoverColor="blue"
            className="u-half-spacing-right"
            aria-label="Edit folder"
            onClick={editDialogHandler.open}
          >
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Delete folder">
        <span>
          <IconButton
            color="indigo"
            hoverColor="red"
            aria-label="Delete folder"
            onClick={() => setIsDeleteFolderDialogOpen(true)}
          >
            <TrashIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );

  return (
    <div className="u-flex--aSelf-end">
      {menu}
      <CreateFolderDialog
        dialogHandler={editDialogHandler}
        parentAccesses={parent && parent.accesses}
        existingAccesses={props.folder.accesses}
        folderName={props.folder.name}
        onSubmit={(folderName: string, trustLevels: TrustLevel[]) => {
          editFolder({ name: folderName, trustLevels });
          editDialogHandler.close();
        }}
        loading={isEditingFolder}
        type="edit"
        trustLevels={props.trustLevels}
        companyId={props.companyId}
      />

      <Dialog open={isDeleteFolderDialogOpen} onClose={() => setIsDeleteFolderDialogOpen(false)}>
        <Title onClose={() => setIsDeleteFolderDialogOpen(false)}>You are about to delete this folder </Title>

        <Content>
          <p>
            Delete folder <strong>“{props.folder.name}”</strong>.
          </p>
          <p>
            <em>Note: Only empty folders can be deleted.</em>
          </p>
          <p>Are you sure?</p>
        </Content>
        <DialogActions>
          <ButtonList>
            <Button
              isLoading={isDeletingFolder}
              kind="primary"
              color="red"
              onClick={() => deleteFolder(props.folder.id)}
            >
              Yes, delete
            </Button>
            <Button kind="tertiary" onClick={() => setIsDeleteFolderDialogOpen(false)}>
              Cancel
            </Button>
          </ButtonList>
        </DialogActions>
      </Dialog>
    </div>
  );
}
