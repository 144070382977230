import { AllIndustries, Industry } from 'types';
import React, { ReactNode } from 'react';
import { companiesApi, companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import useResource from 'util/resource/useResource';
import { fetched } from 'util/resource';
import EditablePicker from 'pages/Organization/shared/EditablePicker';

interface EditableIndustriesProps {
  canEdit: boolean;
  onConfirm: (value: Industry[]) => Promise<any> | void;
  initialIndustriesNames: string[];
  valueComponent: ReactNode;
  style?: React.CSSProperties;
}

export const IndustryResource = () => {
  const { resource } = useResource<AllIndustries>(companiesAPIUrls.industries.listAll());

  if (resource.state == 'fetched') {
    return fetched<Industry[]>([...resource.resource.parents, ...resource.resource.children]);
  }

  return resource;
};

export default function EditableIndustries(props: EditableIndustriesProps) {
  const resource = IndustryResource();

  return (
    <EditablePicker
      {...props}
      name={'parentIndustries'}
      placeholder={'Select industries'}
      initialItems={allIndustries =>
        props.initialIndustriesNames
          .map(industry => allIndustries.find(indu => industry === indu.name))
          .filter(type => type != undefined)
      }
      optionLabel={t => t.name}
      optionalOnAdd={(input, optionsLength, selectedItem) =>
        companiesApi.industries.search(input, optionsLength, selectedItem?.name)
      }
      optionsResource={resource}
      label={'Add industries'}
    />
  );
}
