import { Organization } from 'types/organization';
import React, { useState } from 'react';
import OrganizationEditLogoAndBanner from 'pages/Organization/shared/OrganizationEditLogoAndBanner';
import Button from 'ui/elements/buttons/Button';
import { SUCCESS_WIDTH } from 'pages/CompanySettings/utils';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';
import { FormikProps } from 'formik';
import useNotify from 'hooks/useNotify';

interface LogoAndBannerSettingsProps {
  organization: Organization;
  onShowPreview: () => void;
  formik: FormikProps<any>;
}

export function LogoAndBannerSettings(props: LogoAndBannerSettingsProps) {
  const notify = useNotify();
  const [success, setSuccess] = useState(false);
  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(props.organization, {
    onSuccess: () => {
      setSuccess(true);
      notify('success', 'Settings saved');
    },
  });

  return (
    <>
      <OrganizationEditLogoAndBanner
        organizationId={props.organization.id}
        logoUrl={props.organization.logoUrl}
        bannerUrl={props.organization.bannerUrl}
        onLogoUploadSuccess={url => {
          props.formik.setFieldValue('logoUrl', url);
        }}
        onBannerUploadSuccess={url => {
          props.formik.setFieldValue('bannerUrl', url);
        }}
      />
      <ButtonList align="left" className="u-section-spacing-top">
        <Button
          onClick={() =>
            updateOrganization({
              logoUrl: props.formik.values.logoUrl,
              bannerUrl: props.formik.values.bannerUrl,
            })
          }
          kind="primary"
          isLoading={isUpdatingOrganization}
          isSuccess={success}
          setSuccess={(isSuccess: boolean) => setSuccess(isSuccess)}
          style={{ width: `${SUCCESS_WIDTH}px` }}
          disabled={isUpdatingOrganization || success}
        >
          Update
        </Button>
        <Button kind="tertiary" color="grey" onClick={props.onShowPreview}>
          <EyeIcon className="u-quarter-spacing-right" /> Preview profile
        </Button>
      </ButtonList>
    </>
  );
}
