import { Organization } from 'types/organization';
import React from 'react';
import ScrollableBody from 'ui/views/layouts/BaseLayout/ScrollableBody';
import voidIllustration from 'ui/illustrations/void.svg';

interface PageProps {
  organization: Organization;
}
export function OrganizationManagePage(props: PageProps) {
  return (
    <ScrollableBody accountForMobileHeader>
      <div className="u-align-center u-section-padding-y">
        <h1 className="text-h2 u-content-spacing-bottom">In construction</h1>
        <div style={{ maxWidth: '18rem', margin: '5rem auto' }}>
          <img src={voidIllustration} />
        </div>
      </div>
    </ScrollableBody>
  );
}
