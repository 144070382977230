import EditIcon from 'ui/elements/icons/EditIcon';
import React from 'react';

import PopMenu from 'ui/modules/PopMenu';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import { notEmpty } from 'util/arrayUtils';

interface Props {
  onEdit?: () => void;
  onDelete?: () => void;
}

export default function UpdateMenu(props: Props) {
  return (
    <PopMenu
      items={[
        props.onEdit
          ? {
              onClick: props.onEdit,
              icon: <EditIcon />,
              text: <> Edit </>,
            }
          : undefined,
        props.onDelete
          ? {
              onClick: props.onDelete,
              icon: <TrashIcon />,
              text: <> Delete </>,
            }
          : undefined,
      ].filter(notEmpty)}
    />
  );
}
