import { CompanyPendingInCommunity } from 'apis/DashboardAPI';
import featureToggle from 'featureToggle';
import React from 'react';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import { bluePlanetTheme } from 'ui/theme';
import CardHeading from 'ui/views/cards/CardHeading';
import ImageCard from 'ui/views/cards/ImageCard';
import { communityUrls } from 'urls';
import Resource from 'util/resource/Resource';
import HundredPitchesOnYourAgenda from './100pitchesOnYourAgenda';
import { useCompanyApplication } from './useCompanyApplication';

interface Props {
  message: CompanyPendingInCommunity;
}

export default function ApplicationOnYourAgenda({ message }: Props) {
  const { community } = message;
  const { resource: companyApplication } = useCompanyApplication(community.slug, message.company.id);

  const is100Pitches = featureToggle.communityIs100Pitches(community.id);

  return is100Pitches ? (
    <HundredPitchesOnYourAgenda companyId={message.company.id} communitySlug={message.community.slug} />
  ) : (
    <Resource resource={companyApplication}>
      {companyApplication => {
        const hasCompletedApplication = companyApplication.every(a => !!a.answer);
        return (
          <ImageCard
            bleedOnMobile
            backgroundColor={bluePlanetTheme.bluePlanetPalette.white}
            color={bluePlanetTheme.bluePlanetPalette.indigo.dark}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <div>
              <CardHeading
                heading={`Your application to ${community.name}${hasCompletedApplication ? '' : ' is waiting'}`}
              />
              {!hasCompletedApplication ? (
                <p className="text-body u-content-spacing-bottom">
                  {community.name} are waiting for you to complete your application.
                </p>
              ) : (
                <p className="text-body u-content-spacing-bottom">
                  Your application to {community.name} is being reviewed.
                </p>
              )}
            </div>
            <div>
              <LinkAsButton kind={'primary'} url={communityUrls.overview(community.slug)}>
                Go to application
              </LinkAsButton>
            </div>
          </ImageCard>
        );
      }}
    </Resource>
  );
}
