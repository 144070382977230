import { DashboardData } from 'apis/DashboardAPI';
import dashboardApi from 'apis/DashboardAPI';
import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';

export const userDashboardKey = dashboardApi.getUserDashboardData();

export const useUserDashboardData = () => {
  const swrResult = useSWR<DashboardData>(
    userDashboardKey,
    undefined,
    // We don't want to cache too long, since a lot of actions could trigger changes to this data
    { dedupingInterval: 10000 },
  );
  return useSWRResource(swrResult);
};
