import React, { useState } from 'react';
import FundingStageChip from 'ui/domain/Chips/FundingStageChip';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import Dictionary from 'ui/elements/dictionary/Dictionary';
import DictionaryItem from 'ui/elements/dictionary/DictionaryItem';
import Card from 'ui/views/cards/Card';
import CardStack from 'ui/views/cards/CardStack';
import CompanyChat from 'domain/conversations/CompanyConversationAsUser';
import { asDayMonthNameYear } from 'util/dateUtils';
import { CloseFundingRoundDTO, FundingRound as FundingRoundDTO, CompanyProfile } from 'types/company';
import User from 'domain/users/Common/User';
import CardInfoCard from 'ui/views/cards/CardInfoCard';
import { UserProfile } from 'types/user';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import FundingRoundLimitedView from './FundingRoundLimitedView';
import { capitalize } from 'util/stringUtils';
import useDialogHandler from 'hooks/useDialogHandler';
import EditFundingRound from './EditFundingRound';
import { bluePlanetTheme } from 'ui/theme';
import useElementSize from 'hooks/useElementSize';
import classNames from 'classnames';
import { IResource } from 'util/resource';
import Calendly from 'ui/domain/Calendly';
import differenceInDays from 'date-fns/differenceInDays';
import CloseFundingRoundDialog from 'pages/CompanySettings/FundingRound/CloseFundingRoundDialog';
import { useCloseFundingRound } from 'pages/CompanySettings/FundingRound/actions';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import { companyUrls } from 'urls';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import styled from '@emotion/styled';
import FundingRoundShareMenu from './FundingRoundShareMenu';
import Resource from 'util/resource/Resource';
import CurrencyTooltip from 'ui/domain/Currency/CurrencyTooltip';

interface Props {
  className?: string;
  company: CompanyProfile;
  fundingRound: IResource<FundingRoundDTO>;
  onChange?: () => void;
  canEditCompany: boolean;
}

export const getFundingRoundClosingDateText = (closingDate?: string) => {
  if (!closingDate) return '';
  const difference = differenceInDays(new Date(closingDate), new Date());
  if (difference === 0) return 'closes today';
  if (difference === 1) return '1 day left';
  return difference + ' days left';
};

const ButtonContainer = styled.div(
  (props: { width?: number }) => `
  display: flex;
  align-items: flex-start;
  justify-content: ${props.width && props.width < 775 ? 'space-between' : 'unset'};
  flex-direction: ${props.width && props.width < 520 ? 'column' : 'row'};
`,
);

export default function FundingRound(props: Props) {
  const [userDialogProfile, setUserDialogProfile] = useState<UserProfile>();
  const editDialogHandler = useDialogHandler();
  const closeDialogHandler = useDialogHandler();
  const shareCompanyDialogHandler = useDialogHandler();

  const { ref, width } = useElementSize<HTMLDivElement>();

  const elevation = 1;

  const [onCloseFundingRound, isSavingCloseFundingRound] = useCloseFundingRound(props.company.id, props.company.slug, {
    onSuccess: () => {
      props.onChange && props.onChange();
      closeDialogHandler.close();
    },
    onFailure: () => {},
  });

  function closeFundingRound(_: UUID, fundingRound: CloseFundingRoundDTO, shouldUpdateValuation?: boolean) {
    if (fundingRound) {
      onCloseFundingRound({ fundingRound: fundingRound, shouldUpdateValuation });
    }
  }

  return (
    <Resource resource={props.fundingRound} renderLoading="Nothing" renderError="Nothing">
      {fundingRound => {
        if (fundingRound.visibility === 'restricted' && !fundingRound.isActive) {
          return null;
        } else if (fundingRound.visibility === 'restricted' && fundingRound.isActive) {
          return (
            <FundingRoundLimitedView
              company={props.company}
              className={props.className}
              closingDate={fundingRound.plannedClosingDate}
            />
          );
        } else if (fundingRound.visibility === 'full') {
          return (
            <>
              <CardStack className={props.className} ref={ref}>
                <CardInfoCard
                  title="Active funding round"
                  subTitle={getFundingRoundClosingDateText(fundingRound.plannedClosingDate)}
                />
                <Card padding={'none'} elevation={elevation}>
                  <div className="u-content-padding">
                    <div className="text-subheading u-half-spacing-right u-half-spacing-bottom">Terms</div>
                    <Dictionary columnCount={width && width > 820 ? 3 : width && width > 520 ? 2 : 1}>
                      <DictionaryItem label="Pre-money valuation">
                        {fundingRound.preMoneyValuation?.value ? (
                          <CurrencyTooltip value={fundingRound.preMoneyValuation} />
                        ) : (
                          'N/A'
                        )}
                      </DictionaryItem>
                      <DictionaryItem label="Capital seeking">
                        {fundingRound?.capitalSeeking?.value ? (
                          <CurrencyTooltip value={fundingRound?.capitalSeeking} />
                        ) : (
                          'N/A'
                        )}
                      </DictionaryItem>
                      {props.company.fundingStage && (
                        <DictionaryItem label="Funding stage">
                          <FundingStageChip label={props.company.fundingStage.name} />
                        </DictionaryItem>
                      )}
                      {fundingRound?.plannedClosingDate && (
                        <DictionaryItem label="Closing date">
                          {asDayMonthNameYear(fundingRound.plannedClosingDate)}
                        </DictionaryItem>
                      )}
                      {fundingRound?.type && (
                        <DictionaryItem label="Type">{capitalize(fundingRound?.type)}</DictionaryItem>
                      )}
                      {fundingRound?.previousValuation && (
                        <DictionaryItem label="Previous valuation">
                          {fundingRound?.previousValuation.value ? (
                            <CurrencyTooltip value={fundingRound?.previousValuation} />
                          ) : (
                            'N/A'
                          )}
                        </DictionaryItem>
                      )}
                    </Dictionary>
                  </div>
                  {props.canEditCompany && (
                    <ButtonContainer width={width}>
                      <ButtonList className="u-content-padding" axis={width && width > 775 ? 'horizontal' : 'vertical'}>
                        <Button kind="primary" onClick={() => closeDialogHandler.open()} style={{ minWidth: 156 }}>
                          Close funding round
                        </Button>
                        <Button kind="secondary" onClick={editDialogHandler.open} style={{ minWidth: 156 }}>
                          Edit details
                        </Button>
                      </ButtonList>
                      <ButtonList
                        className="u-content-padding"
                        axis={width && width > 775 ? 'horizontal' : 'vertical'}
                        align={'left'}
                      >
                        <LinkAsButton kind="tertiary" url={companyUrls.settings.inviteUser(props.company.slug)}>
                          Invite connections
                        </LinkAsButton>
                        <FundingRoundShareMenu
                          company={props.company}
                          shareCompanyDialogHandler={shareCompanyDialogHandler}
                        />
                      </ButtonList>
                    </ButtonContainer>
                  )}
                </Card>

                {fundingRound?.leadInvestor && (
                  <Card elevation={elevation}>
                    <div className="text-subheading u-content-spacing-bottom">Lead investor</div>
                    <User
                      onClick={() => setUserDialogProfile(fundingRound?.leadInvestor)}
                      user={fundingRound?.leadInvestor}
                    />
                  </Card>
                )}
                <Card
                  elevation={elevation}
                  className={classNames('u-flex-space-between', { 'u-flex-align-center': width && width > 520 })}
                  style={{ flexDirection: width && width < 520 ? 'column' : 'row' }}
                >
                  <div>
                    <h3
                      className="text-metadata-medium"
                      style={{ color: bluePlanetTheme.bluePlanetPalette.indigo.main }}
                    >
                      Are you interested?
                    </h3>
                    <p
                      className={classNames('text-metadata-regular-black u-content-spacing-right', {
                        'u-half-spacing-bottom': width && width < 520,
                      })}
                    >
                      Let the company know, and they will add you to their shortlist group with more info.
                    </p>
                  </div>
                  <ButtonList axis={(width && width > 775) || (width && width < 520) ? 'horizontal' : 'vertical'}>
                    {props.company.calendlyUrl && (
                      <Calendly calendlyUrl={props.company.calendlyUrl}>
                        {onClick => (
                          <Button kind="secondary" onClick={onClick} style={{ minWidth: 130 }}>
                            Ask for meeting
                          </Button>
                        )}
                      </Calendly>
                    )}
                    <CompanyChat
                      style={{ minWidth: 130 }}
                      buttonText="I'm interested"
                      buttonKind="primary"
                      company={props.company}
                      icon="none"
                      initialMessage="Hi, I am interested in your current funding round, can you please give me access to relevant investment documents and term-sheet information?"
                      trackingKey="investment-round"
                    />
                  </ButtonList>
                </Card>
              </CardStack>
              {userDialogProfile && (
                <UserProfileDialog
                  cwUserId={userDialogProfile.cwUserId}
                  onClose={() => setUserDialogProfile(undefined)}
                />
              )}
              {editDialogHandler.isOpen && (
                <EditFundingRound
                  companyId={props.company.id}
                  companySlug={props.company.slug}
                  onClose={editDialogHandler.close}
                  fundingStage={props.company.fundingStage}
                  fundingRound={fundingRound}
                  onSave={() => (props.onChange ? props.onChange() : () => {})}
                />
              )}
              <CloseFundingRoundDialog
                dialogHandler={closeDialogHandler}
                fundingRound={fundingRound}
                onCloseFundingRound={closeFundingRound}
                isSaving={isSavingCloseFundingRound}
              />
              {shareCompanyDialogHandler.isOpen && (
                <UserConversationDialog
                  sharedContent={{ type: 'company', companyId: props.company.id }}
                  conversation={{ type: 'new' }}
                  closeConversation={shareCompanyDialogHandler.close}
                />
              )}
            </>
          );
        } else return null;
      }}
    </Resource>
  );
}
