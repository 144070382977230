import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import IconButton from '../icons/IconButton';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import Card from 'ui/views/cards/Card';
import { Link } from 'react-router-dom';
import {
  contentSpacing,
  halfSpacing,
  quarterSpacing,
  sectionSpacing,
  transitionHover,
  zLayer1,
  zLayer2,
} from 'ui/theme/themeConstants';
import { css } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import useContainerWidth from 'hooks/useContainerWidth';

export interface CarouselItemProps {
  children: ReactNode;
  width: number;
  className?: string;
}

const SeeMoreContainer = styled.div`
  border-radius: 50%;
  border: 1px solid ${bluePlanetTheme.bluePlanetPalette.grey.main};
`;

function SeeMoreCard({ href }: { href: string }) {
  return (
    <div className="u-half-spacing-left" style={{ display: 'flex', height: '100%' }}>
      <Link style={{ display: 'flex', flexGrow: 1 }} to={href}>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            flexDirection: 'column',
          }}
          hover="drop-shadow"
        >
          {/* Offset to match height of next/prev */}
          <SeeMoreContainer style={{ marginTop: '30px' }}>
            <IconButton color="grey">
              <ChevronRightIcon />
            </IconButton>
          </SeeMoreContainer>
          <div className="u-half-spacing-top">See more</div>
        </Card>
      </Link>
    </div>
  );
}

export const CarouselItem = ({ width, children, className }: CarouselItemProps) => {
  return (
    <div
      className={className}
      style={{
        width: `${width}%`,
        flexShrink: 0,
      }}
    >
      {children}
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  user-select: none;
`;

const ArrowButton = styled.button(({ position }: { position: 'right' | 'left' }) => {
  return css`
    position: absolute;
    z-index: ${zLayer2};
    top: 50%;
    transform: translate3d(0, -50%, 0);
    ${position}: 0;
    ${bluePlanetTheme.breakpoints.up('sm')} {
      ${position === 'right'
        ? css`
            right: ${quarterSpacing};
          `
        : css`
            left: ${quarterSpacing};
          `}
    }
    background-color: ${bluePlanetTheme.bluePlanetPalette.white};
    border-radius: ${bluePlanetTheme.shape.borderRadius}px;
    border: 1px solid ${bluePlanetTheme.bluePlanetPalette.grey.main};
    padding: ${quarterSpacing};
    appearance: none;

    &,
    svg {
      transition: ${bluePlanetTheme.transitions.create(['background-color', 'color'], {
        easing: transitionHover,
      })};
    }
    &:hover {
      color: ${bluePlanetTheme.bluePlanetPalette.indigo.main};
      background-color: ${bluePlanetTheme.bluePlanetPalette.grey.medium};

      svg {
        color: inherit;
      }
    }
  `;
});

const CarouselContainer = styled.div<{
  bleedAmount: string;
  fadeToColor: string;
  isNextEnabled: boolean;
  isPrevEnabled: boolean;
}>(props => {
  return css`
    --fade-gradient-width: ${contentSpacing};
    ${bluePlanetTheme.breakpoints.up('sm')} {
      --fade-gradient-width: ${sectionSpacing};
    }

    position: relative;
    overflow: hidden;
    margin-left: -${props.bleedAmount};
    margin-right: -${props.bleedAmount};

    margin-top: -${halfSpacing};
    margin-bottom: -${halfSpacing};
    padding-top: ${halfSpacing};
    padding-bottom: ${halfSpacing};

    &:after,
    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      width: var(--fade-gradient-width);
      transition: opacity 0.3s ease;
    }

    &:after {
      opacity: ${props.isNextEnabled ? 1 : 0};
      background-image: linear-gradient(to right, transparent, ${props.fadeToColor});
      right: 0;
    }

    &:before {
      opacity: ${props.isPrevEnabled ? 1 : 0};
      background-image: linear-gradient(to left, transparent, ${props.fadeToColor});
      left: 0;
      z-index: ${zLayer1};
    }
  `;
});

export interface CarouselProps {
  numVisible: number;
  seeMoreIsVisible?: boolean;
  seeMoreHref?: string;
  bleedAmount: string;
  backgroundColor?: string;
  highlightedItemIndex?: number; // Should be set by the parent component when it's displaying a highlighted carousel item
  onResize?: (itemWidth: number) => void;
  showNavigationOnHover?: boolean;
  children: React.ReactNode[];
}

export default function Carousel({
  numVisible,
  seeMoreIsVisible = false,
  seeMoreHref,
  bleedAmount: bleedAmountProp,
  backgroundColor,
  highlightedItemIndex,
  onResize,
  showNavigationOnHover,
  children,
}: CarouselProps) {
  const [index, setIndex] = React.useState(0);
  const count = React.Children.count(children) + (seeMoreIsVisible ? 1 : 0);
  const skip = 1;

  const isNextEnabled = index + numVisible < count;
  const isPrevEnabled = index - skip >= 0;

  const next = () => {
    if (isNextEnabled) {
      setIndex(index + skip);
    }
  };

  const prev = () => {
    if (index - skip >= 0) {
      setIndex(index - skip);
    }
  };

  const handlers = useSwipeable({
    trackMouse: true,
    preventScrollOnSwipe: true,
    onSwipedRight: () => {
      prev();
    },
    onSwipedLeft: () => {
      next();
    },
  });

  const carouselItemRef = useRef<HTMLDivElement>(null);

  const leftHiddenItemIndex = isPrevEnabled ? Math.max(index - numVisible, 0) : -1;
  const rightHiddenItemIndex = isNextEnabled ? index + numVisible : -1;

  const isSmUp = useMediaQuery(bluePlanetTheme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(bluePlanetTheme.breakpoints.up('md'));
  // CSS calc function requires unit of measure
  const bleedAmount =
    bleedAmountProp.endsWith('rem') || bleedAmountProp.endsWith('px') ? bleedAmountProp : bleedAmountProp + 'px';
  const edgePadding = isMdUp ? 4 : 7; // amount of space on the left and right of the container, that will make the previous or next card visible
  const islastItemVisible = index + numVisible === count;
  const itemWidth = (100 - edgePadding) / numVisible;
  const itemPixelWidth = useContainerWidth(carouselItemRef, [carouselItemRef.current, window.innerWidth, itemWidth]);
  const translateAmount =
    index === 0 ? 0 : islastItemVisible ? itemWidth * index - edgePadding : itemWidth * index - edgePadding / 2;
  const itemGap = isSmUp
    ? { gap: contentSpacing, halfGap: halfSpacing }
    : { gap: halfSpacing, halfGap: quarterSpacing };

  const [isNavigationVisible, setIsNavigationVisible] = useState(!showNavigationOnHover || !isSmUp);

  // first item and last item should not bleed out of screen, we need to offset the bleed amount applied to the container
  const bleedOffset = index === 0 ? bleedAmount : islastItemVisible ? `-${bleedAmount}` : itemGap.halfGap;

  useEffect(() => {
    if (carouselItemRef.current) {
      onResize?.(itemPixelWidth ?? 0);
    }
  }, [carouselItemRef.current, itemPixelWidth]);

  // If the highlightedItemIndex is set, we want to make sure that it is kept visible in the carousel
  useEffect(() => {
    if (highlightedItemIndex !== undefined) {
      if (highlightedItemIndex >= numVisible + index) {
        setIndex(highlightedItemIndex - numVisible + 1);
      } else if (highlightedItemIndex < index) {
        setIndex(highlightedItemIndex);
      }
    }
  }, [highlightedItemIndex]);

  return (
    <CarouselContainer
      bleedAmount={bleedAmount}
      fadeToColor={backgroundColor ?? bluePlanetTheme.bluePlanetPalette.grey.light}
      isNextEnabled={isNextEnabled}
      isPrevEnabled={isPrevEnabled}
      onPointerEnter={() => setIsNavigationVisible(true)}
      onPointerLeave={() => setIsNavigationVisible(!showNavigationOnHover || !isSmUp)}
    >
      <Container
        style={{
          transform: `translateX(calc(-${translateAmount}% + ${bleedOffset}))`,
          transition: 'transform 0.3s ease',
        }}
        {...handlers}
      >
        {children.map((child, i) => {
          return (
            <CarouselItem width={itemWidth} key={i}>
              <div
                ref={i === 0 ? carouselItemRef : undefined}
                style={{
                  transition: 'opacity 0.3s ease',
                  opacity: rightHiddenItemIndex === i || leftHiddenItemIndex === i ? 0.8 : 1, // Slightly fade hidden items on each side
                  height: '100%',
                  marginRight: i !== children.length - 1 || numVisible > count ? itemGap.gap : 0,
                }}
              >
                {child}
              </div>
            </CarouselItem>
          );
        })}
        {seeMoreHref && seeMoreIsVisible && (
          <CarouselItem width={itemWidth}>
            <SeeMoreCard href={seeMoreHref} />
          </CarouselItem>
        )}
      </Container>
      {isPrevEnabled && isSmUp && isNavigationVisible && (
        <ArrowButton position="left" disabled={!isPrevEnabled} onClick={prev}>
          <ChevronLeftIcon color="grey" />
        </ArrowButton>
      )}
      {isNextEnabled && isSmUp && isNavigationVisible && (
        <ArrowButton position="right" disabled={!isNextEnabled} onClick={next}>
          <ChevronRightIcon color="grey" />
        </ArrowButton>
      )}
    </CarouselContainer>
  );
}
