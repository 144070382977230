import React from 'react';
import { decorators } from 'ui/storybookUtils';

import add_document from './add_document.svg';
import add_user from './add_user.svg';
import business_chat from './business_chat.svg';
import businessPlan from './business_plan.svg';
import business_man from './business-man.svg';
import capTable from './cap_table.svg';
import collaborators from './collaborators.svg';
import communication from './communication.svg';
import confetti from './confetti.svg';
import documents from './documents.svg';
import emailSubscribe from './email_subscribe.svg';
import empty from './empty.svg';
import envelope from './envelope.svg';
import events from './events.svg';
import feed from './feed.svg';
import feeling_blue from './feeling_blue.svg';
import finish_line from './finish_line.svg';
import goals from './goals.svg';
import healthMultipleDna from './graphic-health-multiple-dna.svg';
import healthSingleDna from './graphic-health-single-dna.svg';
import healthSplash from './graphic-health-splash.svg';
import welcomeBackground2 from './graphic-welcome-background-2.svg';
import welcomeBackground from './graphic-welcome-background.svg';
import graphs from './graphs.svg';
import healthArenaBanner1 from './health-arena-banner-1.svg';
import healthArenaBanner2 from './health-arena-banner-2.svg';
import labels from './labels.svg';
import meetTheTeam from './meet_the_team.svg';
import meeting from './meeting.svg';
import mission_impossible from './mission_impossible.svg';
import network from './network.svg';
import person_looking_at_graph from './person_looking_at_graph.svg';
import presentation from './presentation.svg';
import reporting_help from './reporting_help.svg';
import rocket from './rocket.svg';
import sign from './sign.svg';
import update_profile_large from './update_profile_large.svg';
import update_profile from './update_profile.svg';
import video_player from './video_player.svg';
import voidIllustration from './void.svg';

const Img = ({ src }: { src: string }) => <img height={160} src={src} />;

export const AddDocument = (args: any) => <Img src={add_document} />;
export const AddUser = (args: any) => <Img src={add_user} />;
export const BusinessChat = (args: any) => <Img src={business_chat} />;
export const BusinessPlan = (args: any) => <Img src={businessPlan} />;
export const BusinessMan = (args: any) => <Img src={business_man} />;
export const CapTable = (args: any) => <Img src={capTable} />;
export const Collaborators = (args: any) => <Img src={collaborators} />;
export const Communication = (args: any) => <Img src={communication} />;
export const Confetti = (args: any) => <Img src={confetti} />;
export const Documents = (args: any) => <Img src={documents} />;
export const EmailSubscribe = (args: any) => <Img src={emailSubscribe} />;
export const Empty = (args: any) => <Img src={empty} />;
export const Envelope = (args: any) => <Img src={envelope} />;
export const Events = (args: any) => <Img src={events} />;
export const Feed = (args: any) => <Img src={feed} />;
export const FeelingBlue = (args: any) => <Img src={feeling_blue} />;
export const FinishLine = (args: any) => <Img src={finish_line} />;
export const Goals = (args: any) => <Img src={goals} />;
export const HealthMultipleDna = (args: any) => <Img src={healthMultipleDna} />;
export const HealthMultipleSingle = (args: any) => <Img src={healthSingleDna} />;
export const HealthSplash = (args: any) => <Img src={healthSplash} />;
export const WelcomeBackground2 = (args: any) => <Img src={welcomeBackground2} />;
export const WelcomeBackground = (args: any) => <Img src={welcomeBackground} />;
export const Graphs = (args: any) => <Img src={graphs} />;
export const HealthArenaBanner1 = (args: any) => <Img src={healthArenaBanner1} />;
export const HealthArenaBanner2 = (args: any) => <Img src={healthArenaBanner2} />;
export const Labels = (args: any) => <Img src={labels} />;
export const MeetTheTeam = (args: any) => <Img src={meetTheTeam} />;
export const Meeting = (args: any) => <Img src={meeting} />;
export const MissionImpossible = (args: any) => <Img src={mission_impossible} />;
export const PersonLookingAtGraph = (args: any) => <Img src={person_looking_at_graph} />;
export const Presentation = (args: any) => <Img src={presentation} />;
export const ReportingHelp = (args: any) => <Img src={reporting_help} />;
export const Rocket = (args: any) => <Img src={rocket} />;
export const Sign = (args: any) => <Img src={sign} />;
export const UpdateProfileLarge = (args: any) => <Img src={update_profile_large} />;
export const UpdateProfile = (args: any) => <Img src={update_profile} />;
export const VideoPlayer = (args: any) => <Img src={video_player} />;
export const Void = (args: any) => <Img src={voidIllustration} />;

export const Network = (args: any) => <img height={80} src={network} />;

export default {
  component: AddUser,
  title: 'illustrations/illustrations',
  decorators,
  argTypes: {
    className: { control: false },
    transform: { control: false },
    onClick: { control: false },
    viewBox: { control: false },
    style: { control: false },
  },
};
