import useSWR from 'hooks/useSWR';
import { ICompany, Role, Scope } from 'types/company';
import { fetched, fetchError, isFetching } from 'util/resource';
import { communityAPIUrls } from '../communities/communitiesApi';
import { CommunityAccessDTO, CommunityVisibility } from 'types/company/community';

function mapScopes(scopes: string[]): Scope[] {
  return scopes.map(scope => {
    const [action, resource, actingAs] = scope.split(':');
    return {
      action,
      resource,
      actingAs,
    } as Scope;
  });
}

interface Access {
  roles: Role[];
  scopes: Scope[];
  myCompanyMemberships: { visibility: CommunityVisibility; company: ICompany }[];
}

export const communityAccessKey = (communitySlug: string) => communityAPIUrls.getMe(communitySlug);

export const useCommunityAccess = (communitySlug: string) => {
  const response = useSWR<CommunityAccessDTO>(communityAccessKey(communitySlug));

  const data: Access = {
    roles: response.data?.roles ?? [],
    scopes: mapScopes(response.data?.scopes ?? []),
    myCompanyMemberships: response.data?.myCompanyMemberships ?? [],
  };

  if (response.error) {
    return {
      resource: fetchError(404),
      mutate: response.mutate,
    };
  }

  if (response.data) {
    return {
      resource: fetched(data),
      mutate: response.mutate,
    };
  }

  return {
    resource: isFetching(),
    mutate: response.mutate,
  };
};
