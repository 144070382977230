import React from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { Meta } from '@storybook/react';
import { MemoryRouter } from 'react-router-dom';
import { UserProfile } from 'types/user';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import snackbar from '../globalstate/reducers/snackbarReducer';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  snackbar,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export const decorators = [
  (Story: any) => (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <MemoryRouter>
          <ThemeProvider theme={bluePlanetTheme}>{Story()}</ThemeProvider>
        </MemoryRouter>
      </StyledEngineProvider>
    </Provider>
  ),
] as Meta['decorators'];

export const ComponentWrapper = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <div className="u-content-spacing-bottom">
    <div className="u-half-spacing-bottom text-small">{title}</div>
    {children}
  </div>
);

export const MockUser: UserProfile = {
  id: 1,
  cwUserId: '',
  name: 'Art Vandelay',
  position: 'Architect',
  employer: 'Vandelay Industries',
  linkedInUrl: 'arthur-v-47b01358',
  phone: '123-456-7890',
  email: 'art@vandelayidustries.com',
};
