import React from 'react';
import styles from './styles.scss';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import cx from 'classnames';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

interface Props {
  className?: string;
  tabContainer?: React.ComponentType<{ children: React.ReactNode }>; // Optional wrapper component
  tabs: { name: string; path: string; intercomTarget: string; active?: boolean }[];
  children: React.ReactNode;
}

const Container = styled.div`
  width: 100%;
`;

export default function PageTabs({ className, tabs, children, tabContainer }: Props) {
  const TabContainer = tabContainer || React.Fragment;
  return (
    <>
      <Container>
        <div className={styles.header}>
          <TabContainer>
            <MobileMargin className={cx(styles.tabList, className)}>
              {tabs.map(tab => (
                <Link
                  to={tab.path}
                  key={tab.name}
                  className={cx(styles.tab, { [styles.activeTab]: tab.active })}
                  data-intercom-target={tab.intercomTarget}
                >
                  {tab.name}
                </Link>
              ))}
            </MobileMargin>
          </TabContainer>
        </div>
        {children}
      </Container>
    </>
  );
}
