import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CardsIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3 11.609c0-2.829 0-4.243.879-5.122C4.757 5.61 6.172 5.61 9 5.61h2c2.828 0 4.243 0 5.121.878C17 7.366 17 8.78 17 11.61v5c0 2.828 0 4.242-.879 5.12-.878.88-2.293.88-5.121.88H9c-2.828 0-4.243 0-5.121-.88C3 20.852 3 19.438 3 16.61v-5Z" />
      <path d="M16.924 19.609c1.096-.613 1.469-1.96 2.214-4.654l1.054-3.809c.746-2.694 1.119-4.04.486-5.101-.633-1.062-2.024-1.422-4.806-2.144l-1.967-.51c-2.782-.722-4.173-1.083-5.269-.47-.78.436-1.193 1.244-1.636 2.645" />
      <path d="M7.761 11.885c.805-.457 1.507-.273 1.929.02.173.12.26.18.31.18.05 0 .137-.06.31-.18.422-.293 1.124-.477 1.929-.02 1.056.6 1.294 2.578-1.14 4.247-.465.318-.697.477-1.099.477s-.634-.16-1.098-.477c-2.435-1.67-2.197-3.648-1.14-4.247Z" />
    </SvgIcon>
  );
}
