import React from 'react';
import Chip from 'ui/elements/Chip';

interface Props {
  label: string;
  className?: string;
  onDelete?: () => void;
  fontSize?: 'small' | 'large';
}

const fundingStageColor = 'yellow-light';
export default function FundingStageChip(props: Props) {
  return <Chip color={fundingStageColor} {...props} />;
}
