import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';
import React from 'react';
import FileIcon from 'domain/shared/Files/File/FileIcon';
import styles from './styles.scss';
import Chip from 'ui/elements/Chip';
import useDownloadWarning from 'domain/documents/useDownloadWarning';
import { DocumentDTO } from 'types/documents';
import { useDownloadPitchDocument } from 'domain/documents/useDownloadDocument';
import { SUPPORTED_DOCUMENTS_PREVIEW, SUPPORTED_IMAGES_PREVIEW } from 'util/constants';
import DownloadIcon from 'ui/elements/icons/DownloadIcon';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import useDocumentPreviewOrDownload from 'hooks/useDocumentPreviewOrDownload';
import PopMenu from 'ui/modules/PopMenu';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';

interface Props {
  document: DocumentDTO;
  companyId: number;
  className?: string;
  isPitchDeck?: boolean;
}

export default function PitchDocumentTile({ document, companyId, className, isPitchDeck }: Props) {
  const isPdf = SUPPORTED_DOCUMENTS_PREVIEW.some(type => document.name.endsWith(type));
  const isImage = SUPPORTED_IMAGES_PREVIEW.some(type => document.name.endsWith(type));

  const [downloadDocument, isDownloading] = useDownloadPitchDocument(companyId);

  const { onClick: onDownloadClick, WarningDialog } = useDownloadWarning(document.name, () =>
    downloadDocument(document.id),
  );

  const { onClick: onNameClick, dialog } = useDocumentPreviewOrDownload({
    filename: document.name,
    downloadDocumentPreview: () => companiesApi.profile.pitchDocuments.download(companyId, document.id, 'preview'),
    downloadBlob: () => downloadDocument(document.id),
  });

  return (
    <>
      <div className={cx(className, 'u-flex u-flex-space-between u-flex-align-center u-content-padding-y')}>
        <div className={cx(styles.tile, `u-flex u-flex-align-center`)}>
          {isDownloading ? <CircularProgress size={24} /> : <FileIcon name={document.name} fontSize="medium" />}
          <div className={cx(styles.text, styles.document, 'u-ellipsis text-small u-align-center u-half-spacing-left')}>
            <a onClick={onNameClick}>{document.name}</a>
          </div>
          {isPitchDeck && <Chip className="u-content-spacing-x" label="pitch deck" color="blue-light" />}
        </div>
        <PopMenu
          items={[
            {
              text: <>Download</>,
              icon: <DownloadIcon fontSize="medium" />,
              onClick: onDownloadClick,
            },
            ...(isPdf || isImage
              ? [
                  {
                    text: <>Preview</>,
                    icon: <EyeIcon fontSize="medium" />,
                    onClick: onNameClick,
                  },
                ]
              : []),
          ]}
        />
      </div>
      {WarningDialog}
      {dialog}
    </>
  );
}
