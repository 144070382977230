import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import useRoute from 'hooks/useRoute';
import React from 'react';
import { ICompany } from 'types/company';
import Button from 'ui/elements/buttons/Button';
import ChatAddIcon from 'ui/elements/icons/ChatAddIcon';
import CheckMarkCircleIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import ShareIcon from 'ui/elements/icons/ShareIcon';
import UpdateIcon from 'ui/elements/icons/UpdateIcon';
import PopMenu from 'ui/modules/PopMenu';
import { companyUrls } from 'urls';
import { notEmpty } from 'util/arrayUtils';

export default function FundingRoundShareMenu({
  shareCompanyDialogHandler,
  company,
}: {
  shareCompanyDialogHandler: DialogHandlerProps;
  company: ICompany;
}) {
  const { push } = useRoute();

  const handleShareInChat = () => {
    shareCompanyDialogHandler.open();
  };

  const { copy, showCopiedSuccess } = useCopyToClipboard(
    `${location.origin}${companyUrls.overview(
      company.slug,
      'profile',
    )}?utm_source=direct&utm_medium=copy-link&utm_campaign=company&utm_term=funding-round`,
  );

  return (
    <div>
      <PopMenu
        iconColor="blue"
        icon={<ShareIcon />}
        renderAnchor={onClick => (
          <Button kind="tertiary" onClick={onClick}>
            Share company profile
          </Button>
        )}
        items={[
          {
            icon: <UpdateIcon />,
            text: <>Create Update</>,
            onClick: () => push(companyUrls.overview(company.slug, 'updates', { isPostingUpdate: true })),
          },
          {
            icon: showCopiedSuccess ? <CheckMarkCircleIcon strokeWidth={2} color="green" /> : <LinkIcon />,
            text: <>Copy link to company profile</>,
            onClick: copy,
          },
          {
            icon: <ChatAddIcon />,
            text: <>Share in chat</>,
            onClick: handleShareInChat,
          },
          !!navigator.share
            ? {
                icon: <ShareIcon />,
                text: <>More options</>,
                onClick: () =>
                  navigator.share({
                    title: company.name,
                    text: `Check out ${company.name}'s funding round on CrowdWorks!`,
                    url: `${location.origin}${companyUrls.overview(
                      company.slug,
                      'profile',
                    )}?utm_source=direct&utm_medium=native-share&utm_campaign=company&utm_term=funding-round`,
                  }),
              }
            : undefined,
        ].filter(notEmpty)}
      />
    </div>
  );
}
