import React, { useRef, useState } from 'react';
import usePaginatedResourceLegacy from 'util/resource/usePaginatedResourceLegacy';
import { getOrElse, getOrUndefined } from 'util/resource';
import { emptyPaginatedResult } from 'util/paginationUtils';
import { useLocation } from 'react-router-dom';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { useUserProfiles } from 'apis/CompanyAPI/users/useUserProfiles';
import Resources from 'util/resource/Resources';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import { useCompanyPulse } from 'apis/CompanyAPI/companies/useCompanyPulse';
import ActivityFeed from './Updates';
import PostUpdateDialog from 'pages/Company/Overview/sections/updates/PostUpdateDialog';
import PopMenu from 'ui/modules/PopMenu';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import { BODY_ID } from 'ui/views/layouts/BaseLayout/ScrollableBody';
import { useCompanyChecklistSessionStorage } from 'apis/OnboardingAPI';
import { companyUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import { CommunityUpdate } from 'types/content';
import Update from 'pages/Community/Overview/CommunityUpdates/Update';
import { CompanyOverviewSection } from 'urls';

export default function CompanyOverviewActivitySection({
  company,
  viewAs,
  onChangeSection,
}: {
  company: ICompany;
  viewAs: CompanyViewAs;
  onChangeSection: (section: CompanyOverviewSection) => string | (() => void);
}) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isPostingUpdate = params.get('writeUpdate') === '1';
  const [shouldShowArchivedUpdates, setShouldShowArchivedUpdates] = useState(false);
  const api = companyUpdateAPI(company.id);

  const [updatesResource, , , onSeeMoreUpdates, reloadUpdates] = usePaginatedResourceLegacy(
    paginationOptions => api.list(shouldShowArchivedUpdates, paginationOptions || { page: 1, limit: 5 }),
    [company.id, shouldShowArchivedUpdates],
  );
  // If update is posted to a community we show it on the company feed right away
  const [updatePostedInCommunity, setUpdatePostedInCommunity] = useState<CommunityUpdate>();

  const userProfiles = useUserProfiles(
    getOrUndefined(updatesResource)?.values?.map(it => it.update.creatorCwUserId) ?? [],
  );
  const { resource: pulseResource } = useCompanyPulse(company.slug);
  const companiesResource = useCompanies();
  const communitiesResource = useCommunities();
  const companies = getOrElse(companiesResource.resource, emptyPaginatedResult()).values;
  const communities = getOrUndefined(communitiesResource.resource)?.values ?? [];

  const isCompanyAdmin = viewAs === 'Admin';
  const canPostUpdate = isCompanyAdmin;

  const updatesRef = useRef<HTMLDivElement>(null);

  const scrollToUpdatesSection = () => {
    if (updatesRef.current) {
      const updatesPosition = updatesRef.current.offsetTop;
      const body = document.getElementById(BODY_ID);
      body?.scrollTo({ top: updatesPosition - 30, behavior: 'smooth' });
    }
  };
  const actitivies = getOrElse(pulseResource, emptyPaginatedResult()).values;

  const { markStepAsCompleted } = useCompanyChecklistSessionStorage(company.slug);

  const community = communities.find(c => c.id === updatePostedInCommunity?.communityId)?.details;

  return (
    <>
      <Resources resources={[updatesResource, userProfiles.resource]}>
        {([updates, userProfiles]) => {
          return (
            <div style={{ maxWidth: 820 }}>
              {canPostUpdate && (
                <div data-intercom-target="company-post-updates">
                  <PostUpdateDialog
                    initiallyOpen={isPostingUpdate}
                    companies={[...companies, ...communities.map(c => c.details)]}
                    company={company}
                    onUpdatePosted={update => {
                      setUpdatePostedInCommunity(update);
                      scrollToUpdatesSection();
                      markStepAsCompleted('PostFirstUpdate');
                      reloadUpdates();
                    }}
                  />
                </div>
              )}
              {isCompanyAdmin && (
                <div className="u-flex-end u-half-spacing-y">
                  <PopMenu
                    items={[
                      {
                        text: <>Latest</>,
                        onClick: () => setShouldShowArchivedUpdates(false),
                        selected: !shouldShowArchivedUpdates,
                      },
                      {
                        text: <>Archive</>,
                        onClick: () => setShouldShowArchivedUpdates(true),
                        selected: shouldShowArchivedUpdates,
                      },
                    ]}
                    anchorOrigin={{
                      horizontal: 'left',
                      vertical: 'bottom',
                    }}
                    renderAnchor={onClick => (
                      <button onClick={onClick} className="text-small" style={{ fontWeight: 500 }}>
                        {shouldShowArchivedUpdates === true ? 'Archived' : 'Latest'}
                        <ChevronDownIcon className="u-quarter-spacing-left" fontSize="inherit" />
                      </button>
                    )}
                  />
                </div>
              )}
              <div ref={updatesRef}>
                {updatePostedInCommunity && community && (
                  <Update
                    update={updatePostedInCommunity}
                    creator={userProfiles.values.find(
                      u => u.cwUserId === updatePostedInCommunity.update.creatorCwUserId,
                    )}
                    community={community}
                    postedBy={{ type: 'company', company }}
                    shownIn="company"
                    onArchive={reloadUpdates}
                    onDelete={reloadUpdates}
                    onEdit={reloadUpdates}
                    canEditPost={false}
                  />
                )}
                <ActivityFeed
                  shouldShowArchivedUpdates={shouldShowArchivedUpdates}
                  company={company}
                  updates={updates}
                  userProfiles={userProfiles}
                  activities={actitivies}
                  viewAs={viewAs}
                  onSeeMore={onSeeMoreUpdates}
                  reloadUpdates={reloadUpdates}
                  onChangeSection={onChangeSection}
                />
              </div>
            </div>
          );
        }}
      </Resources>
    </>
  );
}
