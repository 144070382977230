import { Service } from 'types/organization';
import React, { ReactNode } from 'react';
import useResource from 'util/resource/useResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import EditablePicker from 'pages/Organization/shared/EditablePicker';

interface EditableServicesProps {
  canEdit: boolean;
  onConfirm: (value: Service[]) => Promise<any> | void;
  initialServices: Service[];
  valueComponent: ReactNode;
  style?: React.CSSProperties;
}

export default function EditableServices(props: EditableServicesProps) {
  const { resource } = useResource<Service[]>(OrganizationAPI.listAllServicesUrl);

  return (
    <EditablePicker
      {...props}
      name={'parentServices'}
      placeholder={'Select services'}
      initialItems={() => props.initialServices}
      label={'Add services'}
      optionLabel={t => t.name}
      optionsResource={resource}
    />
  );
}
