import useResourceLegacy from 'util/resource/useResourceLegacy';
import React, { useState } from 'react';
import ImageCard from 'ui/views/cards/ImageCard';
import welcome_background from 'ui/illustrations/graphic-welcome-background-2.svg';

import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { getOrUndefined } from 'util/resource';
import useCompanyRole from 'hooks/useCompanyRole';
import { CompanyMessageList, ICompany } from 'types/company';
import useLocalStorage from 'hooks/useLocalStorage';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { startProductTour } from 'util/intercom/intercom';
import productTours from 'util/intercom/productTours';
import PleaseEnableCookiesDialog from 'CookieConsent/PleaseEnableCookiesDialog';

type Props = {
  company: ICompany;
};

export default function WelcomeMessage({ company }: Props) {
  const companyId = company.id;
  const { canEditCompany } = useCompanyRole(company.slug);
  const [hasReadWelcomeMessage, setHasReadWelcomeMessage] = useLocalStorage(
    `has-read-company-overview-welcome-message-${companyId}`,
    false,
  );

  const [messages] = useResourceLegacy(
    () => (canEditCompany ? companiesApi.messages.overview(companyId) : new Promise<CompanyMessageList>(() => {})),
    [companyId],
  );

  const welcomeMessage = getOrUndefined(messages)?.values?.filter(message => message.type === 'WelcomeOverview')[0];
  const [showEnableCookieDialog, setShowEnableCookieDialog] = useState(false);

  if (!welcomeMessage || hasReadWelcomeMessage) {
    return null;
  }

  const intercomProductTourId = productTours.companyOverviewProductTourForAdmins();

  const onClick = () => {
    const _Intercom: Intercom_.IntercomStatic = (window as any).Intercom;
    if (!_Intercom) {
      setShowEnableCookieDialog(true);
    } else {
      intercomProductTourId && startProductTour(intercomProductTourId);
    }
  };
  return (
    <ImageCard
      backgroundImage={welcome_background}
      className="u-half-spacing-bottom text-font-text"
      padding="double"
      style={{ backgroundPosition: 'right -140px bottom -10px' }}
      bleedOnMobile
    >
      <div style={{ maxWidth: 685 }}>
        <h2 className="u-half-spacing-bottom text-weight-medium text-x-large">Welcome to your company profile!</h2>
        <p className="u-half-spacing-bottom text-medium">
          Your profile is your window to the world, and showcases your company to existing shareholders and potential
          future investors. With regular check-ins and updates, you will keep your followers in the loop on what goes on
          in your company while simultaneously building trust.
        </p>
        <p className="u-content-spacing-bottom text-medium">
          Keep track of how your content engages your audience in the insights section. You can improve your stats and
          attract the right people by keeping your profile up-to-date and posting traction signals.
        </p>
        <ButtonList>
          {intercomProductTourId && (
            <Button kind="primary" color="blue" onClick={onClick}>
              Show me around
            </Button>
          )}
          <Button kind="tertiary" color="grey" onClick={() => setHasReadWelcomeMessage(true)}>
            Dismiss
          </Button>
        </ButtonList>
      </div>
      <PleaseEnableCookiesDialog open={showEnableCookieDialog} onClose={() => setShowEnableCookieDialog(false)} />
    </ImageCard>
  );
}
