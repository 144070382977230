import React, { useState } from 'react';
import CardStack from 'ui/views/cards/CardStack';
import ClickableCard from 'ui/views/cards/ClickableCard';
import fuzzysearch from 'fuzzysearch';
import Button from 'ui/elements/buttons/Button';
import Logo from 'ui/domain/Logo';
import { communityUrls, userUrls } from 'urls';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import Indicator from 'ui/elements/Indicator/NumberIndicator';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import TextFilter from 'domain/shared/Filters/TextFilter';
import { MyCommunityMembershipStatus } from './common/MyCommunityMembershipStatus';
import { MyCommunity } from 'types/company/community';
import { useMediaQuery, useTheme } from '@mui/material';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

interface Props {
  communities: MyCommunity[];
}

const Title = React.memo(function Title({ c }: { c: MyCommunity }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{ width: '100%', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? undefined : 'center' }}
      className="u-flex u-flex-space-between u-flex--wrap u-flex--gap-half"
    >
      <div>
        {c.details.name}
        <div className="text-metadata">{c.membersCount} members</div>
      </div>
      <MyCommunityMembershipStatus style={{ minWidth: isMobile ? undefined : '14rem' }} community={c} />
    </div>
  );
});

export default function CommunityList({ communities }: Props) {
  const [search, setSearch] = useState('');

  const filteredCommunities = !search
    ? communities
    : communities.filter(c => fuzzysearch(search.toLowerCase(), c.details.name.toLowerCase()));

  const limit = 12;
  const [showAll, setShowAll] = useState(false);

  return (
    <div>
      <Link to={userUrls.dashboard.network.overview()} className="text-link">
        <ChevronLeftIcon fontSize="small" /> Back
      </Link>
      <MobileMargin>
        <SectionHeadingLarge
          className="u-content-spacing-top"
          heading={
            <>
              Your communities{' '}
              <Indicator className="u-quarter-spacing-left" color="grey" shape="square" value={communities.length} />
            </>
          }
        >
          <div>
            <TextFilter placeholder="Community name" setValue={setSearch} value={search} />
          </div>
        </SectionHeadingLarge>
      </MobileMargin>
      <CardStack className="u-flex u-flex--column u-flex-grow">
        {filteredCommunities.slice(0, showAll ? undefined : limit).map(c => (
          <ClickableCard
            href={communityUrls.overview(c.slug)}
            key={`company=${c.id}`}
            avatar={<Logo size={40} url={c.details.logoURL} type={'community'} />}
            title={<Title c={c} />}
          />
        ))}
      </CardStack>
      {!showAll && filteredCommunities.length > limit && (
        <div className="u-half-spacing-top">
          <Button kind="tertiary" onClick={() => setShowAll(true)}>
            Show {filteredCommunities.length - limit} more
          </Button>
        </div>
      )}
    </div>
  );
}
