import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FullFundingRound } from 'types/company';
import Button from 'ui/elements/buttons/Button';
import FormRow from 'ui/elements/form/FormRow';
import Label from 'ui/elements/form/Label';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import CurrencyInput from 'ui/elements/form/numbers/CurrencyInput';
import { DEFAULT_CURRENCY } from 'util/currency';
import { Stage } from 'types';
import Select from 'ui/elements/form/Select/Select';
import { getOrUndefined } from 'util/resource';
import { fundingRoundSchema, fundingRoundTypes } from 'pages/CompanySettings/FundingRound/CompanyFundingRound';
import { useNewFundingRound, useUpdateFundingRound } from 'pages/CompanySettings/FundingRound/actions';
import LoadableResource from 'util/resource/Resource';
import usePaginatedResourceLegacy from 'util/resource/usePaginatedResourceLegacy';
import DatePicker from 'ui/elements/form/DatePicker';
import TextField from 'ui/elements/form/TextField';
import CalendarIcon from 'ui/elements/icons/CalendarIcon';
import { DATE_FORMAT } from 'util/constants';
import { SUCCESS_WIDTH } from 'pages/CompanySettings/utils';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { useCompanyPulse } from 'apis/CompanyAPI/companies/useCompanyPulse';
import addDays from 'date-fns/addDays';
import { useFundingStages } from 'apis/CompanyAPI/labels/useFundingStages';
import InvestorMatching from './InvestorMatching';
import PitchReview from './PitchReview';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';

interface Props {
  companyId: number;
  companySlug: string;
  fundingRound?: FullFundingRound;
  fundingStage?: Stage;
  onClose: () => void;
  onSave: () => void;
}

export default function EditFundingRound({
  companyId,
  companySlug,
  onClose,
  onSave,
  fundingRound,
  fundingStage,
}: Props) {
  const [success, setSuccess] = useState(false);
  const { resource: stageLabels } = useFundingStages();
  const { mutate: reloadCompanyActivities } = useCompanyPulse(companySlug);
  const stageOptions = getOrUndefined(stageLabels)?.values?.map(sx => ({ ...sx, value: sx.id })) ?? [];
  const [onNewFundingRound, isSavingNewFundingRound] = useNewFundingRound(companyId, companySlug, {
    onSuccess: () => {
      onSave();
      setSuccess(true);
      // Need to refetch company activities, as we need the latest activities on funding rounds (closed or started)
      reloadCompanyActivities();
      onClose();
    },
    onFailure: () => {
      () => setSuccess(false);
    },
  });

  const [onUpdateFundingRound, isSavingUpdateFundingRound] = useUpdateFundingRound(companyId, companySlug, {
    onSuccess: () => {
      onClose();
      onSave();
      // Need to refetch company activities, as we need the latest activities on funding rounds (closed or started)
      reloadCompanyActivities();
      setSuccess(true);
    },
    onFailure: () => {
      () => setSuccess(false);
    },
  });

  const [companyInvestors] = usePaginatedResourceLegacy(
    paginationOptions =>
      companiesApi.users.search(companySlug, {
        role: 'investors',
        ...(paginationOptions || {
          limit: 20,
        }),
      }),
    [companySlug],
  );

  const saving = isSavingNewFundingRound || isSavingUpdateFundingRound;

  const formik = useFormik({
    initialValues: {
      capitalSeeking: fundingRound?.capitalSeeking?.value,
      capitalSeekingCurrency: fundingRound?.capitalSeeking?.currency ?? DEFAULT_CURRENCY,
      preMoneyValuation: fundingRound?.preMoneyValuation?.value,
      preMoneyValuationCurrency: fundingRound?.preMoneyValuation?.currency ?? DEFAULT_CURRENCY,
      previousValuation: fundingRound?.previousValuation?.value,
      previousValuationCurrency: fundingRound?.previousValuation?.currency ?? DEFAULT_CURRENCY,
      fundingStage: fundingRound?.fundingStage ?? fundingStage ?? stageOptions[0],
      leadInvestor: fundingRound?.leadInvestor,
      type: fundingRound?.type,
      openingDate: fundingRound?.openingDate,
      plannedClosingDate: fundingRound?.plannedClosingDate,
    },
    onSubmit: values => {
      fundingRound
        ? onUpdateFundingRound({
            capitalSeeking: values.capitalSeeking,
            capitalSeekingCurrency: values.capitalSeekingCurrency,
            preMoneyValuation: values.preMoneyValuation,
            preMoneyValuationCurrency: values.preMoneyValuationCurrency,
            previousValuation: values.previousValuation,
            previousValuationCurrency: values.previousValuationCurrency,
            leadInvestor: values.leadInvestor?.cwUserId ?? null,
            type: values.type ?? 'Other',
            plannedClosingDate: values.plannedClosingDate,
            fundingStage: values.fundingStage.id,
          })
        : onNewFundingRound({
            capitalSeeking: values.capitalSeeking ?? 0,
            capitalSeekingCurrency: values.capitalSeekingCurrency,
            preMoneyValuation: values.preMoneyValuation ?? 0,
            preMoneyValuationCurrency: values.preMoneyValuationCurrency,
            previousValuation: values.previousValuation ?? 0,
            previousValuationCurrency: values.previousValuationCurrency,
            leadInvestor: values.leadInvestor?.cwUserId,
            type: values.type ?? 'Other',
            plannedClosingDate: values.plannedClosingDate,
            fundingStage: values.fundingStage.id,
          });
    },
    validationSchema: fundingRoundSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.dirty) {
      setSuccess(false);
    }
  }, [formik.dirty]);

  function handleSelectDate(date: Date): void {
    const newDate = new Date(date);
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    formik.setFieldValue('plannedClosingDate', newDate.toISOString());
  }

  return (
    <Dialog onClose={onClose} open={true} maxWidth="sm">
      <Title onClose={onClose}>Funding round</Title>
      <Content overflowY="visible">
        {!fundingRound && (
          <p className="u-section-spacing-bottom">
            By activating the funding round module and filling in the basic details, you advertise on your pitch to
            potential investors that your company is currently seeking funding. The details are only available to your
            followers, but anyone viewing your pitch will see that you have an active funding round.
          </p>
        )}
        <FormRow>
          <CurrencyInput
            name="capitalSeeking"
            value={formik.values.capitalSeeking}
            error={formik.errors.capitalSeeking}
            touched={formik.touched.capitalSeeking}
            onChange={v => formik.setFieldValue('capitalSeeking', v)}
            label="Capital seeking"
            placeholder="5 000 000"
            currency={formik.values.capitalSeekingCurrency}
            onCurrencyChange={v => formik.setFieldValue('capitalSeekingCurrency', v)}
            helperText=" " // Include to get consistent form spacing
          />
        </FormRow>
        <FormRow>
          <CurrencyInput
            name="preMoneyValuation"
            value={formik.values.preMoneyValuation}
            error={formik.errors.preMoneyValuation}
            touched={formik.touched.preMoneyValuation}
            onChange={v => formik.setFieldValue('preMoneyValuation', v)}
            label="Pre-money valuation"
            placeholder="10 000 000"
            currency={formik.values.preMoneyValuationCurrency}
            onCurrencyChange={v => formik.setFieldValue('preMoneyValuationCurrency', v)}
            nullable={true}
            helperText={' '} // Include to get consistent form spacing
          />
        </FormRow>
        <FormRow>
          <CurrencyInput
            name="previousValuation"
            value={formik.values.previousValuation}
            error={formik.errors.previousValuation}
            touched={formik.touched.previousValuation}
            onChange={v => formik.setFieldValue('previousValuation', v)}
            label="Previous valuation"
            placeholder="10 000 000"
            currency={formik.values.previousValuationCurrency}
            onCurrencyChange={v => formik.setFieldValue('previousValuationCurrency', v)}
            nullable={true}
            helperText=" " // Include to get consistent form spacing
          />
        </FormRow>
        <FormRow>
          <Label>Funding stage</Label>
          <Select
            value={formik.values.fundingStage}
            placeholder="Select current funding stage"
            onChange={label => (label ? formik.setFieldValue('fundingStage', label) : undefined)}
            getOptionLabel={i => i.name}
            options={stageOptions}
            error={formik.touched.fundingStage && !!formik.errors.fundingStage}
            helperText={formik.touched.fundingStage ? formik.errors.fundingStage?.name : ' '}
          />
        </FormRow>
        <FormRow>
          <Label>Type</Label>
          <Select
            name="type"
            placeholder="Select type"
            value={formik.values.type}
            onChange={type => formik.setFieldValue('type', type)}
            getOptionLabel={type => type.charAt(0).toUpperCase() + type.slice(1)}
            options={fundingRoundTypes}
            error={formik.touched.type && !!formik.errors.type}
            helperText={formik.touched.type ? formik.errors.type : ' '}
            isClearable={false}
          />
        </FormRow>
        <FormRow>
          <LoadableResource resource={companyInvestors} renderError="Nothing">
            {investors => (
              <div className="u-fullWidth">
                <Label>Lead investor (Optional)</Label>
                <Select
                  name="leadInvestor"
                  placeholder="Search"
                  value={formik.values.leadInvestor}
                  onChange={user => formik.setFieldValue('leadInvestor', user)}
                  onInputChange={input => (!input ? formik.setFieldValue('leadInvestor', '') : null)}
                  getOptionLabel={investor => investor.name}
                  options={investors.values}
                  error={formik.touched.leadInvestor && !!formik.errors.leadInvestor}
                  helperText={formik.touched.leadInvestor ? formik.errors.leadInvestor : ' '}
                />
              </div>
            )}
          </LoadableResource>
        </FormRow>
        <FormRow>
          <Label>Closing date</Label>
          <DatePicker
            name="plannedClosingDate"
            dateFormat={DATE_FORMAT}
            selected={formik.values.plannedClosingDate ? new Date(formik.values.plannedClosingDate) : null}
            onChange={() => {
              return formik.handleChange('plannedClosingDate');
            }}
            onSelect={handleSelectDate}
            onBlur={formik.handleBlur}
            customInput={
              <TextField
                error={formik.touched.plannedClosingDate && !!formik.errors.plannedClosingDate}
                helperText={
                  formik.touched.plannedClosingDate && formik.errors.plannedClosingDate
                    ? formik.errors.plannedClosingDate
                    : 'Closing date can be maximum 90 days in the future'
                }
                suffix={<CalendarIcon />}
              />
            }
            minDate={new Date()}
            maxDate={addDays(new Date(), 90)}
          />
        </FormRow>
        <div className="u-section-spacing-top">
          <SectionHeadingLarge heading="Boost your upcoming funding round" />
          <div className="u-flex u-flex--column u-flex--gap-2">
            <InvestorMatching companyId={companyId} />
            <PitchReview companyId={companyId} />
          </div>
        </div>
      </Content>
      <DialogActions>
        <Button
          onClick={() => formik.handleSubmit()}
          className="u-ellipsis"
          kind="primary"
          isLoading={saving}
          isSuccess={success}
          setSuccess={(isSuccess: boolean) => setSuccess(isSuccess)}
          style={{ width: `${SUCCESS_WIDTH + (!fundingRound ? 20 : 0)}px` }}
          disabled={saving || success || !formik.dirty}
        >
          {fundingRound ? 'Update' : 'Save & Publish'}
        </Button>
        {
          <Button onClick={onClose} kind="tertiary">
            Cancel
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}
