import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function HandshakeIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M22 7.554h-2.789c-.601 0-.902 0-1.185-.086-.283-.085-.534-.252-1.034-.586-.75-.5-1.606-1.07-2.031-1.2-.425-.128-.876-.128-1.778-.128-1.226 0-2.016 0-2.568.229-.55.228-.984.661-1.852 1.529L8 8.075c-.195.195-.293.293-.353.39a1 1 0 0 0 .062 1.148c.07.09.178.176.393.349.796.64 1.943.577 2.664-.148L12 8.572h1l6 6.036a1.429 1.429 0 0 1 0 2.012 1.408 1.408 0 0 1-2 0l-.5-.503m0 0-3-3.018m3 3.018a1.429 1.429 0 0 1 0 2.012 1.408 1.408 0 0 1-2 0l-1-1.006m0 0a1.429 1.429 0 0 1 0 2.012 1.408 1.408 0 0 1-2 0l-1.5-1.51m3.5-.502-2-2M10 17.626l-.5-.503m.5.503a1.429 1.429 0 0 1 0 2.012 1.408 1.408 0 0 1-2 0l-2.824-2.883c-.58-.592-.87-.888-1.242-1.045-.371-.156-.786-.156-1.615-.156H2" />
      <path d="M22 15.554h-2.5M8.5 7.554H2" />
    </SvgIcon>
  );
}
