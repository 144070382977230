import React, { ReactNode } from 'react';
import { IResource } from 'util/resource';
import Resource from 'util/resource/Resource';
import InlineEditableField from 'ui/elements/form/InlineEditableField';
import Picker from 'domain/labels/Picker';
import { halfSpacing } from 'ui/theme/themeConstants';

type EditablePickerProps<T> = {
  name: string;
  placeholder: string;
  canEdit: boolean;
  onConfirm: (value: T[]) => Promise<any> | void;
  initialItems: (allOptions: T[]) => T[];
  optionLabel: (t: T) => string;
  valueComponent: ReactNode;
  optionsResource: IResource<T[]>;
  style?: React.CSSProperties;
  optionalOnAdd?: (input: string, optionsLength: number, selectedItem?: T) => void;
  label: string;
};

export default function EditablePicker<T>({
  name,
  placeholder,
  canEdit,
  onConfirm,
  initialItems,
  valueComponent,
  optionLabel,
  optionsResource,
  label,
  optionalOnAdd,
  style,
}: EditablePickerProps<T>) {
  if (!canEdit) return valueComponent;

  return (
    <Resource resource={optionsResource}>
      {allOptions => (
        <InlineEditableField
          label={label}
          inputWrapperStyle={{ marginLeft: `${halfSpacing}` }}
          defaultValue={initialItems(allOptions)}
          onConfirm={onConfirm}
          renderInputComponent={(onChange, value) => (
            <Picker
              name={name}
              placeholder={placeholder}
              style={style}
              selectedItems={value}
              setItems={onChange}
              optionalOnAdd={optionalOnAdd}
              optionsValues={allOptions}
              optionLabel={optionLabel}
            />
          )}
          valueComponent={valueComponent}
        />
      )}
    </Resource>
  );
}
