import { Organization } from 'types/organization';
import React from 'react';
import { Location } from 'history';
import { organizationUrls } from 'urls';
import { Route, useLocation } from 'react-router';
import PageTabs from 'ui/modules/PageTabs';
import { Switch } from 'react-router-dom';
import MaxWidth, { maxWidths } from 'ui/views/containers/MaxWidth';
import useRoute from 'hooks/useRoute';
import { OrganizationBasicInformation } from 'pages/Organization/Settings/BasicInformation';
import { bluePlanetTheme } from 'ui/theme';
import ScrollableBody from 'ui/views/layouts/BaseLayout/ScrollableBody';
import { VisibilitySettings } from 'pages/Organization/Settings/VisibilitySettings';
import { ConnectionSettings } from 'pages/Organization/Settings/ConnectionSettings';
import styled from '@emotion/styled';
import { contentSpacing } from 'ui/theme/themeConstants';

const getTabs = (location: Location, slug: string) => {
  return [
    {
      name: 'Basic information',
      path: organizationUrls.settings.settings(slug),
      active: location.pathname === organizationUrls.settings.settings(slug),
      intercomTarget: 'organization-settings-basic-information',
    },
    {
      name: 'Visibility',
      path: organizationUrls.settings.visibility(slug),
      active: location.pathname === organizationUrls.settings.visibility(slug),
      intercomTarget: 'organization-settings-visibility',
    },
    {
      name: 'Connections',
      path: organizationUrls.settings.connections(slug),
      active: location.pathname === organizationUrls.settings.connections(slug),
      intercomTarget: 'organization-settings-connections',
    },
  ];
};

const TabContainer = styled.div`
  margin: 0 ${contentSpacing};
  width: 100%;
  max-width: ${maxWidths.xl};
  ${bluePlanetTheme.breakpoints.down('md')} {
    margin: 0;
  }
`;

interface PageProps {
  organization: Organization;
}

export function OrganizationSettingsPage(props: PageProps) {
  const location = useLocation();
  const route = useRoute();
  const tabs = getTabs(location, props.organization.slug);

  return (
    <>
      <ScrollableBody accountForMobileHeader>
        <div
          className="u-section-padding-y u-content-padding-x"
          style={{ backgroundColor: bluePlanetTheme.bluePlanetPalette.white }}
        >
          <MaxWidth style={{ margin: '0 auto' }} width="xl" className="u-flex u-flex-space-between">
            <div
              className="u-flex u-flex-align-center u-flex-space-between"
              style={{ position: 'relative', backgroundColor: bluePlanetTheme.bluePlanetPalette.white }}
            >
              <h3 className="text-h3 u-section-padding-y">{props.organization.name} settings</h3>
            </div>
          </MaxWidth>
        </div>
        <PageTabs tabs={tabs} tabContainer={TabContainer}>
          <div className="u-content-padding-x u-section-padding-y">
            <MaxWidth width="xl" style={{ margin: '0 auto' }}>
              <MaxWidth width="large">
                <Switch>
                  <Route
                    exact
                    path={`${route.match.path}/visibility`}
                    render={() => <VisibilitySettings organization={props.organization} />}
                  />
                  <Route
                    exact
                    path={`${route.match.path}/connections`}
                    render={() => <ConnectionSettings organization={props.organization} />}
                  />
                  <Route
                    path={`${route.match.path}`}
                    render={() => <OrganizationBasicInformation organization={props.organization} />}
                  />
                </Switch>
              </MaxWidth>
            </MaxWidth>
          </div>
        </PageTabs>
      </ScrollableBody>
    </>
  );
}
