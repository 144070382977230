import { Hidden, useMediaQuery } from '@mui/material';
import { Author, AuthorImage } from 'domain/shared/author';
import useDialogHandler from 'hooks/useDialogHandler';
import React, { lazy, useRef, useState } from 'react';
import { AllCategories } from 'types/content';
import { CompanyUpdateVisibility, PostUpdateTo } from 'types/content/update';
import Button from 'ui/elements/buttons/Button';
import { DocumentList } from 'types/documents';
import Loading from 'ui/elements/Loading';

import Settings from './Settings';
import Visibility, { VisibilitySelector } from './Visibility';
import { ContentAPI } from 'apis/ContentAPI';
import Dropzone from 'domain/shared/Files/Upload/Dropzone';
import AttachmentList from 'domain/Updates/shared/UpdateForm/AttachmentList';

import config from 'config';
import Dialog, { Title, Content } from 'ui/views/dialogs/Dialog';
import { bluePlanetTheme } from 'ui/theme';
import BulbIcon from 'ui/elements/icons/BulbIcon';
import UpdateIdeas, { getHelpOptions } from 'domain/Updates/CompanyUpdate/UpdateIdeas';
import {
  communityMentionSearchFunction,
  companyUserMentionSearchFunction,
  removeMentions,
} from 'ui/elements/form/RichTextEditor/utils';
import Label from 'ui/elements/form/Label';
import { useUpdateForm } from 'domain/Updates/shared/UpdateForm/useUpdateForm';
import { companyUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import { useCommunityMemberships } from 'apis/CompanyAPI/companies/useCommunityMemberships';
import { ICompany } from 'types/company';
import { getOrElse } from 'util/resource';
import { CompanyUpdateFormValues } from 'domain/Updates/CompanyUpdate/UpdateForm/types';

const RichTextEditor = lazy(() => import('ui/elements/form/RichTextEditor'));

interface Props {
  titleDraft?: string;
  contentDraft?: string;
  category?: AllCategories;
  bannerImage?: string;
  company: ICompany;
  onSave: (dto: CompanyUpdateFormValues, postTo: PostUpdateTo) => void;
  isSaving: boolean;
  author: Author;
  onClose: () => void;
  selectedVisibility: CompanyUpdateVisibility;
  attachments?: DocumentList;
  linkPreview?: string;
  saveButtonText?: string;
  contentId?: string;
}

export default function UpdateForm(props: Props) {
  const [showHelpSection, setShowHelpSection] = useState(false);

  const contentAPI = ContentAPI(`${config.CONTENT_API_URL}/companies/${props.company.id}`);
  const getImageUploadUrl = (filename: string) => contentAPI.images.getCompanyUploadUrl(props.company.id, filename);
  const api = companyUpdateAPI(props.company.id);

  const settingsDialogHandler = useDialogHandler(false);
  const [isVisiblitiySelectorOpen, setIsVisibilitySelectorOpen] = useState(false);
  const [postUpdateTo, setPostUpdateTo] = useState<PostUpdateTo>({
    type: 'company',
    visibility: props.selectedVisibility,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const { formik, onCancel, onSubmit, onUploadFile, onAttachmentRemoved, newAttachments, removeFile } = useUpdateForm({
    ...props,
    onSave: dto => props.onSave(dto, postUpdateTo),
  });

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const { resource: communityMemberships } = useCommunityMemberships(props.company.slug);

  const mentionSearchFunction = async (searchQuery: string) =>
    postUpdateTo.type === 'company'
      ? companyUserMentionSearchFunction(searchQuery, postUpdateTo.visibility, props.company.id)
      : communityMentionSearchFunction(searchQuery, 'CommunityMembers', postUpdateTo.community.id);

  const onClose = () => onCancel(props.onClose);
  const maxWidth = settingsDialogHandler.isOpen || isVisiblitiySelectorOpen ? undefined : 'md';

  // The owners information category is still a hack. If should only be allowed to be used for private updates
  // if we change visibility we have to reset the category.

  const onSetPostUpdateTo = (postUpdateTo: PostUpdateTo) => {
    if (postUpdateTo.type !== 'company' || postUpdateTo.visibility !== 'Private') {
      formik.setFieldValue('category', 'news');
    }
    setPostUpdateTo(postUpdateTo);

    // when we change visibility, we don't know if the mentioned companies/users can still see the update, so we remove them
    formik.setFieldValue('structuredContent', removeMentions(formik.values.structuredContent));
  };

  return (
    <Dialog maxWidth={maxWidth} fullScreen={isMobile} verticalAlign="top" open onClose={onClose}>
      {settingsDialogHandler.isOpen ? (
        <>
          <Title onClose={settingsDialogHandler.close}>Advanced Settings</Title>
          <Settings
            onClose={settingsDialogHandler.close}
            values={formik.values}
            setFieldValue={formik.setFieldValue}
            setPostUpdateTo={onSetPostUpdateTo}
          />
        </>
      ) : isVisiblitiySelectorOpen ? (
        <VisibilitySelector
          communityMemberships={getOrElse(communityMemberships, { values: [] }).values}
          canSelectCommunities={props.contentId === undefined} // only allow selecting communities for new updates
          setIsVisibilitySelectorOpen={setIsVisibilitySelectorOpen}
          setPostUpdateTo={onSetPostUpdateTo}
          postUpdateTo={postUpdateTo}
          settingsDialogHandler={settingsDialogHandler}
        />
      ) : (
        <div ref={containerRef} className="u-flex u-flex--column u-flex-space-between">
          <Title onClose={onClose}>
            <div className="u-flex u-flex-space-between u-flex-align-center" style={{ width: '100%' }}>
              <div className="u-flex u-flex-align-center">
                <AuthorImage className="u-half-spacing-right" author={props.author} />
                <Visibility
                  openVisibilityPicker={() => setIsVisibilitySelectorOpen(true)}
                  postUpdateTo={postUpdateTo}
                />
              </div>
              <Hidden smDown>
                <Button
                  onClick={() => onSubmit()}
                  kind="primary"
                  type="submit"
                  data-intercom-target="publish"
                  isLoading={props.isSaving}
                  className="u-half-spacing-right"
                >
                  {props.saveButtonText || 'Publish'}
                </Button>
              </Hidden>
            </div>
          </Title>
          <Content className="u-section-spacing-bottom">
            {!isMobile && (
              <div className="u-section-spacing-bottom">
                <Button
                  kind="tertiary"
                  onClick={() => setShowHelpSection(prev => !prev)}
                  className="u-flex u-flex-align-center"
                >
                  <BulbIcon color="blue" className="u-half-spacing-right" />
                  <Label style={{ margin: 0 }}>Post ideas</Label>
                </Button>
                {showHelpSection && <UpdateIdeas helpOptions={getHelpOptions().filter(opt => opt.value !== 'greet')} />}
              </div>
            )}

            <React.Suspense fallback={<Loading spacing="content-spacing" position="center" />}>
              <Dropzone disableOnClick onDrop={onUploadFile} tabIndex={-1}>
                <RichTextEditor
                  toolbarType="none"
                  name="content"
                  deltaName="structuredContent"
                  content={formik.values.content}
                  delta={formik.values.structuredContent || {}}
                  linkPreview={props.linkPreview}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                  validateField={formik.validateField}
                  handleChange={formik.handleChange}
                  error={formik.touched.content ? formik.errors.content : undefined}
                  getImageUploadUrl={getImageUploadUrl}
                  mentionSearchFunction={mentionSearchFunction}
                  onUploadFile={onUploadFile}
                  submitButton={
                    <Button
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => onSubmit()}
                      kind="primary"
                      type="submit"
                      data-intercom-target="publish"
                      isLoading={props.isSaving}
                    >
                      {props.saveButtonText || 'Publish'}
                    </Button>
                  }
                />
              </Dropzone>
            </React.Suspense>
            <AttachmentList
              attachmentIdList={formik.values.attachments}
              existingAttachments={props.attachments?.documents ?? []}
              newAttachments={newAttachments}
              downloadAttachment={documentId => api.attachments.download(props.contentId || '', documentId)}
              removeExistingAttachment={onAttachmentRemoved}
              removeNewAttachment={removeFile}
            />
          </Content>
        </div>
      )}
    </Dialog>
  );
}
