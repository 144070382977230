import { DashboardData } from 'apis/DashboardAPI';
import dashboardApi from 'apis/DashboardAPI';
import useSWR from 'hooks/useSWR';
import { CompanyListItem } from 'types/company';
import { TinyCommunity } from 'types/company/community';
import { useSWRResource } from 'util/resource/useSWRResource';

export const companyDashboardKey = (companyId: number) => dashboardApi.getCompanyDashboardData(companyId);

export const companyOrCommunityDashboardKey = (companyOrCommunity?: CompanyListItem | TinyCommunity) => {
  if (!companyOrCommunity) return undefined;
  return companyOrCommunity.isCommunity
    ? dashboardApi.getCommunityDashboardData(companyOrCommunity.id)
    : dashboardApi.getCompanyDashboardData(companyOrCommunity.id);
};

export const useCompanyOrCommunityDashboardData = (companyOrCommunity?: CompanyListItem | TinyCommunity) => {
  const swrResult = useSWR<DashboardData>(companyOrCommunityDashboardKey(companyOrCommunity), undefined, {
    // We don't want to cache too long, since a lot of actions could trigger changes to this data
    dedupingInterval: 10000,
  });
  return useSWRResource(swrResult);
};
