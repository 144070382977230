import { Menu, MenuItem, PopoverOrigin, useMediaQuery } from '@mui/material';
import React, { CSSProperties } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import IconButton from 'ui/elements/icons/IconButton';
import EllipsisVertical from 'ui/elements/icons/EllipsisVertical';
import { IconColor } from 'ui/elements/icons/SvgIcon';
import Tooltip from 'ui/elements/Tooltip';
import DrawerBottom, { DrawerItem } from 'ui/elements/DrawerBottom';

export interface PopMenuItem {
  onClick?: () => void;
  icon?: JSX.Element;
  text: JSX.Element | string;
  selected?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  backgroundColor?: string;
}

interface Props {
  style?: CSSProperties;
  className?: string;
  items?: PopMenuItem[];
  icon?: JSX.Element;
  tooltip?: string;
  iconColor?: IconColor;
  staticItems?: React.ReactNode;
  renderAnchor?: (onClick: (e: React.MouseEvent<HTMLElement>) => void, isOpen: Boolean) => JSX.Element;
  anchorOrigin?: PopoverOrigin;
}

export default function PopMenu({
  style,
  className,
  icon,
  tooltip,
  iconColor,
  renderAnchor,
  staticItems,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  items = [],
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  return (
    <div className={className} style={style}>
      {renderAnchor ? (
        renderAnchor(handleClick, isOpen)
      ) : tooltip ? (
        <Tooltip title={tooltip}>
          <span>
            <IconButton color={iconColor ?? 'indigo'} onClick={handleClick}>
              {icon ?? <EllipsisVertical />}
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <IconButton color={iconColor ?? 'indigo'} onClick={handleClick}>
          {icon ?? <EllipsisVertical />}
        </IconButton>
      )}

      {isMobile && (
        <DrawerBottom isOpen={isOpen} onClose={handleClose}>
          {items.map((item, i) => (
            <DrawerItem
              backgroundColor={item.backgroundColor}
              key={i}
              prefix={item.icon}
              onClick={() => {
                handleClose();
                item.onClick?.();
              }}
            >
              {item.text}
            </DrawerItem>
          ))}
        </DrawerBottom>
      )}
      {!isMobile && (
        <Menu open={isOpen} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={anchorOrigin}>
          {items.map((item, i) => (
            <MenuItem
              key={i}
              data-open={!!open || undefined}
              className={className}
              sx={{ backgroundColor: item.backgroundColor }}
              onMouseEnter={item.onMouseEnter}
              onMouseLeave={item.onMouseLeave}
              onClick={() => {
                item.onClick?.();
                handleClose();
              }}
            >
              {item.icon && <span className="u-flex u-half-spacing-right">{item.icon}</span>}
              {item.text}
            </MenuItem>
          ))}
          {staticItems && <div style={{ padding: bluePlanetTheme.spacing(1) }}>{staticItems}</div>}
        </Menu>
      )}
    </div>
  );
}
