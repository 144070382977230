import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function NeuralNetworkIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21.5 12.584a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM13.5 4.584a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM12.5 12.084a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM6.5 8.084a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM10.5 20.084a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
      <path d="m13.5 5.584 4 5m-3 5.5-4 3m-2.5-1-3-8m1.313-2.846L10.5 5.084m2 7 4.005.344M12 6.084l-1 4.5" />
    </SvgIcon>
  );
}
