import React, { CSSProperties, ReactNode } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import Card from './Card';
import { useMediaQuery } from '@mui/material';

interface Props {
  children: ReactNode;
  backgroundImage?: string;
  hideImage?: boolean;
  backgroundColor?: string;
  color?: string;
  style?: CSSProperties;
  className?: string;
  backgroundGradient?: string;
  bleedOnMobile?: boolean;
  padding?: 'default' | 'half' | 'double' | 'none';
}

export default function ImageCard({
  backgroundColor = bluePlanetTheme.bluePlanetPalette.indigo.dark,
  color = bluePlanetTheme.bluePlanetPalette.white,
  backgroundImage,
  children,
  style,
  backgroundGradient,
  hideImage = false,
  bleedOnMobile = false,
  ...rest
}: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  return (
    <Card
      style={{
        backgroundPosition: 'right center',
        backgroundRepeat: 'no-repeat',
        borderRadius: bleedOnMobile && isMobile ? 0 : undefined,
        backgroundColor,
        color,
        backgroundImage: hideImage
          ? ''
          : [backgroundImage && `url(${backgroundImage})`, backgroundGradient].filter(Boolean).join(','),
        ...style,
      }}
      {...rest}
    >
      {children}
    </Card>
  );
}
