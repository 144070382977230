import React from 'react';
import { ICompany } from 'types/company';
import useResource from 'util/resource/useResource';
import { companyUrls } from 'urls';
import { Link } from 'react-router-dom';
import LoadableResources from 'util/resource/Resources';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import Button from 'ui/elements/buttons/Button';
import { useMediaQuery, useTheme } from '@mui/material';
import { getDateRange } from 'util/dateUtils';
import { NUMBER_OF_DAYS_IN_INSIGHTS_PERIOD } from './Manage';
import StatsCard from 'ui/views/cards/StatsCard';
import { contentSpacing } from 'ui/theme/themeConstants';
import { ProfileEngagement, ProfileInsights } from 'apis/CompanyAPI/insights/types';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

interface Props {
  company: ICompany;
}

export default function CompanyInsights({ company }: Props) {
  const [startDate, endDate] = getDateRange(new Date(), NUMBER_OF_DAYS_IN_INSIGHTS_PERIOD);

  const { resource: profileVisits } = useResource<ProfileInsights>(
    companiesAPIUrls.insights.profileVisits(company.id, startDate, endDate),
  );

  const { resource: profileEngagement } = useResource<ProfileEngagement>(
    companiesAPIUrls.insights.profileEngagement(company.id, startDate, endDate),
  );

  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down('md'));
  const isXlDown = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <MobileMargin className="u-section-spacing-bottom">
      <SectionHeadingLarge heading={`Last ${NUMBER_OF_DAYS_IN_INSIGHTS_PERIOD} days`} />
      <LoadableResources resources={[profileVisits, profileEngagement]}>
        {([profileVisits, profileEngagement]) => {
          return (
            <div
              style={{
                display: 'grid',
                gap: contentSpacing,
                gridTemplateColumns: isTabletDown ? '1fr 1fr' : isXlDown ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr',
              }}
            >
              <StatsCard
                className="u-flex-grow"
                title="Profile views"
                value={profileVisits.visits.value}
                previousValue={profileVisits.visits.previousValue}
                tooltip="The number of times your company profile has been viewed in the current period."
              >
                <Link to={companyUrls.insights(company.slug)}>
                  <Button className="u-content-spacing-top" style={{ width: '100%' }} kind="secondary" color="grey">
                    See more
                  </Button>
                </Link>
              </StatsCard>
              <StatsCard
                className="u-flex-grow"
                title="Saved to collections"
                value={profileEngagement.collectionListings.value}
                previousValue={profileEngagement.collectionListings.previousValue}
                tooltip="The number of times your company profile has been added to the personal collections of other users."
              >
                <Link to={companyUrls.insights(company.slug)}>
                  <Button className="u-content-spacing-top" style={{ width: '100%' }} kind="secondary" color="grey">
                    See more
                  </Button>
                </Link>
              </StatsCard>
              <StatsCard
                className="u-flex-grow"
                title="New connections"
                value={profileEngagement.newConnections.value}
                previousValue={profileEngagement.newConnections.previousValue}
                tooltip="The number of new connections your company has made in the current period."
              >
                <Link to={companyUrls.insights(company.slug)}>
                  <Button className="u-content-spacing-top" style={{ width: '100%' }} kind="secondary" color="grey">
                    See more
                  </Button>
                </Link>
              </StatsCard>
            </div>
          );
        }}
      </LoadableResources>
    </MobileMargin>
  );
}
