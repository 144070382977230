import { BODY_ID } from 'ui/views/layouts/BaseLayout/ScrollableBody';

export const scrollToTop = () => {
  const body = document.getElementById(BODY_ID);
  body?.scrollTo({ top: 0 });
};

export const scrollolol = (top: number) => {
  const body = document.getElementById(BODY_ID);
  body?.scrollTo({ top: top + body.scrollTop, behavior: 'smooth' });
};
