import React, { useEffect, useRef } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import TextField from 'ui/elements/form/TextField';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { useAuth0 } from '@auth0/auth0-react';
import VerifyEmailMessage from 'pages/Registration/common/VerifyEmailMessage';
import { communityUrls, staticFileLocations, userUrls } from 'urls';
import FormRow from 'ui/elements/form/FormRow';
import { FormControl, FormGroup } from '@mui/material';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import CompleteRegistrationLayout from './CompleteRegistrationLayout';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { getOrUndefined } from 'util/resource';
import { useLoginWithRedirect } from 'auth/useLoginWithRedirect';
import featureToggle from 'featureToggle';
import i18n from 'util/i18n';
import { emailRegex } from 'util/stringUtils';
import config from 'config';
import useNotify from 'hooks/useNotify';

export type RegistrationDetails =
  | {
      type: 'company';
      communityCode?: string;
      originUrl?: string; // url where the user was before they were redirected to auth0. Used by auth0 login action
    }
  | {
      type: 'user';
      sharedInviteToken?: string;
      personalInviteToken?: string;
      originUrl?: string;
    };

function getRegistrationDetails(): RegistrationDetails {
  const jsonString = window?.localStorage?.getItem('registerAs');

  try {
    return jsonString ? JSON.parse(jsonString) : { type: 'user' };
  } catch (e) {
    return { type: 'user' };
  }
}
export function setRegistrationDetails(registrationDetails: RegistrationDetails) {
  window?.localStorage?.setItem('registerAs', JSON.stringify(registrationDetails));
}

export default function CompleteRegistration(props: RouteComponentProps) {
  const params = new URLSearchParams(props.location.search);
  const { logout } = useAuth0();

  const emailFromParams = params.get('email');

  const registrationDetails = getRegistrationDetails();

  const communityCode = registrationDetails.type === 'company' ? registrationDetails.communityCode : undefined;

  const [communityRegistrationTokenResource] = useResourceLegacy(
    () => (communityCode ? companiesApi.companyProfile.getFromInviteCode(communityCode) : Promise.resolve(undefined)),
    [communityCode],
  );

  const sharedInviteToken = registrationDetails.type === 'user' ? registrationDetails.sharedInviteToken : undefined;
  const personalInviteToken = registrationDetails.type === 'user' ? registrationDetails.personalInviteToken : undefined;
  const originUrl = registrationDetails.originUrl;
  const verificationToken = params.get('verificationToken');
  const notify = useNotify();

  const registrationState = params.get('areTermsAccepted') === 'true' ? 'Verify email' : 'Accept terms';
  const error = params.get('errormessage');

  useEffect(() => {
    if (error) {
      notify('error', error);
    }
  }, []);

  const formRef = useRef<HTMLFormElement>(null);

  const { getReturnTo, setReturnTo } = useLoginWithRedirect();
  // todo remove when new auth0 script is live in production
  const imageUrl = params.get('imageUrl') ?? undefined;
  const initialName = params.get('name') ?? '';
  const formik = useFormik({
    initialValues: {
      name: initialName,
      imageUrl: ['cdn.auth0.com', 'gravatar'].some(domain => imageUrl?.includes(domain)) ? undefined : imageUrl, // Most people don't have Gravatar, so we get images with initials, and the colors are not on brand
      email: emailFromParams ?? '',
      companyName: '',
      acceptedUserTerms: false,
    },
    initialErrors: {
      email: error || undefined,
    },
    initialTouched: {
      email: !!error,
    },

    onSubmit: () => {
      window?.localStorage.removeItem('registerAs');
      formRef.current?.submit();
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .min(1, 'Your name should be at least 1 real character') // This is how auth0 signup does it
        .required('Your name is required'),
      // email is required if verificationToken is not present
      email: Yup.string()
        .matches(emailRegex, { message: 'Please enter a valid email address' })
        .when('verificationToken', {
          is: (verificationToken: string) => !verificationToken,
          then: schema => schema.required('Your email is required'),
          otherwise: schema => schema.notRequired(),
        }),
      companyName:
        registrationDetails.type === 'company'
          ? Yup.string().required('Please enter the name of your company')
          : Yup.string().notRequired(),
      acceptedUserTerms: Yup.boolean()
        .required()
        .oneOf(
          [true],
          'In order to use CrowdWorks we kindly ask you to read and accept our terms of use and privacy policy.',
        ),
    }),
    enableReinitialize: true,
  });

  const { user } = useAuth0();
  const emailIsVerified = user?.email_verified === true;

  if (registrationState === 'Accept terms') {
    return (
      <CompleteRegistrationLayout
        registerAs={registrationDetails}
        communityRegistrationToken={communityRegistrationTokenResource}
      >
        <form
          ref={formRef}
          action={`https://${config.AUTH0_DOMAIN}/continue?state=${params.get('state')}`}
          method="post"
          style={{
            width: '100%', // without this, there is an extra gap around the form
          }}
        >
          <TextField hidden name="imageUrl" value={formik.values.imageUrl} />
          <TextField hidden name="sharedInviteToken" value={sharedInviteToken} />
          <TextField hidden name="personalInviteToken" value={personalInviteToken} />
          <TextField hidden name="originUrl" value={originUrl} />
          <TextField hidden name="communityCode" value={communityCode} />
          <TextField hidden name="verificationToken" value={verificationToken} />
          {initialName ? (
            <TextField hidden name="name" value={formik.values.name} />
          ) : (
            <TextField
              name="name"
              onChange={formik.handleChange}
              label="Your personal name"
              placeholder="Jane Doe"
              value={formik.values.name}
              helperText={formik.errors.name ?? ' '}
              error={!!formik.errors.name && !!formik.touched.name}
              autoFocus
            />
          )}
          {!emailFromParams ? (
            <TextField
              name="email"
              onChange={formik.handleChange}
              label={i18n('en').common.email('capitalize')}
              value={formik.values.email}
              error={!!formik.errors.email && !!formik.touched.email}
              helperText={formik.errors.email ?? ' '}
              autoFocus={!!initialName} // Only focus on email input if name input is not visible
            />
          ) : (
            <TextField hidden name="email" value={formik.values.email} />
          )}

          {registrationDetails.type === 'user' && (
            <FormGroup className="u-half-spacing-top">
              <FormControl required error={!!formik.errors.acceptedUserTerms && !!formik.touched.acceptedUserTerms}>
                <Checkbox
                  name="acceptedUserTerms"
                  onChange={formik.handleChange}
                  checked={!!formik.values.acceptedUserTerms}
                  color="primary"
                  label={
                    <span>
                      By signing up you agree to our&nbsp;
                      <a
                        className="text-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={userUrls.legal.termsOfUse()}
                      >
                        terms of use
                      </a>
                      &nbsp; and{' '}
                      <a
                        className="text-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={userUrls.legal.privacyPolicy()}
                      >
                        privacy policy
                      </a>
                    </span>
                  }
                  helperText={formik.touched.acceptedUserTerms ? formik.errors.acceptedUserTerms ?? ' ' : '  '}
                />
              </FormControl>
            </FormGroup>
          )}
          {registrationDetails.type === 'company' && (
            <>
              <FormikTextField
                className="u-content-spacing-top"
                name="companyName"
                label="Company name"
                placeholder="Kramerica industries"
                formikProps={formik}
              />
              <FormRow>
                <FormControl required error={!!formik.errors.acceptedUserTerms && !!formik.touched.acceptedUserTerms}>
                  <Checkbox
                    name="acceptedUserTerms"
                    onChange={formik.handleChange}
                    checked={formik.values.acceptedUserTerms}
                    color="primary"
                    fontSize="small"
                    label={
                      <span>
                        I agree to the{' '}
                        <a
                          className="text-link"
                          href={staticFileLocations.termsOfAgreements}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          terms and conditions
                        </a>{' '}
                        and the&nbsp;
                        <a
                          href={staticFileLocations.dataProcessingAgreement}
                          className="text-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          data processing agreement
                        </a>
                      </span>
                    }
                    helperText={formik.touched.acceptedUserTerms ? formik.errors.acceptedUserTerms ?? ' ' : '  '}
                  />
                </FormControl>
              </FormRow>
            </>
          )}
          <ButtonList className="u-content-spacing-top">
            <Button
              kind="primary"
              type="submit"
              onClick={e => {
                e.preventDefault();
                const community = getOrUndefined(communityRegistrationTokenResource);
                if (featureToggle.isMentorCommunity(community?.id)) {
                  setReturnTo(communityUrls.innovasjonNorge.registrationRedirect());
                } else if (community?.hasApplication) {
                  setReturnTo(communityUrls.overview(community.slug));
                }
                formik.handleSubmit();
              }}
            >
              Continue
            </Button>
            <Button
              kind="tertiary"
              onClick={() => {
                window?.localStorage.removeItem('registerAs');
                logout();
              }}
            >
              Cancel
            </Button>
          </ButtonList>
        </form>
      </CompleteRegistrationLayout>
    );
  } else if (!emailIsVerified) {
    return <VerifyEmailMessage email={formik.values.email} />;
  } else return <Redirect to={getReturnTo() ?? userUrls.dashboard.home()} />;
}
