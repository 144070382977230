import config from 'config';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { TrustLevel } from 'types/company/company';
import { DocumentDownloadDTO, DocumentDTO, DocumentType, FolderDTO, UploadDocumentResponse } from 'types/documents';
import { ICwUserId } from 'types/user';
import ApiBase from '../ApiBase';
import { CompanyId } from 'urls';

const apiUrl = `${config.DOCUMENTS_API_URL}`;
const documentsBaseUrl = `${apiUrl}/documents`;
const foldersBaseUrl = `${apiUrl}/folders`;

const getDocumentsIndexUrl = (companyId: CompanyId, folderId: FolderDTO['id']) =>
  `${documentsBaseUrl}/${companyId}/folder/${folderId}`;

const getFolderListUrl = (companyId: CompanyId) => `${foldersBaseUrl}/${companyId}`;

const api = ApiBase();

export const documentsUrls = {
  documentCenter: {
    listFolders: (companyId: number) => getFolderListUrl(companyId),
    listDocuments: (companyId: number, folderId: string) => getDocumentsIndexUrl(companyId, folderId),
    listUnread: (companyId: number) => `${documentsBaseUrl}/${companyId}/unread`,
  },
  boardroom: {
    listFolders: (companyId: number) => `${apiUrl}/boardroom/folders/${companyId}`,
    listLatestDocuments: (companyId: number) => `${apiUrl}/boardroom/documents/${companyId}/latest`,
    listDocuments: (companyId: number, folderId: string) =>
      `${apiUrl}/boardroom/documents/${companyId}/folder/${folderId}`,
    listUnread: (companyId: number) => `${apiUrl}/boardroom/documents/${companyId}/unread`,
  },
};

export default {
  list: (companyId: number, folderId: FolderDTO['id']) =>
    api.get<{ documents: DocumentDTO[] }>(getDocumentsIndexUrl(companyId, folderId)),
  download: (companyId: number, documentId: DocumentDTO['id']) =>
    api.get<DocumentDownloadDTO>(`${documentsBaseUrl}/${companyId}/${documentId}`),
  listFolders: (companyId: number) => api.get<{ folders: FolderDTO[] }>(getFolderListUrl(companyId)),
  createFolder: (
    companyId: number,
    payload: {
      parentId?: FolderDTO['parentId'];
      name: FolderDTO['name'];
      trustLevels: TrustLevel[];
    },
  ) => api.post<void>(getFolderListUrl(companyId), payload),
  editFolder: (
    companyId: number,
    folderId: FolderDTO['id'],
    payload: {
      name: FolderDTO['name'];
      trustLevels: TrustLevel[];
    },
  ) => api.patch<void>(`${foldersBaseUrl}/${companyId}/folder/${folderId}`, payload),
  deleteFolder: (companyId: number, folderId: FolderDTO['id']) =>
    api.deleteRequest<void>(`${foldersBaseUrl}/${companyId}/folder/${folderId}`),
  deleteDocument: (companyId: number, documentId: DocumentDTO['id']) =>
    api.deleteRequest(`${documentsBaseUrl}/${companyId}/${documentId}`),
  upload: (companyId: number, folderId: FolderDTO['id'], payload: { filename: string; documentType?: DocumentType }) =>
    api.post<UploadDocumentResponse>(getDocumentsIndexUrl(companyId, folderId), payload),
  userFiles: {
    uploadFile: (folderName: string, payload: { filename: string }) =>
      api.post<UploadDocumentResponse>(`${apiUrl}/userfiles?folder=${folderName}`, payload),
    deleteFile: (documentId: UUID) => api.deleteRequest<UploadDocumentResponse>(`${apiUrl}/userfiles/${documentId}`),
  },
  listDownloadedBy: (companyId: number, documentId: DocumentDTO['id'], paginationOptions?: PaginationOptions) =>
    api.get<PaginatedResult<ICwUserId>>(`${documentsBaseUrl}/${companyId}/${documentId}/downloads`, {
      requestParams: paginationOptions,
    }),

  boardroom: {
    list: (companyId: number, folderId: FolderDTO['id']) =>
      api.get<{ documents: DocumentDTO[] }>(`${apiUrl}/boardroom/documents/${companyId}/folder/${folderId}`),

    download: (companyId: number, documentId: DocumentDTO['id']) =>
      api.get<DocumentDownloadDTO>(`${apiUrl}/boardroom/documents/${companyId}/${documentId}`),
    upload: (companyId: number, folderId: FolderDTO['id'], payload: { filename: string }) =>
      api.post<UploadDocumentResponse>(`${apiUrl}/boardroom/documents/${companyId}/folder/${folderId}`, payload),
    deleteDocument: (companyId: number, documentId: DocumentDTO['id']) =>
      api.deleteRequest(`${apiUrl}/boardroom/documents/${companyId}/${documentId}`),
    createFolder: (
      companyId: number,
      payload: {
        parentId?: FolderDTO['parentId'];
        name: FolderDTO['name'];
      },
    ) => api.post<void>(`${apiUrl}/boardroom/folders/${companyId}`, payload),
    editFolder: (
      companyId: number,
      folderId: FolderDTO['id'],
      payload: {
        name: FolderDTO['name'];
      },
    ) => api.patch<void>(`${apiUrl}/boardroom/folders/${companyId}/folder/${folderId}`, payload),
    deleteFolder: (companyId: number, folderId: FolderDTO['id']) =>
      api.deleteRequest<void>(`${apiUrl}/boardroom/folders/${companyId}/folder/${folderId}`),
    listDownloadedBy: (companyId: number, documentId: DocumentDTO['id'], paginationOptions?: PaginationOptions) =>
      api.get<PaginatedResult<ICwUserId>>(`${apiUrl}/boardroom/documents/${companyId}/${documentId}/downloads`, {
        requestParams: paginationOptions,
      }),
  },
};
