import ApiBase from 'apis';
import config from 'config';
import { Organization, Service, UpdateOrganizationDTO } from 'types/organization';

const baseUrl = config.COMPANIES_API_URL;
const api = ApiBase();

export default {
  getBySlug: (slug: string) => `${baseUrl}/organizations/${slug}`,
  listAllServices: () => api.get<Service[]>(`${baseUrl}/labels/services`),
  listAllServicesUrl: `${baseUrl}/labels/services`,
  listAllTypes: () => api.get<Service[]>(`${baseUrl}/labels/organization-types`),
  listAllTypesUrl: `${baseUrl}/labels/organization-types`,
  update: (organizationId: string, dto: UpdateOrganizationDTO) =>
    api.put<Organization>(`${baseUrl}/organizations/${organizationId}`, dto),
};
