import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function ExpandIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m8.2 16.566 7.6-7.601m-7.6 7.601c-.444-.443-.01-2.535 0-3.167m0 3.167c.443.444 2.535.01 3.167 0M15.8 8.965c-.444-.444-2.536-.01-3.168 0m3.168 0c.443.443.009 2.535 0 3.167" />
      <path d="M2.5 12.766c0-4.479 0-6.718 1.391-8.11 1.391-1.39 3.63-1.39 8.109-1.39 4.478 0 6.718 0 8.109 1.39C21.5 6.049 21.5 8.288 21.5 12.767c0 4.478 0 6.717-1.391 8.108-1.392 1.392-3.63 1.392-8.109 1.392-4.478 0-6.718 0-8.109-1.392C2.5 19.484 2.5 17.244 2.5 12.766Z" />
    </SvgIcon>
  );
}
