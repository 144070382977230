import { Organization, Service } from 'types/organization';
import React, { useState } from 'react';
import Picker from 'domain/labels/Picker';
import Resource from 'util/resource/Resource';
import useResource from 'util/resource/useResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import Button from 'ui/elements/buttons/Button';
import { SUCCESS_WIDTH } from 'pages/CompanySettings/utils';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';
import useNotify from 'hooks/useNotify';
import { FormikProps } from 'formik';

interface ServicesSettingsProps {
  organization: Organization;
  onShowPreview: () => void;
  formik: FormikProps<any>;
}

export function ServicesSettings(props: ServicesSettingsProps) {
  const notify = useNotify();
  const { resource } = useResource<Service[]>(OrganizationAPI.listAllServicesUrl);
  const [success, setSuccess] = useState(false);
  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(props.organization, {
    onSuccess: () => {
      setSuccess(true);
      notify('success', 'Settings saved');
    },
  });

  return (
    <>
      <Resource resource={resource}>
        {Services => (
          <Picker
            name={'Services'}
            label={'Services'}
            setItems={services => {
              props.formik.setFieldValue('services', services);
            }}
            selectedItems={props.formik.values.services}
            optionLabel={service => service.name}
            optionsValues={Services}
            placeholder={'Select services'}
          />
        )}
      </Resource>
      <ButtonList align="left" className="u-content-spacing-top">
        <Button
          onClick={() =>
            updateOrganization({
              services: props.formik.values.services.map((s: Service) => s.id),
            })
          }
          kind="primary"
          isLoading={isUpdatingOrganization}
          isSuccess={success}
          setSuccess={(isSuccess: boolean) => setSuccess(isSuccess)}
          style={{ width: `${SUCCESS_WIDTH}px` }}
          disabled={isUpdatingOrganization || success}
        >
          Update
        </Button>
        <Button kind="tertiary" color="grey" onClick={props.onShowPreview}>
          <EyeIcon className="u-quarter-spacing-right" /> Preview profile
        </Button>
      </ButtonList>
    </>
  );
}
