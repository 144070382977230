import React, { useRef, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { iso8601DateString } from 'util/dateUtils';
import Chip from 'ui/elements/Chip';

import { useHistory } from 'react-router';
import DatePicker from 'ui/elements/form/DatePicker';
import { DATE_FORMAT } from 'util/constants';
import LinkButton from 'ui/elements/buttons/LinkButton';
import isValid from 'date-fns/isValid';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import differenceInDays from 'date-fns/differenceInDays';
import isSameDay from 'date-fns/isSameDay';

interface DateRangePreset {
  id: string;
  label: string;
  range: () => { startDate: Date; endDate: Date };
}

interface Props {
  initialRange: {
    startDate: Date;
    endDate: Date;
  };
  presets: DateRangePreset[];
  maxDaysRange: number;
  onChange: (range: { startDate: Date; endDate: Date }) => void;
}

function parseRelativeTime(str: string) {
  const match = str.match(/\-([0-9]+)d/);
  if (match) {
    const days = parseInt(match[1], 10);

    return addDays(new Date(), (days - 1) * -1);
  }
  return null;
}

function parseValidISODate(str: string) {
  const date = parseISO(str);
  return isValid(date) ? date : null;
}

function getInitialRange(
  from: string | null,
  to: string | null,
  maxDaysRange: number,
): { startDate: Date; endDate: Date } {
  const defaultStartDate = startOfDay(addDays(new Date(), -29));
  const defaultEndDate = endOfDay(new Date());
  const startDate = from ? parseValidISODate(from) || parseRelativeTime(from) : null;
  const endDate = to ? parseValidISODate(to) : null;

  if (startDate && endDate && differenceInDays(endDate, startDate) >= maxDaysRange) {
    return {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    };
  } else {
    return {
      startDate: startDate ?? defaultStartDate,
      endDate: endDate ?? defaultEndDate,
    };
  }
}

export function getDefaultDateRange() {
  return {
    startDate: startOfDay(addDays(new Date(), -29)),
    endDate: endOfDay(new Date()),
  };
}

export function getInitialDateRangeFromSearchParams(str: string, maxDaysRange: number) {
  const searchParams = new URLSearchParams(str);
  return getInitialRange(searchParams.get('from'), searchParams.get('to'), maxDaysRange);
}

export default function DateRangePresetsPicker(props: Props) {
  const history = useHistory();
  const rangeButton = useRef(null);
  const [rangePickerOpen, setRangePickerOpen] = useState(false);

  function deriveState(startDate: Date, endDate: Date) {
    const preset = props.presets.find(entry => {
      const range = entry.range();
      return isSameDay(range.startDate, startDate) && isSameDay(range.endDate, endDate);
    });

    return {
      startDate,
      endDate,
      preset: preset?.id,
    };
  }

  const [state, setState] = useState(deriveState(props.initialRange.startDate, props.initialRange.endDate));

  function isDirty(startDate: Date, endDate: Date) {
    return !(props.initialRange.startDate === startDate && props.initialRange.endDate === endDate);
  }

  function close(startDate: Date, endDate: Date, preset: string | undefined) {
    setRangePickerOpen(false);

    if (!isDirty(startDate, endDate)) {
      return;
    }

    const params = new URLSearchParams();
    if (preset) {
      params.append('from', preset);
    } else {
      params.append('from', iso8601DateString(startDate));
      params.append('to', iso8601DateString(endDate));
    }

    history.replace({
      pathname: history.location.pathname,
      search: params.toString(),
    });

    props.onChange({ startDate, endDate });
  }

  function onClose() {
    close(state.startDate, state.endDate, state.preset);
  }

  function updateState(startDate: Date, endDate: Date, usePresets: boolean) {
    if (usePresets) {
      const nextState = deriveState(startDate, endDate);
      setState(nextState);
      close(nextState.startDate, nextState.endDate, nextState.preset);
    } else {
      setState({ startDate, endDate, preset: undefined });
    }
  }

  return (
    <span className="u-inline-flex u-flex--wrap u-flex--gap-half">
      <span ref={rangeButton}>
        <Chip
          useShadow
          color="white"
          onClick={() => setRangePickerOpen(true)}
          label={(state.preset && props.presets.find(p => p.id === state.preset)?.label) ?? 'Custom range'}
          fontSize="large"
        />
      </span>
      <Menu open={rangePickerOpen} anchorEl={rangeButton.current} onClose={onClose}>
        {props.presets.map(p => (
          <MenuItem
            key={p.id}
            onClick={() => {
              const range = p.range();
              updateState(range.startDate, range.endDate, true);
            }}
          >
            {p.label}
          </MenuItem>
        ))}
        <MenuItem
          key="custom"
          onClick={() => {
            setRangePickerOpen(false);
            updateState(state.startDate, state.endDate, false);
          }}
        >
          Custom range
        </MenuItem>
      </Menu>
      {!state.preset && (
        <>
          from
          <span>
            <DatePicker
              selected={state.startDate}
              dateFormat={DATE_FORMAT}
              onChange={date => {
                if (date instanceof Date) {
                  updateState(date, state.endDate, false);
                }
              }}
            />
          </span>
          to
          <span>
            <DatePicker
              selected={state.endDate}
              dateFormat={DATE_FORMAT}
              onChange={date => {
                if (date instanceof Date) {
                  updateState(state.startDate, date, false);
                }
              }}
            />
          </span>
          {isDirty(state.startDate, state.endDate) && (
            <LinkButton
              onClick={() => {
                if (differenceInDays(state.endDate, state.startDate) >= props.maxDaysRange) {
                  alert(`Maximum date range is ${props.maxDaysRange} days`);
                } else {
                  onClose();
                }
              }}
            >
              Update
            </LinkButton>
          )}
        </>
      )}
    </span>
  );
}
