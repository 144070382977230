import { emptyQuillContent, updateValidationSchema } from 'domain/Updates/shared/utils';
import { useFormik } from 'formik';
import { CompanyUpdateFormValues } from '../../CompanyUpdate/UpdateForm/types';
import useFileUpload from 'domain/shared/Files/Upload/useFileUpload';
import usePrompt from 'hooks/usePrompt';
import { uploadFile } from 'domain/shared/Files/actions';
import DocumentAPI from 'apis/DocumentAPI';
import i18n from 'util/i18n';
import { DocumentList } from 'types/documents';

export function useUpdateForm(props: {
  onSave: (dto: CompanyUpdateFormValues) => void;
  titleDraft?: string;
  contentDraft?: string;
  bannerImage?: string;
  attachments?: DocumentList;
  linkPreview?: string;
}) {
  const formik = useFormik<CompanyUpdateFormValues>({
    initialValues: {
      title: props.titleDraft ?? undefined,
      category: 'news',
      bannerImage: props.bannerImage || '',
      content: props.contentDraft ?? emptyQuillContent,
      structuredContent: {} as string,
      attachments: props.attachments?.documents.map(doc => doc.id) || [],
      linkPreview: props.linkPreview,
    },
    onSubmit: values => {
      const data: CompanyUpdateFormValues = {
        attachments: values.attachments,
        title: values.title,
        category: values.category,
        bannerImage: values.bannerImage,
        content: values.content,
        structuredContent: JSON.stringify(values.structuredContent) || '{}',
        linkPreview: values.linkPreview,
      };
      props.onSave(data);
    },
    validationSchema: updateValidationSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });
  const onAttachmentUploaded = (files: { documentId: string; name: string }[]) => {
    const newDocumentIds = files.map(f => f.documentId);
    formik.setFieldValue('attachments', [...formik.values.attachments, ...newDocumentIds]);
  };

  const onAttachmentRemoved = (documentId: UUID): Promise<void> => {
    DocumentAPI.userFiles.deleteFile(documentId);
    return Promise.resolve(
      formik.setFieldValue(
        'attachments',
        formik.values.attachments.filter(doc => doc !== documentId),
      ),
    ).then(() => {});
  };

  usePrompt(formik.dirty, formik.isSubmitting);
  const [newAttachments, onUploadFile, removeFile] = useFileUpload(file => uploadFile('updates', file), {
    onSuccess: onAttachmentUploaded,
    deleteFile: onAttachmentRemoved,
  });

  const deleteAttachments = (attachmentIdsToKeep: string[], allAttachmentIds: string[]) => {
    const attachmentsToDelete = allAttachmentIds.filter(att => !attachmentIdsToKeep.includes(att));
    attachmentsToDelete?.forEach(documentId => {
      DocumentAPI.userFiles.deleteFile(documentId);
    });
  };

  const onCancel = (callback: () => void) => {
    if (formik.dirty && !formik.isSubmitting) {
      const confirmed = confirm(i18n('en').common.unsavedChangesPrompt);
      if (confirmed) {
        deleteAttachments(props.attachments?.documents.map(d => d.id) ?? [], formik.values.attachments);
        callback();
      }
    } else {
      callback();
    }
  };

  const onSubmit = () => {
    deleteAttachments(formik.values.attachments, props.attachments?.documents.map(d => d.id) ?? []);
    formik.handleSubmit();
  };

  return { formik, newAttachments, onAttachmentRemoved, onUploadFile, removeFile, onCancel, onSubmit };
}
