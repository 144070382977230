import React, { useEffect, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import { SelfUserProfile, PatchUserPayload } from 'types/user';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';
import useNotify from 'hooks/useNotify';
import { saveUser } from 'actions/userProfiles';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { USER_WIZARD_CONTENT_MIN_HEIGHT } from './UserOnboardingWizard';
import CompanyRegistrationForm from 'pages/Registration/common/CompanyRegistrationForm';
import { NewCompanyDTO } from 'pages/Registration/common/actions';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { invalidate } from 'hooks/useSWR';
import { companiesKey } from 'apis/CompanyAPI/companies/useCompanies';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { companyProfileKey } from 'apis/CompanyAPI/companies/useCompanyProfile';
import { CompanyProfile, ICompany } from 'types/company';
import useLocalStorage, { getLocalStorageItem } from 'hooks/useLocalStorage';
import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import { communityAccessKey } from 'apis/CompanyAPI/companies/useCommunityAccess';
import { communityKey } from 'apis/CompanyAPI/communities/useCommunity';
import { communitiesKey } from 'apis/CompanyAPI/communities/useCommunities';
import ToggleButton, { ToggleButtonGroup } from 'ui/elements/form/choice/ToggleButton';

interface Props {
  onStart?: () => void;
  onComplete: () => void;
  userProfile: SelfUserProfile;
  onShowCompanyWizard: (company: ICompany) => void;
}

export default function ChooseYourRole({ onComplete, onStart, onShowCompanyWizard }: Props) {
  const notify = useNotify();

  const [showError, setShowError] = useState(false);
  const [role, setRole] = useState<'company' | 'investor' | 'mentor-or-advisor' | null>();

  const onRoleChange = (role: 'company' | 'investor' | 'mentor-or-advisor') => {
    setRole(role);
    setShowError(false);
  };

  const requestToJoinCommunitySlug = getLocalStorageItem('join-community', undefined);
  const [, , removeFromLocalStorage] = useLocalStorage<string | undefined>('join-community', undefined);

  const [requestToJoinCommunity] = useLazyResource(
    (company?: ICompany) => {
      if (!!requestToJoinCommunitySlug) {
        removeFromLocalStorage();
        if (company)
          return communitiesApi.accessRequest
            .requestToJoinAsCompany(requestToJoinCommunitySlug, company.id)
            .then(() => {});
        else
          return communitiesApi.accessRequest
            .requestToJoin(requestToJoinCommunitySlug, role === 'investor' ? 'investor' : 'advisor')
            .then(() => {});
      } else {
        return Promise.resolve();
      }
    },
    {
      onSuccess: () => {
        //  we need to refetch these in case the user is auto-accepted into the community
        requestToJoinCommunitySlug && invalidate(communityAccessKey(requestToJoinCommunitySlug));
        requestToJoinCommunitySlug && invalidate(communityKey(requestToJoinCommunitySlug));
        //  we need to refetch these to show correct data on the dashboard
        invalidate(communitiesKey);
      },
    },
  );
  const [onSaveUser, isSavingUser] = useLazyResource((user: PatchUserPayload) => saveUser(user), {
    onSuccess: () => {
      requestToJoinCommunity(undefined);
      invalidate(userDashboardKey);
      onComplete();
    },
    onFailure: () => notify('error', `Unable to save changes`),
  });

  const [onRegisterCompany, isRegistering] = useLazyResource(
    (newCompany: NewCompanyDTO) =>
      companiesApi.registration.registerCompany(
        undefined,
        {
          company: {
            companyName: newCompany.companyName,
            isCommunity: false,
          },
          originUrl: window.location.toString(),
        },
        {},
      ),
    {
      onSuccess: result => {
        requestToJoinCommunity(result.company);
        // preload company profile. This prevents  a new loading screen when changing to the company wizard
        invalidate<CompanyProfile>(companyProfileKey(result.company.slug), {
          ...result.company,
          hasPublicPitch: true,
          industries: { values: [] },
          stages: { values: [] },
          connectionCount: 0,
          lastUpdated: new Date().toISOString(),
        });
        onShowCompanyWizard(result.company);
        invalidate(userDashboardKey);
        invalidate(companiesKey);
      },
      onFailure: error => notify('error', error || `Failed to register company.`),
    },
  );

  useEffect(() => {
    onStart && onStart();
  }, [onStart]);

  return (
    <>
      <Content style={{ minHeight: USER_WIZARD_CONTENT_MIN_HEIGHT }}>
        <ToggleButtonGroup>
          <ToggleButton
            isSelected={role === 'investor'}
            title="Investor"
            description="I am an investor or scouting for an investment firm"
            onClick={() => onRoleChange('investor')}
          />
          <ToggleButton
            title="Startup"
            description="I am the founder or represent a company that is looking to network & secure funding"
            isSelected={role === 'company'}
            onClick={() => onRoleChange('company')}
          />
          <ToggleButton
            title="Advisor or mentor"
            description="I am mentoring or act as an advisor for early phase companies"
            isSelected={role === 'mentor-or-advisor'}
            onClick={() => onRoleChange('mentor-or-advisor')}
          />
        </ToggleButtonGroup>

        {role === 'company' && (
          <div className="u-content-spacing-top u-section-spacing-bottom">
            <CompanyRegistrationForm
              registerAs="company"
              isSaving={isRegistering}
              submitButtonText="Next"
              onRegisterNew={onRegisterCompany}
              autoFocus
            />
          </div>
        )}
        {showError && <p className="u-half-spacing-top text-error">Please choose your role</p>}
      </Content>
      {role !== 'company' && (
        <DialogActions align="space-between">
          <ButtonList>
            <Button
              onClick={() => {
                if (!role) {
                  setShowError(true);
                } else {
                  onSaveUser({
                    isInvestor: role === 'investor',
                  });
                }
              }}
              kind="primary"
              isLoading={isSavingUser}
              className="track-investor-onboarding-role-continue track-wizard-step-continue"
            >
              Next
            </Button>
          </ButtonList>
        </DialogActions>
      )}
    </>
  );
}
