import { Badge, Tooltip } from '@mui/material';
import UserListDialog from 'domain/users/UserListDialog';
import DeleteDocumentDialog from 'domain/companies/Documents/Delete/DeleteDocumentDialog';
import useDialogHandler from 'hooks/useDialogHandler';
import React, { useState } from 'react';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { DocumentDTO } from 'types/documents';
import { ICwUserId } from 'types/user';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import UsersIcon from 'ui/elements/icons/UsersIcon';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import i18n from 'util/i18n';
import IconButton from 'ui/elements/icons/IconButton';
import DownloadIcon from 'ui/elements/icons/DownloadIcon';
import CircularProgress from '@mui/material/CircularProgress';
import PopMenu from 'ui/modules/PopMenu';

interface Props {
  onCopy: (documentId: DocumentDTO['id']) => void;
  document: DocumentDTO;
  mobile: boolean;
  companyId: number;
  listDownloadedBy: (PaginationOptions: PaginationOptions) => Promise<PaginatedResult<ICwUserId>>;
  deleteDocument: (documentId: string) => Promise<any>;
  isDeleting: boolean;
  downloadDocument: () => void;
  isDownloading: boolean;
  canUploadDocuments: boolean;
}

function StyleBadge({ downloads }: { downloads: number }) {
  return (
    <Badge badgeContent={downloads > 9999 ? '10k+' : downloads} color="primary">
      <UsersIcon />
    </Badge>
  );
}

export default function DocumentAction(props: Props) {
  const downloadedByDialogHandler = useDialogHandler();
  const [showDeleteDocument, setShowDeleteDocument] = useState(false);
  const downloads = props.document.downloads;

  const menu = props.mobile ? (
    <PopMenu
      items={[
        {
          text: <>Downloaded by</>,
          icon: <StyleBadge downloads={downloads} />,
          onClick: downloadedByDialogHandler.open,
        },
        {
          text: <>Download</>,
          icon: <DownloadIcon />,
          onClick: props.downloadDocument,
        },
        ...(props.canUploadDocuments
          ? [
              {
                text: <>Copy link</>,
                icon: <LinkIcon />,
                onClick: () => props.onCopy(props.document.id),
              },
              {
                text: <>Delete document</>,
                icon: <TrashIcon />,
                onClick: () => setShowDeleteDocument(true),
              },
            ]
          : []),
      ]}
    />
  ) : (
    <>
      <Tooltip title={'Download'} placement="left">
        <span>
          <IconButton
            color="indigo"
            hoverColor="blue"
            className="u-half-spacing-right"
            onClick={() => props.downloadDocument()}
          >
            {!props.isDownloading ? <DownloadIcon /> : <CircularProgress size={24} />}
          </IconButton>
        </span>
      </Tooltip>
      {props.canUploadDocuments && (
        <>
          <Tooltip title={i18n('en').documents.seenBy} placement="bottom">
            <span>
              <IconButton
                color="indigo"
                hoverColor="blue"
                className="u-half-spacing-right"
                onClick={downloadedByDialogHandler.open}
              >
                <StyleBadge downloads={downloads} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={i18n('en').documents.copyLink}>
            <span>
              <IconButton
                color="indigo"
                hoverColor="blue"
                className="u-half-spacing-right"
                onClick={() => props.onCopy(props.document.id)}
              >
                <LinkIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={i18n('en').documents.deleteDocument} disableFocusListener>
            <span>
              <IconButton color="indigo" hoverColor="red" onClick={() => setShowDeleteDocument(true)}>
                <TrashIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )}
    </>
  );

  return (
    <div className="u-flex-end">
      {menu}
      <UserListDialog
        isOpen={downloadedByDialogHandler.isOpen}
        closeDialog={downloadedByDialogHandler.close}
        title="Downloaded by"
        fetchResource={props.listDownloadedBy}
      />
      <DeleteDocumentDialog
        isOpen={showDeleteDocument}
        onClose={() => setShowDeleteDocument(false)}
        document={props.document}
        onDelete={() => props.deleteDocument(props.document.id)}
        isDeleting={props.isDeleting}
      />
    </div>
  );
}
