import { useFormik } from 'formik';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import React from 'react';
import { IShortlist } from 'types/user';
import Button from 'ui/elements/buttons/Button';
import CopyButton from 'ui/elements/CopyButton';
import Switch from 'ui/elements/form/choice/Switch';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import FormRow from 'ui/elements/form/FormRow';
import Label from 'ui/elements/form/Label';
import QuickEditField from 'ui/elements/form/QuickEditField';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import { userUrls } from 'urls';
import { SHORTLIST_DESCRIPTION_MAX_LENGTH } from 'util/constants';
import * as Yup from 'yup';

interface ShortlistCreateDialogProps {
  dialogHandler: DialogHandlerProps;
  onSubmit: (listName: string, description?: string) => void;
  isSaving: boolean;
}

interface ShortlistDuplicateDialogProps {
  dialogHandler: DialogHandlerProps;
  shortlistName: string;
  description?: string;
  onSubmit: (listName: string, description?: string) => void;
  isSaving: boolean;
}

interface ShortlistShareDialogProps {
  dialogHandler: DialogHandlerProps;
  isPublic: boolean;
  onSubmit: () => void;
  isSaving: boolean;
}

interface ShortlistSettingsDialogProps {
  dialogHandler: DialogHandlerProps;
  shortlist: IShortlist;
  onSubmit: (list: IShortlist) => void;
  onDelete: () => void;
  isSaving: boolean;
}

interface ShortlistDeleteDialogProps {
  dialogHandler: DialogHandlerProps;
  title: string;
  content: JSX.Element | string;
  onConfirm: () => void;
  isSaving: boolean;
}

const shortlistSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a name for this list'),
  description: Yup.string().notRequired().max(500, 'Description cannot be longer than 500 characters'),
});

export function ShortlistCreateDialog(props: ShortlistCreateDialogProps) {
  const formik = useFormik({
    initialValues: {
      name: '',
      description: undefined,
    },
    onSubmit: values => {
      props.onSubmit(values.name, values.description);
    },
    validationSchema: shortlistSchema,
    enableReinitialize: true,
  });

  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>Create a new list</Title>
      <Content>
        <FormRow>
          <FormikTextField
            formikProps={formik}
            name="name"
            label="Name"
            placeholder="Name your collection"
            autoFocus
            onFocus={e => {
              e.target.select();
            }}
          />
        </FormRow>
        <FormRow>
          <FormikTextField
            formikProps={formik}
            name="description"
            label="Description"
            multiline
            minRows={3}
            maxRows={5}
            placeholder="What is your collection about?"
          />
        </FormRow>
      </Content>
      <DialogActions>
        <Button
          onClick={() => formik.handleSubmit()}
          kind="primary"
          isLoading={props.isSaving}
          disabled={props.isSaving}
        >
          Create
        </Button>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ShortlistShareDialog(props: ShortlistShareDialogProps) {
  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>{props.isPublic ? 'Set collection to private' : 'Share link'}</Title>
      <Content>
        {props.isPublic ? (
          <p>If you make this list private, it will no longer be accessible through your previously shared links.</p>
        ) : (
          <p>
            Click the button below to make the list public and copy its link to the clipboard. Anyone with the link will
            be able to view your collection.
          </p>
        )}
      </Content>
      <DialogActions>
        <Button onClick={props.onSubmit} kind="primary" isLoading={props.isSaving} disabled={props.isSaving}>
          {props.isPublic ? 'Make private' : 'Copy link'}
        </Button>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ShortlistSettingsDialog(props: ShortlistSettingsDialogProps) {
  const { shortlist } = props;

  const formik = useFormik({
    initialValues: {
      name: shortlist.name,
      description: shortlist.description,
    },
    onSubmit: values => {
      props.onSubmit({ ...shortlist, ...values });
    },
    validationSchema: shortlistSchema,
    enableReinitialize: false,
  });

  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>List settings</Title>
      <Content>
        <QuickEditField
          className="u-content-spacing-bottom"
          label="Name"
          name="name"
          placeholder="Name your list"
          onChange={e => {
            formik.setFieldValue('name', e.target.value);
          }}
          onCancel={() => formik.setFieldValue('name', shortlist.name)}
          value={formik.values.name}
          originalValue={shortlist.name}
          onConfirm={(name: string) => props.onSubmit({ ...shortlist, name })}
          error={formik.errors.name}
        />
        <QuickEditField
          label="Description"
          name="description"
          placeholder="What is your collection about?"
          onChange={e => {
            formik.setFieldValue('description', e.target.value);
          }}
          onCancel={() => formik.setFieldValue('description', shortlist.description)}
          value={formik.values.description}
          originalValue={shortlist.description}
          onConfirm={(description: string) => props.onSubmit({ ...shortlist, description })}
          maxLength={SHORTLIST_DESCRIPTION_MAX_LENGTH}
          minRows={3}
          maxRows={5}
          error={formik.errors.description}
        />
        <div className="u-flex u-flex--column">
          <Label className="u-content-spacing-top">Share settings</Label>
          <div className="u-flex--wrap u-flex--gap-half u-flex-space-between u-flex-align-center u-half-spacing-bottom">
            <Switch
              className="u-quarter-spacing-left "
              label={!shortlist.isPublic ? 'Private' : 'Public'}
              value={shortlist.isPublic}
              onChange={() => props.onSubmit({ ...shortlist, isPublic: !shortlist.isPublic })}
            />
            {shortlist.isPublic && (
              <p className="text-metadata u-half-spacing-left">Public lists are viewable by anyone with the link</p>
            )}
          </div>
          <div>
            <CopyButton
              disabled={!shortlist.isPublic}
              kind="tertiary"
              copyString={`${location.origin}${userUrls.collections.view(shortlist.id)}`}
              displayString="Copy link"
            />
          </div>
        </div>
        <Button className="u-section-spacing-top" kind="tertiary" color="red" onClick={props.onDelete}>
          <TrashIcon fontSize="small" className="u-quarter-spacing-right" />
          Delete collection
        </Button>
      </Content>
      <DialogActions>
        <Button onClick={props.dialogHandler.close} kind="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ShortlistDeleteDialog(props: ShortlistDeleteDialogProps) {
  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>{props.title}</Title>
      <Content>
        <p>{props.content}</p>
      </Content>
      <DialogActions>
        <Button
          onClick={props.onConfirm}
          kind="primary"
          color="red"
          isLoading={props.isSaving}
          disabled={props.isSaving}
        >
          Delete
        </Button>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ShortlistDuplicateDialog(props: ShortlistDuplicateDialogProps) {
  const formik = useFormik({
    initialValues: {
      name: props.shortlistName,
      description: props.description,
    },
    onSubmit: values => {
      props.onSubmit(values.name, values.description);
    },
    validationSchema: shortlistSchema,
    enableReinitialize: true,
  });

  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>The list is saved!</Title>
      <Content>
        <p className="text-weight-bold u-section-spacing-bottom">Add your own name and description?</p>
        <FormRow>
          <FormikTextField
            formikProps={formik}
            name="name"
            label="Name"
            placeholder="Name your collection"
            autoFocus
            onFocus={e => {
              e.target.select();
            }}
          />
        </FormRow>
        <FormRow>
          <FormikTextField
            formikProps={formik}
            name="description"
            label="Description"
            multiline
            minRows={3}
            maxRows={5}
            placeholder="What is your collection about?"
          />
        </FormRow>
      </Content>
      <DialogActions>
        <Button
          onClick={() => formik.handleSubmit()}
          kind="primary"
          isLoading={props.isSaving}
          disabled={props.isSaving}
        >
          Update
        </Button>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          No, thanks
        </Button>
      </DialogActions>
    </Dialog>
  );
}
