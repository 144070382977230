import { FormikProps, useFormik } from 'formik';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import currencyMap, { DEFAULT_CURRENCY, getCurrencySymbol } from 'util/currency';
import React, { useState } from 'react';
import { CloseFundingRoundDTO, FullFundingRound } from 'types/company';
import Button from 'ui/elements/buttons/Button';
import FormRow from 'ui/elements/form/FormRow';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import * as Yup from 'yup';
import styles from '../styles.scss';
import CurrencyInput from 'ui/elements/form/numbers/CurrencyInput';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import { formatThousands } from 'util/numbers';
import ChevronUpIcon from 'ui/elements/icons/ChevronUpIcon';
import { capitalize } from 'util/stringUtils';
import { format } from 'date-fns';
import Select from 'ui/elements/form/Select/Select';
import { fundingRoundTypes } from './CompanyFundingRound';
import DatePicker from 'ui/elements/form/DatePicker';
import { DATE_FORMAT } from 'util/constants';
import TextField from 'ui/elements/form/TextField';
import CalendarIcon from 'ui/elements/icons/CalendarIcon';
import cx from 'classnames';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import CompanyAPI from 'apis/CompanyAPI';
import { getOrUndefined } from 'util/resource';

interface Props {
  fundingRound: FullFundingRound;
  dialogHandler: DialogHandlerProps;
  onCloseFundingRound: (id: UUID, fundingRound: CloseFundingRoundDTO, shouldUpdateValuation?: boolean) => void;
  isSaving: boolean;
}

interface StartProps {
  onYes: () => void;
  onNo: () => void;
  onCancel: () => void;
}

interface HasNotRaisedProps {
  onYes: () => void;
  onNo: () => void;
  onCancel: () => void;
  isSaving: boolean;
}

type HasRaisedFormikFields = {
  capitalRaised: number | undefined;
  capitalRaisedCurrency: string;
  postMoneyValuation: number | undefined;
  postMoneyValuationCurrency: string;
  isValuationUpdateChecked: boolean;
};

interface HasRaisedProps<T extends HasRaisedFormikFields> {
  onCloseFundingRound: () => void;
  onCancel: () => void;
  isSaving: boolean;
  formik: {
    values: FormikProps<T>['values'];
    touched: FormikProps<T>['touched'];
    errors: FormikProps<T>['errors'];
    setFieldValue: FormikProps<T>['setFieldValue'];
  };
}

export const closureSchema = Yup.object().shape({
  isValuationUpdateChecked: Yup.boolean(),
  capitalRaised: Yup.number()
    .nullable()
    .notRequired()
    .lessThan(1000000000000000, 'Capital raised must be less than 1 000 000 000 000 000'),
  capitalRaisedCurrency: Yup.string().oneOf(Object.keys(currencyMap)),
  postMoneyValuation: Yup.number()
    .nullable()
    .lessThan(1000000000000000, 'Post money valuation must be less than 1 000 000 000 000 000')
    .when('isValuationUpdateChecked', ([isValuationUpdateChecked], schema) =>
      isValuationUpdateChecked ? schema.nullable().required('Required when valuation update is checked') : schema,
    ),
  postMoneyValuationCurrency: Yup.string().oneOf(Object.keys(currencyMap)),
  capitalSeeking: Yup.number()
    .required('* required field')
    .lessThan(1000000000000000, 'Capital seeking must be less than 1 000 000 000 000 000'),
  capitalSeekingCurrency: Yup.string().oneOf(Object.keys(currencyMap)),
  preMoneyValuation: Yup.number()
    .nullable()
    .required('* required field')
    .lessThan(1000000000000000, 'Pre-money valuation must be less than 1 000 000 000 000 000'),
  preMoneyValuationCurrency: Yup.string().oneOf(Object.keys(currencyMap)),
  fundingStage: Yup.object().required(),
  type: Yup.string().required('* required field'),
  plannedClosingDate: Yup.string().required('* required field'),
});

type ClosureStage = 'start' | 'has-not-raised' | 'has-raised';

function StartContent(props: StartProps) {
  return (
    <>
      <Content>
        <p className="u-half-spacing-bottom">Did you raise capital during the funding round?</p>
      </Content>
      <DialogActions>
        <Button onClick={props.onYes} kind="secondary">
          Yes
        </Button>
        <Button onClick={props.onNo} kind="secondary">
          No
        </Button>
        <Button onClick={props.onCancel} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}

function HasNotRaisedContent(props: HasNotRaisedProps) {
  return (
    <>
      <Content>
        <p className="u-half-spacing-bottom">Do you still want to close the funding round?</p>
      </Content>
      <DialogActions>
        <Button isLoading={props.isSaving} style={{ width: '110px' }} onClick={props.onYes} kind="primary">
          Yes, close
        </Button>
        <Button style={{ width: '110px' }} onClick={props.onNo} kind="secondary">
          Edit details
        </Button>
        <Button onClick={props.onCancel} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}

function HasRaisedContent(props: HasRaisedProps<HasRaisedFormikFields>) {
  return (
    <>
      <Content>
        <p className="u-content-spacing-bottom">How much capital did you raise?</p>
        <FormRow className={styles.formRow}>
          <div className="u-fullWidth">
            <CurrencyInput
              name="capitalRaised"
              value={props.formik.values.capitalRaised}
              error={props.formik.touched.capitalRaised ? props.formik.errors.capitalRaised : undefined}
              onChange={v => props.formik.setFieldValue('capitalRaised', v)}
              label="Capital raised"
              placeholder="10 000 000"
              currency={props.formik.values.capitalRaisedCurrency}
              onCurrencyChange={v => props.formik.setFieldValue('capitalRaisedCurrency', v)}
              touched={props.formik.touched.capitalRaised}
              nullable
            />
          </div>
        </FormRow>
        <FormRow className={styles.formRow}>
          <div className="u-fullWidth">
            <CurrencyInput
              name="postMoneyValuation"
              value={props.formik.values.postMoneyValuation}
              error={props.formik.touched.postMoneyValuation ? props.formik.errors.postMoneyValuation : undefined}
              onChange={v => props.formik.setFieldValue('postMoneyValuation', v)}
              label="Post-money valuation"
              placeholder="10 000 000"
              currency={props.formik.values.postMoneyValuationCurrency}
              onCurrencyChange={v => props.formik.setFieldValue('postMoneyValuationCurrency', v)}
              touched={props.formik.touched.postMoneyValuation}
              nullable
            />
          </div>
        </FormRow>
        <div className="u-content-spacing-top">
          <Checkbox
            color="primary"
            helperText="Valuation is only visible to company admins, board members and shareholders"
            checked={props.formik.values.isValuationUpdateChecked}
            label="Update company profile with new valuation"
            onChange={() => {
              props.formik.setFieldValue('isValuationUpdateChecked', !props.formik.values.isValuationUpdateChecked);
            }}
          />
        </div>
      </Content>
      <DialogActions>
        <Button
          className="u-section-padding-x"
          onClick={props.onCloseFundingRound}
          kind="primary"
          isLoading={props.isSaving}
        >
          Close & confirm
        </Button>
        <Button onClick={props.onCancel} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}

export default function CloseFundingRoundDialog(props: Props) {
  const [stage, setStage] = useState<ClosureStage>('start');
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const [shouldHighlightDetails, setShouldHighlightDetails] = useState(false);
  const [isCapitalSeekingActive, setCapitalSeekingActive] = useState(false);
  const [isPreMoneyValuationActive, setPreMoneyValuationActive] = useState(false);
  const [isFundingStageActive, setFundingStageActive] = useState(false);
  const [isTypeActive, setTypeActive] = useState(false);
  const [isClosingDateActive, setClosingDateActive] = useState(false);

  const [fundingStageOptionsResource] = useResourceLegacy(CompanyAPI.fundingStageLabels.get, []);

  const fundingStageOptions =
    getOrUndefined(fundingStageOptionsResource)?.values?.map(s => ({ ...s, value: s.id })) ?? [];

  const formik = useFormik({
    initialValues: {
      capitalRaised: props.fundingRound.capitalRaised?.value,
      capitalRaisedCurrency: props.fundingRound.capitalRaised?.currency ?? DEFAULT_CURRENCY,
      postMoneyValuation: props.fundingRound.postMoneyValuation?.value ?? undefined,
      postMoneyValuationCurrency: props.fundingRound.postMoneyValuation?.currency ?? DEFAULT_CURRENCY,
      capitalSeeking: props.fundingRound.capitalSeeking.value,
      capitalSeekingCurrency: props.fundingRound.capitalSeeking.currency,
      preMoneyValuation: props.fundingRound.preMoneyValuation.value,
      preMoneyValuationCurrency: props.fundingRound.preMoneyValuation.currency,
      fundingStage: props.fundingRound.fundingStage,
      type: props.fundingRound.type,
      plannedClosingDate: props.fundingRound.plannedClosingDate,
      isValuationUpdateChecked: false,
    },
    onSubmit: values => {
      props.onCloseFundingRound(
        props.fundingRound.id,
        {
          capitalSeeking: values.capitalSeeking ?? 0,
          capitalSeekingCurrency: values.capitalSeekingCurrency ?? DEFAULT_CURRENCY,
          preMoneyValuation: values.preMoneyValuation ?? 0,
          preMoneyValuationCurrency: values.preMoneyValuationCurrency ?? DEFAULT_CURRENCY,
          capitalRaised: values.capitalRaised,
          capitalRaisedCurrency: values.capitalRaised ? values.capitalRaisedCurrency : undefined,
          postMoneyValuation: values.postMoneyValuation,
          postMoneyValuationCurrency: values.postMoneyValuation ? values.postMoneyValuationCurrency : undefined,
          type: values.type ?? 'Other',
          plannedClosingDate: values.plannedClosingDate,
          fundingStage: values.fundingStage?.id,
        },
        values.isValuationUpdateChecked,
      );
    },
    validationSchema: closureSchema,
    enableReinitialize: false,
  });

  function handleSelectDate(date: Date): void {
    const newDate = new Date(date);
    if (new Date(newDate).setHours(0, 0, 0, 0) != new Date().setHours(0, 0, 0, 0)) {
      newDate.setHours(23);
      newDate.setMinutes(59);
      newDate.setSeconds(59);
    }
    formik.setFieldValue('plannedClosingDate', newDate.toISOString());
  }

  function closeDialog() {
    props.dialogHandler.close();
    setTimeout(() => {
      setDetailsVisible(false);
      setStage('start');
      setCapitalSeekingActive(false);
      setPreMoneyValuationActive(false);
      setFundingStageActive(false);
      setTypeActive(false);
      setClosingDateActive(false);
    }, 200);
  }

  function handleEditDetails() {
    if (isDetailsVisible) {
      setShouldHighlightDetails(true);
      setTimeout(() => {
        setShouldHighlightDetails(false);
      }, 200);
    } else {
      setDetailsVisible(true);
    }
  }

  return (
    <Dialog onClose={closeDialog} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={closeDialog}>Close funding round</Title>
      {stage === 'start' && (
        <StartContent
          onYes={() => setStage('has-raised')}
          onNo={() => setStage('has-not-raised')}
          onCancel={closeDialog}
        />
      )}
      {stage === 'has-not-raised' && (
        <HasNotRaisedContent
          onYes={formik.handleSubmit}
          onNo={handleEditDetails}
          onCancel={closeDialog}
          isSaving={props.isSaving}
        />
      )}
      {stage === 'has-raised' && (
        <HasRaisedContent
          onCloseFundingRound={formik.handleSubmit}
          onCancel={closeDialog}
          formik={formik}
          isSaving={props.isSaving}
        />
      )}
      <Content
        paddingY="bottom"
        className={cx(styles.closeRoundDetails, {
          [styles.highlighted]: shouldHighlightDetails,
        })}
      >
        <button className="u-flex-align-center text-metadata" onClick={() => setDetailsVisible(!isDetailsVisible)}>
          {!isDetailsVisible ? (
            <>
              <span>Show details</span>
              <ChevronDownIcon className="u-quarter-spacing-left" fontSize="small" strokeWidth={2} />
            </>
          ) : (
            <>
              <span>Hide details</span>
              <ChevronUpIcon className="u-quarter-spacing-left" fontSize="small" strokeWidth={2} />
            </>
          )}
        </button>

        {isDetailsVisible && (
          <div className="u-section-spacing-top">
            <div className="u-flex u-flex--column u-flex--gap-1">
              <div>
                <div className="text-subheading-2">Capital seeking</div>
                <div className={styles.closingDetailField}>
                  {!isCapitalSeekingActive ? (
                    <>
                      <span>
                        {getCurrencySymbol(formik.values.capitalSeekingCurrency)}{' '}
                        {formatThousands(formik.values.capitalSeeking)}
                      </span>
                      <button className="text-link text-small" onClick={() => setCapitalSeekingActive(true)}>
                        Change
                      </button>
                    </>
                  ) : (
                    <FormRow className={styles.formRow}>
                      <div className="u-fullWidth u-half-spacing-bottom">
                        <CurrencyInput
                          name="capitalSeeking"
                          value={formik.values.capitalSeeking}
                          error={formik.touched.capitalSeeking ? formik.errors.capitalSeeking : undefined}
                          onChange={v => formik.setFieldValue('capitalSeeking', v)}
                          placeholder="10 000 000"
                          currency={formik.values.capitalSeekingCurrency ?? DEFAULT_CURRENCY}
                          onCurrencyChange={v => formik.setFieldValue('capitalSeekingCurrency', v)}
                          touched={formik.touched.capitalSeeking}
                        />
                      </div>
                    </FormRow>
                  )}
                </div>
              </div>
              <div>
                <div className="text-subheading-2">Pre-money valuation</div>
                <div className={styles.closingDetailField}>
                  {!isPreMoneyValuationActive ? (
                    <>
                      <span>
                        {getCurrencySymbol(formik.values.preMoneyValuationCurrency)}{' '}
                        {formatThousands(formik.values.preMoneyValuation)}
                      </span>
                      <button className="text-link text-small" onClick={() => setPreMoneyValuationActive(true)}>
                        Change
                      </button>
                    </>
                  ) : (
                    <FormRow className={styles.formRow}>
                      <div className="u-fullWidth u-half-spacing-bottom">
                        <CurrencyInput
                          name="preMoneyValuation"
                          value={formik.values.preMoneyValuation}
                          error={formik.touched.preMoneyValuation ? formik.errors.preMoneyValuation : undefined}
                          onChange={v => formik.setFieldValue('preMoneyValuation', v)}
                          placeholder="10 000 000"
                          currency={formik.values.preMoneyValuationCurrency ?? DEFAULT_CURRENCY}
                          onCurrencyChange={v => formik.setFieldValue('preMoneyValuationCurrency', v)}
                          touched={formik.touched.preMoneyValuation}
                        />
                      </div>
                    </FormRow>
                  )}
                </div>
              </div>
              <div>
                <div className="text-subheading-2">Funding stage</div>
                <div className={styles.closingDetailField}>
                  {!isFundingStageActive ? (
                    <>
                      <span>{formik.values.fundingStage?.name}</span>
                      <button className="text-link text-small" onClick={() => setFundingStageActive(true)}>
                        Change
                      </button>
                    </>
                  ) : (
                    <FormRow className={styles.formRow}>
                      <div className="u-fullWidth">
                        <Select
                          value={formik.values.fundingStage}
                          placeholder="Select current funding stage"
                          onChange={label => (label ? formik.setFieldValue('fundingStage', label) : undefined)}
                          getOptionLabel={i => i.name}
                          options={fundingStageOptions}
                          error={formik.touched.fundingStage && !!formik.errors.fundingStage}
                          helperText={formik.touched.fundingStage ? formik.errors.fundingStage : undefined}
                        />
                      </div>
                    </FormRow>
                  )}
                </div>
              </div>
              <div>
                <div className="text-subheading-2">Type</div>
                <div className={styles.closingDetailField}>
                  {!isTypeActive ? (
                    <>
                      <span>{capitalize(formik.values.type)}</span>
                      <button className="text-link text-small" onClick={() => setTypeActive(true)}>
                        Change
                      </button>
                    </>
                  ) : (
                    <FormRow className={styles.formRow}>
                      <div className="u-fullWidth">
                        <Select
                          name="type"
                          placeholder="Select type"
                          value={formik.values.type}
                          onChange={type => formik.setFieldValue('type', type)}
                          getOptionLabel={type => type.charAt(0).toUpperCase() + type.slice(1)}
                          options={fundingRoundTypes}
                          error={formik.touched.type && !!formik.errors.type}
                          helperText={formik.touched.type ? formik.errors.type : undefined}
                          isClearable={false}
                        />
                      </div>
                    </FormRow>
                  )}
                </div>
              </div>
              <div>
                <div className="text-subheading-2">Closing date</div>
                <div className={styles.closingDetailField}>
                  {!isClosingDateActive ? (
                    <>
                      <span>{format(new Date(formik.values.plannedClosingDate), 'dd/MM/yyyy')}</span>
                      <button className="text-link text-small" onClick={() => setClosingDateActive(true)}>
                        Change
                      </button>
                    </>
                  ) : (
                    <FormRow className={styles.formRow}>
                      <div className="u-fullWidth">
                        <DatePicker
                          name="plannedClosingDate"
                          dateFormat={DATE_FORMAT}
                          selected={
                            formik.values.plannedClosingDate ? new Date(formik.values.plannedClosingDate) : null
                          }
                          onChange={() => {
                            return formik.handleChange('plannedClosingDate');
                          }}
                          onSelect={handleSelectDate}
                          onBlur={formik.handleBlur}
                          customInput={
                            <TextField
                              error={formik.touched.plannedClosingDate && !!formik.errors.plannedClosingDate}
                              helperText={
                                formik.touched.plannedClosingDate ? formik.errors.plannedClosingDate : undefined
                              }
                              suffix={<CalendarIcon />}
                            />
                          }
                          minDate={new Date(props.fundingRound.openingDate)}
                          maxDate={new Date(props.fundingRound.plannedClosingDate)}
                        />
                      </div>
                    </FormRow>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Content>
    </Dialog>
  );
}
