import { useAuth0 } from '@auth0/auth0-react';
import { LoadingScreen } from 'auth/AuthenticatedRoutes';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { OrganizationContainer } from 'pages/OrganizationContainer';
import OrganizationPage from 'pages/Organization';
import { Organization } from 'types/organization';
import NotFound from 'pages/common/error/NotFoundPage';
import { RouteProps } from 'react-router';
import { OrganizationManagePage } from 'pages/Organization/Manage';
import { OrganizationSettingsPage } from 'pages/Organization/Settings';
import { ErrorBoundary } from '@sentry/react';

interface AdminOrganizationRouteProps extends RouteProps {
  organization: Organization;
}

const AdminOrganizationRoute = (props: AdminOrganizationRouteProps) => {
  if (!props.organization.isAdmin) {
    return <NotFound />;
  }

  return <Route {...props} />;
};

type OrganizationRoutesProps = RouteComponentProps<{ slug: string }>;

export const OrganizationRoutes = ({ match }: OrganizationRoutesProps) => {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <OrganizationContainer slug={match.params.slug}>
      {organization => (
        <ErrorBoundary>
          <Switch>
            <AdminOrganizationRoute
              exact
              organization={organization}
              path={`${match.path}/manage`}
              render={() => <OrganizationManagePage organization={organization} />}
            />
            <AdminOrganizationRoute
              organization={organization}
              path={`${match.path}/settings`}
              render={() => <OrganizationSettingsPage organization={organization} />}
            />
            <Route exact path={match.path} render={() => <OrganizationPage organization={organization} />} />
            <Route component={NotFound} />
          </Switch>
        </ErrorBoundary>
      )}
    </OrganizationContainer>
  );
};
