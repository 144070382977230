import { Organization } from 'types/organization';
import React, { useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import ScrollNavigation from 'ui/modules/ScrollNavigation';
import Accordion from 'ui/modules/Accordion';
import uniq from 'ramda/src/uniq';
import { toggleItemInList } from 'util/arrayUtils';
import { GeneralInformationSettings } from 'pages/Organization/Settings/BasicInformation/GeneralInformationSettings';
import { ServicesSettings } from 'pages/Organization/Settings/BasicInformation/ServicesSettings';
import { LogoAndBannerSettings } from 'pages/Organization/Settings/BasicInformation/LogoAndBannerSettings';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import { EditableOrganizationProfile } from 'pages/Organization/index';
import { useFormik } from 'formik';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';
import useNotify from 'hooks/useNotify';
import * as Yup from 'yup';
import { validWebsiteRegex } from 'util/validation';

interface OrganizationBasicInformationProps {
  organization: Organization;
}

export function OrganizationBasicInformation({ organization }: OrganizationBasicInformationProps) {
  const [expandedSections, setExpandedSections] = useState<string[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [success, setSuccess] = useState(false);
  const notify = useNotify();
  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(organization, {
    onSuccess: () => {
      setSuccess(true);
      notify('success', 'Settings saved');
    },
  });

  const formikServices = useFormik({
    initialValues: {
      services: organization.services,
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const formikGeneralInformation = useFormik({
    initialValues: {
      name: organization.name,
      description: organization.description,
      website: organization.website,
      location: organization.location,
      established: organization.established,
      // We only get the name of the industry and types, but the update method needs the id, therefore, we map it to the
      // Industry and OrganizationType-types, with some fake data, and when the value is updated, we set the correct Ids.
      // Then, in update we map it to the id, and if any objects has id of -1 or id equal name, we don't add them to the
      // update request, as we know they have not changed
      industries: organization.industries.map(industry => ({
        id: -1,
        name: industry,
        tags: [],
      })),
      types: organization.types.map(type => ({
        id: type,
        name: type,
        key: type,
      })),
    },
    validationSchema: Yup.object().shape({
      website: Yup.string()
        .notRequired()
        .matches(validWebsiteRegex, 'Website should be a valid url, e.g. https://example.com')
        .nullable(),
    }),
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const formikLogoAndBanner = useFormik({
    initialValues: {
      logoUrl: organization.logoUrl,
      bannerUrl: organization.bannerUrl,
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const formik = useFormik({
    initialValues: {
      ...formikGeneralInformation.values,
      ...formikLogoAndBanner.values,
      ...formikServices.values,
    },
    onSubmit: values => {
      updateOrganization({
        ...values,
        industries: values.industries.some(i => i.id === -1) ? undefined : values.industries.map(i => i.id),
        services: values.services.map(s => s.id),
        types: values.types.some(t => t.id === t.name) ? undefined : values.types.map(t => t.id),
      });
    },
    enableReinitialize: true,
  });

  return (
    <>
      <ScrollNavigation
        onMenuItemClick={(id: string) => setExpandedSections(uniq([...expandedSections, id]))}
        additionalMenuItems={
          <>
            <Button
              kind="primary"
              className="u-half-spacing-bottom"
              onClick={() => {
                formikGeneralInformation.validateForm().then(validationErrors => {
                  if ((validationErrors.website?.length ?? 0) === 0) {
                    formik.handleSubmit();
                  } else {
                    notify('error', 'Website should be a valid url, e.g. https://example.com');
                  }
                });
              }}
              isLoading={isUpdatingOrganization}
              isSuccess={success}
              setSuccess={(isSuccess: boolean) => setSuccess(isSuccess)}
              disabled={isUpdatingOrganization || success}
            >
              Save
            </Button>
            <Button className="u-ellipsis" kind="tertiary" color="grey" onClick={() => setShowPreview(true)}>
              <EyeIcon className="u-quarter-spacing-right" /> Preview profile
            </Button>
          </>
        }
        sections={[
          {
            key: 'general-information',
            name: 'General information',
            element: (
              <Accordion
                title="General information"
                isExpanded={expandedSections.includes('general-information')}
                showPill
                border="none"
                onChange={() => setExpandedSections(toggleItemInList(expandedSections, 'general-information'))}
              >
                <GeneralInformationSettings
                  organization={organization}
                  onShowPreview={() => setShowPreview(true)}
                  formik={formikGeneralInformation}
                />
              </Accordion>
            ),
          },
          {
            key: 'logo-and-banner',
            name: 'Logo & banner',
            element: (
              <Accordion
                title="Logo & banner"
                isExpanded={expandedSections.includes('logo-and-banner')}
                showPill
                className="u-content-spacing-top"
                border="none"
                onChange={() => setExpandedSections(toggleItemInList(expandedSections, 'logo-and-banner'))}
              >
                <LogoAndBannerSettings
                  organization={organization}
                  onShowPreview={() => setShowPreview(true)}
                  formik={formikLogoAndBanner}
                />
              </Accordion>
            ),
          },
          {
            key: 'services',
            name: 'Services',
            element: (
              <Accordion
                title="Services"
                isExpanded={expandedSections.includes('services')}
                showPill
                className="u-content-spacing-top"
                border="none"
                onChange={() => setExpandedSections(toggleItemInList(expandedSections, 'services'))}
              >
                <ServicesSettings
                  organization={organization}
                  onShowPreview={() => setShowPreview(true)}
                  formik={formikServices}
                />
              </Accordion>
            ),
          },
        ]}
      />
      <CenteredModalDialog open={showPreview} onClose={() => setShowPreview(false)}>
        <MobileMargin>
          <EditableOrganizationProfile
            organization={{
              contacts: organization.contacts,
              team: organization.team,
              events: organization.events,
              id: organization.id,
              slug: organization.slug,
              isAdmin: false,
              ...formik.values,
              industries: formik.values.industries.map(i => i.name),
              types: formik.values.types.map(t => t.name),
            }}
            isModal={true}
          />
        </MobileMargin>
      </CenteredModalDialog>
    </>
  );
}
