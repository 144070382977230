import ApiBase from 'apis';
import config from 'config';
import { BoardroomUpdateDTO } from 'pages/Boardroom/Updates/types';
import { RoomUpdateDTO } from 'pages/Rooms/Updates/types';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { CommunityUpdate, CompanyUpdate } from 'types/content';
import usePaginatedResourceLegacy from 'util/resource/usePaginatedResourceLegacy';

export type UserFeedData =
  | {
      type: 'boardroom';
      boardroomUpdate: BoardroomUpdateDTO;
    }
  | {
      type: 'room';
      roomUpdate: RoomUpdateDTO;
    }
  | {
      type: 'company';
      companyUpdate: CompanyUpdate;
    }
  | {
      type: 'community';
      communityUpdate: CommunityUpdate;
    };

export const useUserFeed = (
  startDate: Date,
  additionalFilters?: {
    endDate?: Date;
    audience?: 'following' | 'shortlisted' | 'all' ;
    sortBy?: string;
  },
  paginationOptions?: PaginationOptions,
) => {
  const link = `${config.CONTENT_API_URL}/users/me/feed`;

  const api = ApiBase();
  return usePaginatedResourceLegacy(
    pagOpts =>
      api.get<PaginatedResult<UserFeedData>>(link, {
        requestParams: {
          ...(pagOpts || paginationOptions),
          audience: additionalFilters?.audience,
          startTime: startDate,
          endTime: additionalFilters?.endDate,
          sortBy: additionalFilters?.sortBy,
        },
      }),
    [JSON.stringify({ ...additionalFilters, startDate })],
  );
};
