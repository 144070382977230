import Label from 'ui/elements/form/Label';
import ImageUpload from 'ui/modules/ImageUpload';
import { resize } from 'util/cloudinary';
import Placeholder from 'ui/modules/ImageUpload/Placeholder';
import styles from 'domain/shared/Branding/styles.scss';
import PhotoIcon from 'ui/elements/icons/ImageIcon';
import Button from 'ui/elements/buttons/Button';
import {
  BANNER_IMAGE_ASPECT_RATIO,
  BANNER_IMAGE_SUGGESTED_MIN_HEIGHT,
  BANNER_IMAGE_SUGGESTED_MIN_WIDTH,
} from 'domain/shared/Branding/BrandingForm';
import React from 'react';
import { ContentAPI } from 'apis/ContentAPI';
import config from 'config';
import useNotify from 'hooks/useNotify';

interface OrganizationEditLogoAndBannerProps {
  organizationId: string;
  logoUrl?: string;
  bannerUrl?: string;
  onLogoUploadSuccess?: (imageUrl: string) => void;
  onBannerUploadSuccess?: (imageUrl: string) => void;
}

export default function OrganizationEditLogoAndBanner(props: OrganizationEditLogoAndBannerProps) {
  const api = ContentAPI(`${config.CONTENT_API_URL}`);
  const notify = useNotify();

  return (
    <section>
      <Label>Logo</Label>
      <div>
        <ImageUpload
          width={120}
          aspectRatio={1}
          onUploadSuccess={image => props.onLogoUploadSuccess?.(image.secure_url)}
          onUploadFailure={() => {
            notify('error', 'Upload failed. Only jpeg & png images are allowed.');
          }}
          getUploadUrl={filename => api.images.getOrganizationUploadUrl(props.organizationId, filename)}
          imageUrl={resize(props.logoUrl, { width: 500, height: 500 })}
          placeholder={
            <Placeholder shape="round">
              <div className={styles.logoPlaceholder}>
                <PhotoIcon fontSize="large" />
                Drop .png or .jpeg logo here
              </div>
            </Placeholder>
          }
          shape="round"
        >
          {openDropzone => (
            <div>
              <Button kind="secondary" onClick={openDropzone} className="u-content-spacing-bottom">
                <span>Upload image</span>
              </Button>
              <p className="text-metadata-regular">
                To upload or change your logo, click to select one or drag your .png or .jpeg file onto the circle.
              </p>
            </div>
          )}
        </ImageUpload>
      </div>
      <div className="u-section-spacing-top">
        <Label>Banner image</Label>
        <ImageUpload
          imageUrl={props.bannerUrl}
          aspectRatio={BANNER_IMAGE_ASPECT_RATIO}
          onUploadSuccess={image => props.onBannerUploadSuccess?.(image.secure_url)}
          onUploadFailure={() => {
            notify('error', 'Upload failed. Only jpeg & png images are allowed.');
          }}
          getUploadUrl={filename => api.images.getOrganizationUploadUrl(props.organizationId, filename)}
          placeholder={<Placeholder>Drop your banner image here or click to select one</Placeholder>}
        >
          {openDropzone => (
            <div className={styles.bannerActions}>
              <Button kind="secondary" onClick={openDropzone} className="u-content-spacing-right">
                <span>Upload image</span>
              </Button>
              <p className="text-metadata-regular">
                For the best result use an image of at least {BANNER_IMAGE_SUGGESTED_MIN_WIDTH}x
                {BANNER_IMAGE_SUGGESTED_MIN_HEIGHT}px in size
              </p>
            </div>
          )}
        </ImageUpload>
      </div>
    </section>
  );
}
