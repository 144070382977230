import Dialog, { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import React from 'react';
import styles from './styles.scss';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import OrganizationEditLogoAndBanner from 'pages/Organization/shared/OrganizationEditLogoAndBanner';

export default function EditOrganizationImagesDialog({
  logoUrl,
  bannerUrl,
  onClose,
  organizationId,
  onLogoChanged,
  onBannerImageChanged,
}: {
  onClose: () => void;
  logoUrl?: string;
  bannerUrl?: string;
  organizationId: string;
  onLogoChanged: (b: string) => void;
  onBannerImageChanged: (b: string) => void;
}) {
  return (
    <Dialog open={true} onClose={onClose} backdropColor="light" maxWidth="md">
      <MobileMargin>
        <Content className={styles.logo}>
          <OrganizationEditLogoAndBanner
            organizationId={organizationId}
            logoUrl={logoUrl}
            bannerUrl={bannerUrl}
            onLogoUploadSuccess={onLogoChanged}
            onBannerUploadSuccess={onBannerImageChanged}
          />
        </Content>
        <DialogActions>
          <Button kind="primary" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </MobileMargin>
    </Dialog>
  );
}
