import EditIcon from 'ui/elements/icons/EditIcon';
import React, { useState } from 'react';
import { Update } from 'types/content';
import Chip from 'ui/elements/Chip';
import ArchiveIcon from 'ui/elements/icons/ArchiveIcon';
import UnarchiveIcon from 'ui/elements/icons/UnarchiveIcon';
import PinIcon from 'ui/elements/icons/PinIcon';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import useDialogHandler from 'hooks/useDialogHandler';
import Button from 'ui/elements/buttons/Button';
import { ICompany } from 'types/company';
import { CircularProgress, Fade } from '@mui/material';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import useFollowCompany from 'domain/companies/Follow/useFollowCompany';
import UserIcon from 'ui/elements/icons/UserIcon';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import GraphIcon from 'ui/elements/icons/GraphIcon';
import UpdateStatsDialog from '../UpdateStatsDialog';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import { Auth0User } from 'types';
import { useAuth0 } from '@auth0/auth0-react';
import { getAdminRights } from 'auth/util';
import { UpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import PopMenu from 'ui/modules/PopMenu';
import CompanyProfileDialog from 'domain/companies/ProfileDialog/CompanyProfileDialog';
import useBackoffice from 'hooks/useBackoffice';
import CheckmarkBoxIcon from 'ui/elements/icons/CheckmarkBoxIcon';
import UnfollowDialog from 'domain/companies/Follow/UnfollowDialog';
import MinusIcon from 'ui/elements/icons/MinusIcon';
import TimerIcon from 'ui/elements/icons/TimerIcon';
import PlusBoxIcon from 'ui/elements/icons/PlusBoxIcon';
import { bluePlanetTheme } from 'ui/theme';

interface ContainerProps {
  update: Update;
  company: ICompany;
  canViewProfile: boolean;
  api: UpdateAPI;
  authorId?: string;
  onEdit?: () => void;
  canEditPost?: boolean;
  onArchive?: (contentId: string) => void;
  onDelete?: () => void;
  setPinned: (isPinned: boolean) => void;
  isPinned: boolean;
  canPinToFeed?: boolean;
  trackingKey?: string;
}

export default function CompanyUpdateMenu(props: ContainerProps) {
  const { update, isPinned, setPinned } = props;
  const [isDeleting, setDeleting] = useState(false);

  const [isArchived, setArchived] = useState<boolean>(update.archived || false);
  const notify = useNotify();

  const pinDialogHandler = useDialogHandler();
  const deleteDialogHandler = useDialogHandler();

  const [togglePin] = useLazyResource((isPinned: boolean) => props.api.togglePin(props.update.id, isPinned), {
    onSuccess: () => {
      setPinned(!isPinned);
      notify('success', `Update has been ${isPinned ? 'pinned' : 'unpinned'} `);
    },
    onFailure: () => notify('error', `Could not ${isPinned ? 'pin' : 'unpin'} update. Please try again later.`),
  });
  const [onArchive] = useLazyResource(
    (isArchived: boolean) => (!isArchived ? props.api.archive(props.update.id) : props.api.unArchive(props.update.id)),
    {
      onSuccess: () => {
        setArchived(!isArchived);
        notify('success', `Update has been ${isArchived ? 'un' : ''}archived`);
      },
      onFailure: () => notify('error', `Could not ${isArchived ? 'un' : ''}archive update. Please try again later.`),
    },
  );

  const onDeleteContent = async () => {
    try {
      setDeleting(true);
      await props.api.delete(props.update.id);
      props.onDelete && props.onDelete();
      notify('success', 'Update deleted');
    } catch {
      setDeleting(false);
      notify('error', 'Failed to delete update');
    } finally {
      setDeleting(false);
      deleteDialogHandler.close();
    }
  };

  const [userDialogIsOpen, setUserDialogIsOpen] = useState(false);
  const [companyProfileDialogIsOpen, setCompanyProfileDialogIsOpen] = useState(false);
  const { user }: { user?: Auth0User } = useAuth0();
  const adminRights = getAdminRights(user);
  const backoffice = useBackoffice();
  const canAccessBackoffice = adminRights.includes('backoffice');
  const [updateStatsDialogIsOpen, setUpdateStatsDialogIsOpen] = useState(false);

  const [isHoveringPending, setHoveringPending] = useState(false);
  const unfollowDialogHandler = useDialogHandler();

  const {
    onFollow,
    onUnfollow,
    isUnfollowing,
    isCompanyRolesFetched,
    isFollowingCompany,
    isPendingFollowerRequest,
    isSendingFollowRequest,
    hasOtherRoleThanFollower,
    pendingStateIsDisabled,
    setPendingStateIsDisabled,
  } = useFollowCompany(props.company);

  if (!isCompanyRolesFetched) {
    return null;
  }

  const shouldRenderFollowMenuItem = !hasOtherRoleThanFollower;

  const shouldDisplayActions =
    shouldRenderFollowMenuItem || props.canViewProfile || props.authorId || props.canEditPost;

  const followMenuItem = isFollowingCompany
    ? {
        text: 'Following',
        icon: <CheckmarkBoxIcon strokeWidth={1} />,
        onClick: unfollowDialogHandler.open,
      }
    : isPendingFollowerRequest
      ? {
          onMouseEnter: () => setHoveringPending(true),
          onMouseLeave: () => {
            setPendingStateIsDisabled(false);
            setHoveringPending(false);
          },
          text: isHoveringPending && !pendingStateIsDisabled ? 'Withdraw' : 'Waiting for approval',
          icon: isHoveringPending ? <MinusIcon /> : <TimerIcon strokeWidth={1} />,
          onClick: unfollowDialogHandler.open,
        }
      : {
          text: 'Follow',
          icon: isSendingFollowRequest ? (
            <Fade in={isSendingFollowRequest} unmountOnExit exit={false}>
              <CircularProgress disableShrink size={17} color="inherit" />
            </Fade>
          ) : (
            <PlusBoxIcon />
          ),
          onClick: onFollow,
        };
  undefined;

  return shouldDisplayActions ? (
    <>
      {isArchived && <Chip color="orange-light" label="Archived" className="u-quarter-spacing-left" />}
      <PopMenu
        items={[
          {
            isVisible: shouldRenderFollowMenuItem,
            ...followMenuItem,
          },
          {
            isVisible: props.canViewProfile,
            text: 'View company profile',
            onClick: () => setCompanyProfileDialogIsOpen(true),
            icon: <EyeIcon />,
          },
          {
            isVisible: !!props.authorId,
            text: 'View author profile',
            onClick: () => setUserDialogIsOpen(true),
            icon: <UserIcon />,
          },
          {
            isVisible: props.canEditPost && props.onEdit,
            text: 'Edit',
            onClick: props.onEdit,
            icon: <EditIcon />,
          },
          {
            isVisible: props.canEditPost && props.canPinToFeed,
            text: isPinned ? 'Unpin' : 'Pin to top',
            icon: <PinIcon />,
            onClick: pinDialogHandler.open,
          },
          {
            isVisible: props.canEditPost,
            text: isArchived ? 'Unarchive' : 'Archive',
            icon: isArchived ? <UnarchiveIcon /> : <ArchiveIcon />,
            onClick: () => onArchive(isArchived),
          },

          {
            isVisible: backoffice.adminRights.includes('read-everything') && backoffice.showBackofficeInfoOnProfiles,
            text: 'View stats',
            icon: <GraphIcon />,

            onClick: () => setUpdateStatsDialogIsOpen(true),
            backgroundColor: bluePlanetTheme.bluePlanetPalette.yellow.medium,
          },
          {
            isVisible: props.onDelete && canAccessBackoffice,
            text: 'Delete',
            icon: <TrashIcon />,
            onClick: deleteDialogHandler.open,
          },
        ].filter(item => item.isVisible)}
      />
      <ConfirmDialog
        isOpen={pinDialogHandler.isOpen}
        onClose={pinDialogHandler.close}
        title={props.isPinned ? 'Unpin update' : 'Pin to top'}
        confirmButton={
          <Button kind="primary" onClick={() => togglePin(!isPinned)}>
            {props.isPinned ? 'Unpin' : 'Pin'}
          </Button>
        }
      >
        {props.isPinned
          ? 'The update will no longer appear at the top of the feed.'
          : 'The update will appear at the top of the feed. You can unpin it whenever you like.'}
      </ConfirmDialog>
      {userDialogIsOpen && props.authorId && (
        <UserProfileDialog cwUserId={props.authorId} onClose={() => setUserDialogIsOpen(false)} />
      )}

      {updateStatsDialogIsOpen && (
        <UpdateStatsDialog updateId={update.id} onClose={() => setUpdateStatsDialogIsOpen(false)} />
      )}
      {companyProfileDialogIsOpen && (
        <CompanyProfileDialog
          analyticsContext="community-updates"
          companyId={props.company.id}
          analyticsCommunityId="none"
          initialTab="profile"
          onClose={() => setCompanyProfileDialogIsOpen(false)}
        />
      )}

      <UnfollowDialog
        dialogHandler={unfollowDialogHandler}
        onUnfollow={onUnfollow}
        isUnfollowing={isUnfollowing}
        companyName={props.company.name}
        requestIsPending={isPendingFollowerRequest}
      />
      <ConfirmDialog
        isOpen={deleteDialogHandler.isOpen}
        onClose={deleteDialogHandler.close}
        title="You are about to delete this post"
        confirmButton={
          <Button color="red" kind="primary" onClick={onDeleteContent} isLoading={isDeleting}>
            Yes, delete
          </Button>
        }
        dismissLabel="No, cancel"
      >
        <p className="u-content-spacing-bottom">It will no longer be visible anywhere on the platform.</p>
        <p>Are you sure?</p>
      </ConfirmDialog>
    </>
  ) : null;
}
