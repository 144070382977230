import React from 'react';
import { EventDTO, getCalendarEvent } from 'ui/domain/Event/types';
import CalendarButton from 'ui/elements/buttons/CalendarButton';
import LocationIcon from 'ui/elements/icons/LocationIcon';
import { asTime, dateWithDayName } from 'util/dateUtils';
import Logo from 'ui/domain/Logo';
import styles from './styles.scss';
import { Link } from 'react-router-dom';
import CalendarDate from 'ui/domain/Event/CalendarDate';
import { organizationUrls } from 'urls';
import HtmlContent from 'ui/elements/HtmlContent';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

export function ShowEvent({ event, title }: { event: EventDTO; title?: string }) {
  return (
    <MobileMargin>
      <div className={styles.container}>
        <div className={styles.left}>
          {title && <div className="text-h3 u-content-spacing-bottom">{event.title}</div>}
          <div className="u-flex">
            <CalendarDate date={event.startsAt} />
            <div className="u-content-spacing-left text-metadata u-flex u-flex--column u-flex-center">
              <div>
                {dateWithDayName(event.startsAt)}{' '}
                {event.endsAt ? `${asTime(event.startsAt)} - ${asTime(event.endsAt)}` : `at ${asTime(event.startsAt)}`}
              </div>
              <div className="u-flex-align-center">
                Venue:
                {event.locationUrl ? (
                  <a
                    className="u-flex-align-center u-half-spacing-left"
                    href={event.locationUrl || '#'}
                    target={event.locationUrl ? '_blank' : '_self'}
                    rel="noreferrer"
                  >
                    <LocationIcon className="u-quarter-spacing-right" color="blue" fontSize="small" />
                    <span className={styles.eventLocation}>{event.location}</span>
                  </a>
                ) : (
                  <span>{event.location}</span>
                )}
              </div>
            </div>
          </div>
          <HtmlContent className={styles.eventContent}>{event.content}</HtmlContent>
          <CalendarButton
            className="u-section-spacing-bottom"
            event={getCalendarEvent(event)}
            trackingKey={'event-add-to-calendar'}
          />
        </div>
        {event.organizations && event.organizations.length > 0 && (
          <div className={styles.right}>
            <span className="text-metadata">Hosted by</span>
            {event.organizations.map(org => (
              <Link
                key={org.organization.id}
                to={organizationUrls.view(org.organization.slug)}
                className={`${styles.organization} u-flex u-flex-align-center u-half-spacing-y u-quarter-padding text-medium text-weight-medium`}
              >
                <Logo className="u-half-spacing-right" type="company" url={org.organization.logoUrl} size={40} />
                <span>{org.organization.name}</span>
              </Link>
            ))}
          </div>
        )}
      </div>
    </MobileMargin>
  );
}
