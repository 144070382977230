import React, { useState } from 'react';
import { DocumentDownloadDTO } from 'types/documents';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { SUPPORTED_DOCUMENTS_PREVIEW, SUPPORTED_IMAGES_PREVIEW } from 'util/constants';
import useDownloadWarning from 'domain/documents/useDownloadWarning';
import PitchDeckPDF from 'domain/companies/Documents/Document/PitchDeck/PitchDeckPDF';
import ImageDialog from 'domain/documents/ImageDialog';

export default function useDocumentPreviewOrDownload({
  filename,
  downloadDocumentPreview,
  downloadBlob,
}: {
  filename: string;
  downloadDocumentPreview: () => Promise<DocumentDownloadDTO>;
  downloadBlob?: () => any;
}) {
  const [documentDownloadDTO, setDocumentDownloadDTO] = useState<DocumentDownloadDTO | null>(null);

  const isPdf = SUPPORTED_DOCUMENTS_PREVIEW.some(type => filename.endsWith(type));
  const isImage = SUPPORTED_IMAGES_PREVIEW.some(type => filename.endsWith(type));

  const notify = useNotify();
  const [loadResource] = useLazyResource(downloadDocumentPreview, {
    onSuccess: result => {
      setDocumentDownloadDTO(result);
    },
    onFailure: () => notify('error', 'Something went wrong when trying to fetch document'),
  });

  const { onClick: onDownloadClick, WarningDialog } = useDownloadWarning(filename, downloadBlob);

  const showPreviewOnClick = isPdf || isImage;
  const showDownloadOnClick = !showPreviewOnClick && onDownloadClick && downloadBlob;

  const onClick = showPreviewOnClick ? () => loadResource('') : showDownloadOnClick ? onDownloadClick : undefined;

  const dialog = (
    <>
      {documentDownloadDTO !== null && isPdf && (
        <PitchDeckPDF
          pitchDeckPath={documentDownloadDTO.path}
          onClose={() => setDocumentDownloadDTO(null)}
          pageNumber={0}
          onPageChange={() => {}}
        />
      )}
      {documentDownloadDTO !== null && isImage && (
        <ImageDialog imagePath={documentDownloadDTO.path} onClose={() => setDocumentDownloadDTO(null)} />
      )}
      {WarningDialog}
    </>
  );

  return {
    onClick,
    dialog,
  };
}
