import React from 'react';
import Button from 'ui/elements/buttons/Button';
import { Grid } from '@mui/material';
import { UserProfile } from 'types/user';

import investmentsIcon from 'ui/illustrations/services/cash-payment.svg';
import bankingIcon from 'ui/illustrations/services/banking.svg';
import governmentalGrantsIcon from 'ui/illustrations/services/governmental-grants.svg';
import legalIcon from 'ui/illustrations/services/legal.svg';
import presentationIcon from 'ui/illustrations/services/presentation.svg';
import accountingIcon from 'ui/illustrations/services/accounting.svg';
import auditIcon from 'ui/illustrations/services/audit.svg';
import codeScreenIcon from 'ui/illustrations/services/code-screen.svg';
import puzzleIcon from 'ui/illustrations/services/puzzle.svg';
import ipIcon from 'ui/illustrations/services/ip.svg';
import chairIcon from 'ui/illustrations/services/chair.svg';
import handshakeIcon from 'ui/illustrations/services/handshake.svg';
import lightBubbleIcon from 'ui/illustrations/services/light-bubble.svg';
import supportIcon from 'ui/illustrations/services/support.svg';
import leaf_business_incubator from 'ui/illustrations/services/leaf_business_incubator.svg';
import financial_services from 'ui/illustrations/services/financial_services.svg';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import useDialogHandler from 'hooks/useDialogHandler';
import { Service } from 'types/organization';

const serviceIcons: { [key: string]: any } = {
  'governmental-grants': governmentalGrantsIcon,
  investments: investmentsIcon,
  banking: bankingIcon,
  legal: legalIcon,
  'investor-meetings': presentationIcon,
  'accelerator-programs': puzzleIcon,
  accounting: accountingIcon,
  'it-software': codeScreenIcon,
  audit: auditIcon,
  mentoring: lightBubbleIcon,
  'ip-expertise': ipIcon,
  'it-support': supportIcon,
  'co-working-space': chairIcon,
  'corporate-matching': handshakeIcon,
  'business-incubator': leaf_business_incubator,
  'financial-services': financial_services,
};

export default function ServiceListing({
  services,
  contactPerson,
}: {
  services: Service[];
  contactPerson: UserProfile | null;
}) {
  const dialogHandler = useDialogHandler();

  return (
    <>
      <Grid container spacing={4} className="text-medium">
        {services.map(service => (
          <Grid item xs={12} sm={6} md={4} key={service.id}>
            <p className="u-flex u-flex-align-center u-flex--gap-half">
              <span style={{ width: '37px', display: 'inline-flex', justifyContent: 'center' }}>
                <ServiceIcon service={service} />
              </span>
              <b className="text-weight-medium">{service.name}</b>
            </p>
          </Grid>
        ))}
      </Grid>
      {contactPerson && (
        <>
          <Button kind="primary" className="u-section-spacing-top" onClick={dialogHandler.open}>
            Contact us
          </Button>
          {dialogHandler.isOpen && (
            <UserConversationDialog
              conversation={{ type: 'withUsers', withCwUserIds: [contactPerson.cwUserId] }}
              closeConversation={dialogHandler.close}
            />
          )}
        </>
      )}
    </>
  );
}

function ServiceIcon({ service }: { service: Service }) {
  const icon = serviceIcons[service.key];
  if (!icon) {
    return null;
  }

  return <img src={icon} />;
}
