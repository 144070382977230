import React, { useState } from 'react';

interface Props<T extends { name: string }> {
  renderItem: (value: T) => JSX.Element;
  renderExpandButton: (value: T, onClick: () => void) => JSX.Element;
  values: T[];
  limit: number;
}

export default function TruncatedList<T extends { name: string }>(props: Props<T>) {
  const [isExpanded, setExpanded] = useState(false);

  function handleExpand() {
    setExpanded(true);
  }

  const limit = isExpanded ? props.values.length : props.limit;

  return (
    <>
      {props.values.slice(0, limit).map((l, i) => (
        <React.Fragment key={`${l.name}--${i}`}>{props.renderItem(l)}</React.Fragment>
      ))}
      {props.values.slice(limit).length > 0 &&
        props.renderExpandButton(
          { ...props.values[0], name: `+${props.values.slice(props.limit).length}` },
          handleExpand,
        )}
    </>
  );
}