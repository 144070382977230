import { DocumentDownloadDTO } from 'types/documents';
import React from 'react';
import PdfPreview from './PdfPreview';
import PreviewImages from './PreviewImages';
import Resource from 'util/resource/Resource';
import useResourceLegacy from 'util/resource/useResourceLegacy';

interface AttachmentPreviewProps {
  attachment: Promise<DocumentDownloadDTO>;
}

export default function AttachmentPreview({ attachment }: AttachmentPreviewProps) {
  const [attachmentResource] = useResourceLegacy<DocumentDownloadDTO>(async () => {
    return attachment;
  }, []);

  return (
    <Resource resource={attachmentResource} renderLoading={'Bar'}>
      {resourceAtt => {
        if (resourceAtt.contentType.startsWith('application/pdf')) {
          return <PdfPreview document={resourceAtt} />;
        } else if (resourceAtt.contentType.startsWith('image')) {
          return <PreviewImages imageUrls={[resourceAtt.path]} />;
        }
        return <></>;
      }}
    </Resource>
  );
}
