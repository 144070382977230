import { Organization } from 'types/organization';
import React from 'react';

interface ConnectionSettingsProps {
  organization: Organization;
}

export function ConnectionSettings(props: ConnectionSettingsProps) {
  return <span>connections {props.organization.name}</span>;
}
