import { bluePlanetTheme } from 'ui/theme';
import { useMediaQuery } from '@mui/material';
import React from 'react';
import MuiFullscreenDialog, { Content } from 'ui/views/dialogs/FullscreenDialog';
import cx from 'classnames';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import IconButton from 'ui/elements/icons/IconButton';
import styles from '../companies/Documents/Document/PitchDeck/styles.scss';
import documentsStyle from './styles.scss';

interface Props {
  imagePath: string;
  onClose: () => void;
}

export default function ImageDialog({ imagePath, onClose }: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const [imageDimensions, setImageDimensions] = React.useState<{ width: number; height: number } | null>(null);

  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;
    const aspectRatio = naturalWidth / naturalHeight;
    const windowWidth = window.innerWidth - 40;
    const windowHeight = window.innerHeight - 40;

    if (windowWidth / aspectRatio <= windowHeight) {
      setImageDimensions({ width: windowWidth, height: windowWidth / aspectRatio });
    } else {
      setImageDimensions({ width: windowHeight * aspectRatio, height: windowHeight });
    }
  };

  const renderImage = () => {
    return (
      <img
        src={imagePath}
        onLoad={handleImageLoad}
        style={{
          width: imageDimensions?.width || 'auto',
          height: imageDimensions?.height || 'auto',
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
        }}
      />
    );
  };

  return isMobile ? (
    <MuiFullscreenDialog open onClose={onClose}>
      <div className={styles.mobileCloseButtonWrapper}>
        <IconButton
          className={cx('u-half-spacing', styles.mobileCloseButton)}
          color="indigo"
          onClick={onClose}
          aria-label="Close"
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Content padding="none">
        <div className={documentsStyle.centerItem}>{renderImage()}</div>
      </Content>
    </MuiFullscreenDialog>
  ) : (
    <CenteredModalDialog open onClose={onClose} disableEnforceFocus width="wide">
      <div
        className={documentsStyle.centerItem}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {renderImage()}
      </div>
    </CenteredModalDialog>
  );
}
