import React from 'react';
import Button from 'ui/elements/buttons/Button';
import useLazyResource from 'util/resource/useLazyResource';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import { FeedbackContext } from 'types/user';
import useLocalStorage from 'hooks/useLocalStorage';
import i18n from 'util/i18n';
import useNotify from 'hooks/useNotify';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import IconAvatar from 'ui/elements/icons/IconAvatar';

export default function ContactMeButton({
  tag,
  feedback,
  companyId,
  buttonText,
}: {
  tag: string;
  feedback: FeedbackContext;
  companyId?: number;
  buttonText?: string;
}) {
  const [hasSent, setHasSent] = useLocalStorage(tag, false);
  const notify = useNotify();

  const [sendFeedback, isSendingFeedback] = useLazyResource(
    (feedbackContext: FeedbackContext) =>
      usersApi.feedback({
        feedback: undefined,
        anonymous: false,
        feedbackContext: feedbackContext,
        companyId,
      }),
    {
      onSuccess: () => {
        setHasSent(true);
      },
      onFailure: () => {
        notify('error', i18n('en').errors.send('feedback'));
      },
    },
  );

  return (
    <div className="text-nowrap">
      {hasSent ? (
        <p className="u-flex u-flex--gap-quarter u-flex-align-center">
          <IconAvatar color="success" size={{ width: '20px', height: '20px' }}>
            <CheckmarkIcon />
          </IconAvatar>
          We will contact you soon.
        </p>
      ) : (
        <Button kind="primary" color="blue" isLoading={isSendingFeedback} onClick={() => sendFeedback(feedback)}>
          {buttonText || 'Contact me'}
        </Button>
      )}
    </div>
  );
}
