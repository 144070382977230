import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import config from 'config';

export const createRedirectUri = () => {
  return `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}/auth0/callback`;
};

interface Props {
  children: React.ReactNode;
}
export default function Auth0ProviderWithHistory(props: Props) {
  const domain = config.AUTH0_DOMAIN;
  const clientId = config.AUTH0_CLIENT_ID;

  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    const redirectUrl = appState?.returnTo || window.location.pathname;
    history.push(redirectUrl);
  };

  return (
    <Auth0Provider
      scope="openid profile email"
      audience={config.AUTH0_API_IDENTIFIER}
      domain={domain}
      clientId={clientId}
      redirectUri={createRedirectUri()}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      {props.children}
    </Auth0Provider>
  );
}
