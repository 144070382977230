import { Organization } from 'types/organization';
import React from 'react';

interface VisibilitySettingsProps {
  organization: Organization;
}

export function VisibilitySettings(props: VisibilitySettingsProps) {
  return <span>Visibility {props.organization.name}</span>;
}
