import AntifraudAPI, { Report } from 'apis/AntifraudAPI';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import ReportDialog from 'domain/antifraud/ReportDialog';
import React, { useState } from 'react';
import FlagIcon from 'ui/elements/icons/FlagIcon';
import PopMenu from 'ui/modules/PopMenu';
import Resource from 'util/resource/Resource';

interface Props {
  cwUserId: string;
}

export default function UserProfileDropdown(props: Props) {
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const { resource: userProfileResource } = useSelfUserProfile();

  return (
    <Resource resource={userProfileResource}>
      {selfUser =>
        selfUser.cwUserId !== props.cwUserId ? (
          <>
            <PopMenu
              items={[
                {
                  icon: <FlagIcon />,
                  text: <>Report user</>,
                  onClick: () => setIsReportDialogOpen(true),
                },
              ]}
            />
            <ReportDialog
              title="What would you like to report?"
              postReport={(report: Report) => AntifraudAPI.report.user(props.cwUserId, report)}
              isOpen={isReportDialogOpen}
              onClose={() => setIsReportDialogOpen(false)}
              reasons={['Spam', 'Scams or fraud', 'Fake account', 'Incorrect information', 'Representing someone else']}
            />
          </>
        ) : null
      }
    </Resource>
  );
}
