import { alpha } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import cx from 'classnames';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';

type Color = 'default' | 'success' | 'white' | 'blue-light' | 'blue';

interface Props {
  color?: Color;
  className?: string;
  size?: { width: string; height: string } | number;
  children: React.ReactNode;
}

function getColors(color: Color) {
  switch (color) {
    case 'success':
      return {
        backgroundColor: bluePlanetTheme.bluePlanetPalette.green.medium,
        color: bluePlanetTheme.bluePlanetPalette.white,
      };
    case 'white':
      return {
        backgroundColor: alpha(bluePlanetTheme.bluePlanetPalette.white, 0.15),
        color: bluePlanetTheme.bluePlanetPalette.white,
      };
    case 'blue-light':
      return {
        backgroundColor: alpha(bluePlanetTheme.bluePlanetPalette.blue.main, 0.15),
        color: bluePlanetTheme.bluePlanetPalette.blue.main,
      };
    case 'blue':
      return {
        backgroundColor: bluePlanetTheme.bluePlanetPalette.blue.main,
        color: bluePlanetTheme.bluePlanetPalette.white,
      };

    default:
      return {
        backgroundColor: bluePlanetTheme.bluePlanetPalette.grey.light,
        color: bluePlanetTheme.bluePlanetPalette.indigo.main,
      };
  }
}

export default function IconAvatar(props: Props) {
  const width = typeof props.size === 'number' ? `${props.size}px` : props.size?.width ?? '48px';
  const height = typeof props.size === 'number' ? `${props.size}px` : props.size?.height ?? '48px';
  return (
    <Avatar
      sx={{
        ...getColors(props.color || 'default'),
        width,
        height,
      }}
      className={cx(props.className)}
    >
      {props.children}
    </Avatar>
  );
}
